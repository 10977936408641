import { mapMutations, mapState } from 'vuex'
import { MUTATIONS, STATE } from '@/store'

/**
 * Provides a method to change the title of a breadcrumb.
 *
 * The method requires the breadcrumb id and a function to resolve the title as the parameters.
 */
const changeBreadcrumb = ({ id, getTitle, defaultValue }) => {
  return {
    computed: {
      ...mapState({
        breadcrumbs: state => state[STATE.BREADCRUMBS] || []
      }),
      breadcrumbTitle: getTitle
    },
    methods: {
      ...mapMutations({
        setBreadcrumbs: MUTATIONS.SET_BREADCRUMBS
      }),
      changeBreadcrumbTitle (id, title) {
        const index = this.breadcrumbs.findIndex(it => it.id === id)
        if (index === -1) return

        this.breadcrumbs[index].title = title
        this.setBreadcrumbs([...this.breadcrumbs])
      }
    },
    watch: {
      breadcrumbTitle: {
        handler (value) {
          this.changeBreadcrumbTitle(
            id,
            {
              value: value == null
                ? (defaultValue ? defaultValue.call(this) : value)
                : value,
              translate: false
            }
          )
        },
        immediate: true,
        deep: true
      }
    }
  }
}

/**
 * @param {Array<{ id: string, name: string, title: string}>} breadcrumbs - an array of breadcrumbs
 */
const useBreadcrumbs = (breadcrumbs) => ({
  methods: {
    ...mapMutations({
      setBreadcrumbs: MUTATIONS.SET_BREADCRUMBS
    })
  },
  created () {
    this.setBreadcrumbs(breadcrumbs)
  }
})

export { changeBreadcrumb, useBreadcrumbs }
