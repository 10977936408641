import { gql, watchQuery } from '../../services/apollo/apollo'

const APPLICATION_INFO = gql`
  query applicationInfo {
    applicationInfo {
      version
      buildTime
    }
  }
`

const applicationService = {
  applicationInfo () {
    return watchQuery({ query: APPLICATION_INFO, variables: null, fetchPolicy: 'cache-first' })
  }
}

export default applicationService
