import { organizationManagementService } from '@/api'

export default (handleEnter) => ({
  methods: {
    [handleEnter] (organizationId) {
      return organizationId
        ? this._routeToOrganization(organizationId)
        : this._routeToUndefined()
    },

    async _routeToOrganization (organizationId) {
      this.$router.push({
        name: 'zeitfabrik',
        params: { organizationId }
      })
    },

    async _routeToUndefined () {
      const { data } = await organizationManagementService.getOrganizationPage({ input: { limit: 1, offset: 0 } })
      if (data.organizationPage.totalCount === 1) {
        return this._routeToOrganization(data.organizationPage.organization[0].id)
      }

      return this.$router.push({ name: 'organization-list' })
    },
  }
})
