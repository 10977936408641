import { gql, watchQuery } from '../../services/apollo/apollo'

const ABSENCE_PAGE = gql`
  query absences($input: AbsencePageInput!) {
    absencePage(input: $input) {
      absence {
        start
        end
        staff {
          id
          firstName
          lastName
        }
      }
      totalCount
    }
  }
`

const absenceManagementService = {
  absencePage (variables) {
    return watchQuery({ query: ABSENCE_PAGE, variables })
  }
}

export default absenceManagementService
