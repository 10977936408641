import axios from 'axios'
import { userManagementService } from '@/api'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

const unprotectedRoutes = ['/user/sign-in', '/user/register', '/user/access', '/user/is-mail-taken', '/version']

instance.interceptors.request.use(async (config) => {
  if (unprotectedRoutes.indexOf(config.url) === -1) {
    const accessExpired = userManagementService.isAccessExpired()
    if (accessExpired) await userManagementService.access()
    config.headers['X-XSRF-TOKEN'] = userManagementService.getXsrfToken()
    config.headers['Authorization'] = 'Bearer ' + userManagementService.getAccessToken()
  }
  return config
})

export default instance
