<template>
  <base-form v-on="$listeners">
    <div class="px-6 py-4">
      <h1 class="text-2xl font-semibold uppercase tracking-wider">
        {{ heading }}
      </h1>
      <p
        v-if="description"
        class="text-gray-600"
      >
        {{ description }}
      </p>
    </div>
    <div>
      <slot />
    </div>
    <div>
      <slot name="footer" />
    </div>
  </base-form>
</template>

<script>
  export  default {
    props: {
      heading: {
        type: String,
        required: true
      },
      description: {
        type: String,
        default () {
          return ''
        }
      }
    }
  }
</script>
