import { MUTATIONS } from '@/store'

function useAction (actionDefs) {
  return actionDefs.reduce((prev, curr) => {
    prev[curr.name] = ({ dispatch }, input) => {
      return executeService({
        dispatch,
        input,
        serviceFn: curr.serviceFn,
        notificationKey: curr.successNotification
      })
    }
    return prev
  }, {})
}

async function executeService ({ dispatch, input, serviceFn, notificationKey }) {
  const { data, errors } = await serviceFn(input)
  if (errors != null) throw errors
  notificationKey && dispatch(MUTATIONS.SET_NOTIFICATION, notificationKey, { root: true })
  return data
}

export default useAction
