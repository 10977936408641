import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const CUSTOMER_PAGE = gql`
  query customerPage($input: CustomerPageInput!) {
    customerPage(input: $input) {
      customer {
        id
        name
        address
      },
      totalCount(input: $input)
    }
  }
`

const CUSTOMER = gql`
  query customer($input: CustomerQueryInput!) {
    customer(input: $input) {
      id
      name
      address
    }
  }
`

const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      name
      address
    }
  }
`

const EDIT_CUSTOMER = gql`
  mutation editCustomer($input: EditCustomerInput!) {
    editCustomer(input: $input) {
      id
      name
      address
    }
  }
`

const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input)
  }
`

const customerManagementService = {
  customerPage (variables) {
    return watchQuery({ query: CUSTOMER_PAGE, variables })
  },

  customer (variables, { fetchPolicy }) {
    return watchQuery({ query: CUSTOMER, variables, fetchPolicy })
  },

  createCustomer (input) {
    return mutate({ mutation: CREATE_CUSTOMER, variables: { input } })
  },

  editCustomer (input) {
    return mutate({ mutation: EDIT_CUSTOMER, variables: { input } })
  },

  deleteCustomer (input) {
    return mutate({ mutation: DELETE_CUSTOMER, variables: { input } })
  }
}

export default customerManagementService
