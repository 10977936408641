import { staffManagementService, userManagementService } from '../../api'
import { useAction, useGetter } from '../helpers'
import { MUTATIONS } from '@/store'

const userModule = {
  namespaced: true,
  state: {
    authenticated: userManagementService.isAuthenticated()
  },
  mutations: {
    setAuthenticated (state, authenticated) {
      state.authenticated = authenticated
    }
  },
  actions: {
    async registerUser (context, input) {
      const response = await userManagementService.registerUser(input)
      return response.data
    },
    async login (context, input) {
      const response = await userManagementService.login(input)
      context.commit('setAuthenticated', true)
      return response.data
    },
    async logout ({ commit }) {
      try {
        const response = await userManagementService.logout()
      } finally {
        commit('setAuthenticated', false)
        commit('reset', null, { root: true })
      }
    },
    async isMailTaken ({ dispatch }, input) {
      const response = await userManagementService.isMailTaken(input)
      return response.data
    },
    setEmailConfirmed (context, emailConfirmed) {
      userManagementService.setEmailConfirmed(emailConfirmed)
    },
    async requestPasswordReset ({ dispatch }, input) {
      const response = await userManagementService.requestPasswordReset(input)
      dispatch(MUTATIONS.SET_NOTIFICATION, 'notification.passwordResetRequested', { root: true })
      return response
    },
    async resetPassword ({ dispatch }, input) {
      const response = await userManagementService.resetPassword(input)
      dispatch(MUTATIONS.SET_NOTIFICATION, 'notification.passwordReset', { root: true })
      return response
    },
    ...useAction([
      {
        name: 'confirmEmail',
        serviceFn: userManagementService.confirmEmail
      },
      {
        name: 'editUser',
        serviceFn: (input, onSuccess) => { /* NOT IMPLEMENTED YET */ },
        successNotification: 'notification.edited'
      },
      {
        name: 'acceptInvitation',
        serviceFn: staffManagementService.acceptInvitation,
        successNotification: 'notification.invitationAccepted'
      },
      {
        name: 'declineInvitation',
        serviceFn: staffManagementService.declineInvitation,
        successNotification: 'notification.invitationDeclined'
      }
    ])
  },
  getters: {
    ...useGetter({
      fetchLoggedInUser: userManagementService.fetchLoggedInUser,
      fetchMe: userManagementService.getMe,
      fetchMeWithCurrentClockState: userManagementService.getMeWithCurrentClockState,
      fetchMeWithCurrentWorkingWeekAndTimeAccount: userManagementService.getMeWithCurrentWorkingWeekAndTimeAccount,
      fetchMeWithLeaveYear: userManagementService.getMeWithCurrentLeaveYear,
      fetchInvitations: staffManagementService.fetchInvitations
    })
  }
}

export default userModule
