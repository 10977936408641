import { gql, mutate, query, watchQuery } from '@/services/apollo/apollo'
import axios from '@/services/axios/axios'

const ORGANIZATION_MANAGEMENT_FRAGMENTS = {
  organization: gql`
    fragment Organization on Organization {
      id
      name
      license
      leavable
      editable
      deletable
      pendingNewOwner
      germanWorkingTimeLawChecksEnabled
      basicFunctionality
        trustBasedFlexTime
    }
  `
}

const ORGANIZATION_PAGE = gql`
  query organizationPage($input: OrganizationPageInput!) {
    organizationPage(input: $input) {
      organization {
        ...Organization
      }
      totalCount
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const ORGANIZATION = gql`
  query organization($input: OrganizationQueryInput!) {
    organization(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const LEAVE_ORGANIZATION = gql`
  mutation leaveOrganization($input: LeaveOrganizationInput!) {
    leaveOrganization(input: $input)
  }
`

const EDIT_ORGANIZATION = gql`
  mutation editOrganization($input: EditOrganizationInput!) {
    editOrganization(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const EDIT_ORGANIZATION_LICENSE = gql`
  mutation editOrganizationLicense($input: EditOrganizationLicenseInput!) {
    editOrganizationLicense(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($input: DeleteOrganizationInput!){
    deleteOrganization(input: $input)
  }
`

const PASS_ORGANIZATION_OWNERSHIP = gql`
  mutation passOrganizationOwnership($input: PassOrganizationOwnershipInput! ) {
    passOrganizationOwnership(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const REVOKE_PASS_ORGANIZATION_OWNERSHIP = gql`
  mutation revokePassOrganizationOwnership($input: RevokePassOrganizationOwnershipInput! ) {
    revokePassOrganizationOwnership(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const ACCEPT_ORGANIZATION_OWNERSHIP = gql`
  mutation acceptOrganizationOwnership($input: AcceptOrganizationOwnershipInput! ) {
    acceptOrganizationOwnership(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const DECLINE_ORGANIZATION_OWNERSHIP = gql`
  mutation declineOrganizationOwnership($input: DeclineOrganizationOwnershipInput! ) {
    declineOrganizationOwnership(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const DISABLE_GERMAN_WORKING_TIME_ACT_CHECKS = gql`
  mutation disableGermanWorkingTimeLawChecks($input: DisableGermanWorkingTimeLawChecksInput! ) {
    disableGermanWorkingTimeLawChecks(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const ENABLE_GERMAN_WORKING_TIME_ACT_CHECKS = gql`
  mutation enableGermanWorkingTimeLawChecks($input: EnableGermanWorkingTimeLawChecksInput! ) {
    enableGermanWorkingTimeLawChecks(input: $input){
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const SET_ORGANIZATION_FUNCTIONALITY = gql`
  mutation setOrganizationFunctionality($input: SetOrganizationFunctionalityInput!){
    setOrganizationFunctionality(input: $input) {
      ...Organization
    }
  }
  ${ORGANIZATION_MANAGEMENT_FRAGMENTS.organization}
`

const ENABLE_TRUST_BASED_FLEX_TIME = gql`
  mutation enableTrustBasedFlexTIme($input: UUID!){
    enableTrustBasedFlexTime(input: $input)
  }
`

const DISABLE_TRUST_BASED_FLEX_TIME = gql`
  mutation enableTrustBasedFlexTIme($input: UUID!){
    disableTrustBasedFlexTime(input: $input)
  }
`

const organizationManagementService = {
  organizationPage(variables) {
    return watchQuery({ query: ORGANIZATION_PAGE, variables })
  },

  getOrganizationPage(variables) {
    return query({ query: ORGANIZATION_PAGE, variables })
  },

  createOrganization(input) {
    return mutate({ mutation: CREATE_ORGANIZATION, variables: { input } })
  },

  organization(variables, { fetchPolicy }) {
    return watchQuery({ query: ORGANIZATION, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  leaveOrganization(input) {
    return mutate({ mutation: LEAVE_ORGANIZATION, variables: { input } })
  },

  editOrganization(input) {
    return mutate({ mutation: EDIT_ORGANIZATION, variables: { input } })
  },

  editOrganizationLicense(input) {
    return mutate({ mutation: EDIT_ORGANIZATION_LICENSE, variables: { input } })
  },

  deleteOrganization(input) {
    return mutate({ mutation: DELETE_ORGANIZATION, variables: { input } })
  },
  passOrganizationOwnership(input) {
    return mutate({ mutation: PASS_ORGANIZATION_OWNERSHIP, variables: { input } })
  },
  revokePassOrganizationOwnership(input) {
    return mutate({ mutation: REVOKE_PASS_ORGANIZATION_OWNERSHIP, variables: { input } })
  },
  acceptOrganizationOwnership(input) {
    return mutate({ mutation: ACCEPT_ORGANIZATION_OWNERSHIP, variables: { input } })
  },
  declineOrganizationOwnership(input) {
    return mutate({ mutation: DECLINE_ORGANIZATION_OWNERSHIP, variables: { input } })
  },
  disableGermanWorkingTimeLawChecks(input) {
    return mutate({ mutation: DISABLE_GERMAN_WORKING_TIME_ACT_CHECKS, variables: { input } })
  },
  enableGermanWorkingTimeLawChecks(input) {
    return mutate({ mutation: ENABLE_GERMAN_WORKING_TIME_ACT_CHECKS, variables: { input } })
  },
  setOrganizationFunctionality(input) {
    return mutate({ mutation: SET_ORGANIZATION_FUNCTIONALITY, variables: { input } })
  },
  getTimeSheetExportConfigurationFile(input) {
    return axios.get(
      `/organization/${input.organizationId}/time-sheet-export-configuration-file`
    ).then(({ data }) => {
      // Convert to ISO 8859-1 bytes
      let latin1Data = new Uint8Array(data.length)
      for (let i = 0; i < data.length; i++) {
        latin1Data[i] = data.charCodeAt(i)
      }

      const blob = new Blob([latin1Data], { type: 'text/plain;charset=ISO-8859-1' })
      saveAs(blob, `${input.fileName}.ini`)
    })
  },
  enableTrustBasedFlexTime(input) {
    return mutate({ mutation: ENABLE_TRUST_BASED_FLEX_TIME, variables: { input } })
  },
  disableTrustBasedFlexTime(input) {
    return mutate({ mutation: DISABLE_TRUST_BASED_FLEX_TIME, variables: { input } })
  }
}

export default organizationManagementService
export { ORGANIZATION_MANAGEMENT_FRAGMENTS }
