import  { paginated }  from '@/store/helpers'

const initialState = {
  page: {
    limit: 30,
    offset: 0,
    staffId: null
  }
}

const leaveAgreementModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  }
}

export default leaveAgreementModule
export { initialState }


