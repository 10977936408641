<template>
  <div id="app">
    <theme-provider
      :theme="themeTailwind"
      class="hyphens-auto font-sans font-light"
    >
      <the-notification class="z-50 fixed bottom-12 inset-x-2 max-w-2xl mx-auto" />
      <router-view />
    </theme-provider>
  </div>
</template>

<script>
  import Theme from './services/codefabrik-ui/theme'
  import TheNotification from '@/components/notification/the-notification.vue'
  import axios from '@/services/axios/axios'

  export default {
    name: 'App',
    components: { TheNotification },
    computed: {
      themeTailwind () {
        return Theme
      }
    },
    created () {
      document.documentElement.lang = navigator.language
      this.$router.afterEach((to, from) => {
        if (to.name === from.name) return
        this.$store.commit('setBreadcrumbs', null)
        this.$store.commit('setSidebar', null)
      })
    },
  }
</script>
