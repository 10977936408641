<template>
  <div class="p-4 min-h-screen">
    <div class="sticky bg-white top-0">
      <img
        src="../../public/zeitfabrik-logo-landscape.svg"
        alt="ZeitFabrik Logo"
        class="mx-auto h-12"
      >
    </div>
    <dialog
      id="pinModal"
      class="fixed h-full w-full backdrop-blur-md bg-white/50"
    >
      <div class="h-full mx-auto max-w-screen-sm flex flex-col justify-center">
        <div
          v-if="selectedStaff"
          class="font-semibold text-4xl text-slate-700"
        >
          {{ $t('label.hello') }} {{ fullName(selectedStaff) }}
        </div>
        <label
          class="mt-12 text-2xl text-slate-700"
        >
          {{ $t('paragraph.enterPin') }}
          <input
            v-model="pinInput"
            type="number"
            max="9999"
            step="1"
            class="mt-2 rounded-lg border w-full text-5xl text-center font-semibold tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
            :class="requestFailed ? 'ring-4 !ring-red-600' : ''"
          >
        </label>
        <button
          class="mt-12 mx-auto uppercase font-semibold text-4xl text-slate-700"
          value="cancel"
          @click="handleClose"
        >
          {{ $t('button.cancelPinInput') }}
        </button>
        <div
          class="mt-4 mx-auto font-semibold text-red-600 h-4"
        >
          {{ errorMessage }}
        </div>
      </div>
    </dialog>


    <div
      class="p-4 h-full"
    >
      <input
        v-model="staffFilter"
        type="search"
        autocomplete="off"
        :placeholder="$t('label.search')"
        class="text-4xl p-4 rounded-lg border-2 border-slate-300 w-full text-center focus:outline-0 focus:ring-4 focus:ring-sky-600"
      >
      <div class="h-full space-y-3 mt-8">
        <div
          v-for="staff in filteredStaffList"
          :key="staff.staffId"
        >
          <div
            v-if="staff.clockedIn"
            role="button"
            @click="handleSelect(staff)"
          >
            <div class="flex w-full items-center rounded-lg border-2 border-lime-950/10 bg-gradient-to-br from-lime-50 to-green-50 p-4">
              <div class="aspect-square w-14 rounded-full bg-gradient-to-br from-lime-400 to-green-500" />

              <div class="flex-1 px-4">
                <div class="text-xl font-semibold">
                  {{ fullName(staff) }}
                </div>

                <div class="text-slate-700">
                  {{ $t('label.clockedIn') }}
                </div>
              </div>
              <div class="font-semibold text-xl uppercase text-slate-600">
                {{ $t('button.clockOut') }}
              </div>
              <svg-chevron-right class="text-red-500 w-10 h-10 stroke-2" />
            </div>
          </div>
          <div
            v-else
            role="button"
            @click="handleSelect(staff)"
          >
            <div class="flex w-full items-center rounded-lg border-2 border-rose-950/10 bg-gradient-to-br from-rose-50 to-red-50 p-4">
              <div class="aspect-square w-14 rounded-full bg-gradient-to-br from-pink-400 to-red-500" />

              <div class="flex-1 px-4">
                <div class="text-xl font-semibold">
                  {{ fullName(staff) }}
                </div>

                <div class="text-slate-700">
                  {{ $t('label.clockedOut') }}
                </div>
              </div>
              <div class="font-semibold text-xl uppercase text-slate-600">
                {{ $t('button.clockIn') }}
              </div>
              <svg-chevron-right class="text-green-500 w-10 h-10 stroke-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from '@/services/axios/axios-attendance-monitor'
  import { DateTime } from 'luxon'
  import SvgChevronRight from '@/components/svg/svg-chevron-right.vue'

  export default {
    name: 'AttendanceMonitorDashboard',
    components: { SvgChevronRight },
    data: () => {
      return {
        requestFailed: false,
        checkInterval: undefined,
        staffs: undefined,
        pinInput: undefined,
        selectedStaff: undefined,
        errorMessage: '',
        staffFilter: '',
        filteredStaffList: []
      }
    },
    watch: {
      pinInput(newValue, _) {
        this.requestFailed = false
        if(newValue !== undefined && String(newValue).length >= 4) {
          this.clockInOut()
        }
      },
      staffFilter(newValue, _){
        this.filteredStaffList = this.staffs.filter((staff) => this.fullName(staff).toLowerCase().includes(newValue.toLowerCase()))
      },
      staffs(newValue, _){
        if(!this.staffFilter) {
          this.filteredStaffList = newValue
        }
      }
    },
    computed: {
      accessToken() {
        return this.$route.query.accessToken
      },
      zoneOffset() {
        return DateTime.now().toFormat('Z')
      },
      axiosConfig() {
        return {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + this.accessToken
          }
        }
      }
    },
    async beforeMount() {
      await this.fetchStaff()

      this.checkInterval = setInterval(async () => {
        await this.fetchStaff()
      }, 30_000)
    },
    methods: {
      fullName(staff) {
        return staff.firstName  + ' ' + staff.lastName
      },
      fetchStaff() {
        axios.get('/device-api/attendance-monitor/staff', this.axiosConfig).then((response) => {
          this.staffs = response.data
        }).catch((error) => {
          this.errorMessage = error
        })
      },
      clockInOut() {
        let body = {
          'pin': this.pinInput,
          'zoneOffset': this.zoneOffset
        }

        let method = this.selectedStaff.clockedIn ? 'clock-out' : 'clock-in'
        let url = '/device-api/attendance-monitor/staff/' + this.selectedStaff.staffId + '/' + method

        axios.post(url, body, this.axiosConfig)
          .then(() => {
            document.getElementById('pinModal').close()
            this.selectedStaff = undefined
            this.errorMessage = ''
            this.staffFilter = ''
          })
          .catch((error) => {
            this.errorMessage = this.$t(`backend.${error.response.data.message}`)
            this.requestFailed = true
          })
          .finally(() => {
            this.pinInput = undefined
            this.fetchStaff()
          })
      },
      handleSelect(staff) {
        this.requestFailed = false
        this.pinInput = undefined
        this.selectedStaff = staff
        document.getElementById('pinModal').show()
      },
      handleClose() {
        this.selectedStaff = undefined
        this.requestFailed = false
        this.pinInput = undefined
        this.errorMessage = ''
        document.getElementById('pinModal').close()
      }
    }
  }
</script>
