import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const FRAGMENTS = {
  LEAVE: gql`
    fragment leave on Leave {
      id,
      start,
      end,
      comment,
      type,
      status,
      totalCost,
      staff {
        id,
        firstName
        lastName
      },
      days {
        applicationLength
      }
    }
  `
}

const LEAVE_PAGE = gql`
  query leavePage($input: LeavePageInput!) {
    leavePage(input: $input) {
      leave {
        ...leave
      }
      totalCount
    }
  }

  ${FRAGMENTS.LEAVE}
`

const LEAVE = gql`
  query leave($input: LeaveQueryInput!) {
    leave(input: $input) {
      ...leave
      executableTasks {
        definition {
          category,
          predicate
        }
      }
    }
  }

  ${FRAGMENTS.LEAVE}
`

const CREATE_LEAVE = gql`
  mutation createLeave($input: CreateLeaveInput!) {
    createLeave(createLeave: $input) {
      start,
      end
    }
  }
`

const REQUEST_LEAVE = gql`
  mutation requestLeave($input: RequestLeaveInput!) {
    requestLeave(input: $input) {
      start,
      end
    }
  }
`

const ACCEPT_LEAVE = gql`
  mutation acceptLeave($input: AcceptLeaveInput!) {
    acceptLeave(input: $input) {
      ...leave
    }
  }

  ${FRAGMENTS.LEAVE}
`

const REJECT_LEAVE = gql`
  mutation rejectLeave($input: RejectLeaveInput!) {
    rejectLeave(input: $input) {
      ...leave
    }
  }

  ${FRAGMENTS.LEAVE}
`

const DELETE_LEAVE = gql`
  mutation deleteLeave($leaveId: String!) {
    deleteLeave(leaveId: $leaveId)
  }
`

const EDIT_LEAVE = gql`
  mutation editLeave($input: EditLeaveInput!) {
    editLeave(editLeave: $input) {
      ...leave
      executableTasks {
        definition {
          category,
          predicate
        }
      }
    }
  }

  ${FRAGMENTS.LEAVE}
`

const leaveManagementService = {
  leavePage (variables) {
    return watchQuery({ query: LEAVE_PAGE, variables })
  },

  leave (variables) {
    return watchQuery({ query: LEAVE, variables, fetchPolicy: 'cache-first' })
  },

  createLeave ({ staffId, start, end, comment, halfDay, type }) {
    return mutate({
      mutation: CREATE_LEAVE,
      variables: {
        input: {
          staffId,
          start,
          end,
          comment,
          type,
          applicationLength: halfDay ? 0.5 : 1
        }
      }
    })
  },

  requestLeave ({ organizationId, start, end, comment, halfDay, type }) {
    return mutate({
      mutation: REQUEST_LEAVE,
      variables: {
        input: {
          organizationId,
          start,
          end,
          comment,
          type,
          applicationLength: halfDay ? 0.5 : 1
        }
      }
    })
  },

  acceptLeave ({ id }) {
    return mutate({
      mutation: ACCEPT_LEAVE,
      variables: { input: { id } }
    })
  },

  rejectLeave ({ id }) {
    return mutate({
      mutation: REJECT_LEAVE,
      variables: { input: { id } }
    })
  },

  deleteLeave (input) {
    return mutate({ mutation: DELETE_LEAVE, variables: { leaveId: input.leaveId } })
  },

  editLeave ({ halfDay, leaveId, start, end, comment, type }) {
    return mutate({
      mutation: EDIT_LEAVE,
      variables: {
        input: {
          leaveId,
          start,
          end,
          comment,
          type,
          applicationLength: halfDay ? 0.5 : 1
        }
      },
      update: (store, { data: { editLeave } }) => {
        const variables = { input: { leaveId: editLeave.id } }
        store.writeQuery({
          query: LEAVE,
          data: { leave: editLeave },
          variables
        })
      }
    })
  }
}

export default leaveManagementService
