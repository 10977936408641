<template>
  <div class="flex flex-col space-y-2 md:space-y-unset md:flex-row md:space-x-2 md:items-center md:space-y-0">
    <h1
      class="w-full font-semibold text-2xl uppercase tracking-wider break-all"
    >
      {{ text }}
    </h1>
    <slot name="primaryAction" />
    <slot name="secondaryAction" />
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      }
    }
  }
</script>
