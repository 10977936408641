export default {
  HOME: {
    id: 'home',
    name: 'dashboard',
    title: 'breadcrumb.home'
  },
  TIME_RECORDING_LIST: {
    id: 'recording',
    name: 'recording',
    title: 'breadcrumb.recordings'
  },
  TIME_SHEET: {
    id: 'time-sheet',
    name: 'time-sheet',
    title: 'breadcrumb.timeSheet'
  },
  TIME_SHEET_EDIT: {
    id: 'time-sheet-edit',
    name: 'time-sheet-edit',
    title: 'breadcrumb.timeSheetEdit'
  },
  TIME_SHEET_LIST: {
    id: 'time-sheet-list',
    name: 'time-sheet-list',
    title: 'breadcrumb.timeSheetList'
  },
  TIME_SHEET_CREATE: {
    id: 'time-sheet-create',
    name: 'time-sheet-create',
    title: 'breadcrumb.timeSheetCreate'
  },
  TIME_RECORDING_CREATE: {
    id: 'create-time-interval',
    name: 'create-time-interval',
    title: 'breadcrumb.create'
  },
  TIME_RECORDING: {
    id: 'time-interval',
    name: 'time-interval',
    title: 'breadcrumb.timeRecord'
  },
  DISTRIBUTABLE_DAY_LIST: {
    id: 'time-distribution',
    name: 'time-distribution',
    title: 'breadcrumb.distributions'
  },
  DISTRIBUTABLE_DAY: {
    id: 'distributable-day',
    name: 'distributable-day',
    title: 'breadcrumb.distributableDay'
  },
  DISTRIBUTED_ORDER_TIME_CREATE: {
    id: 'distribute-time',
    name: 'distribute-time',
    title: 'breadcrumb.distribute'
  },
  DISTRIBUTED_ORDER_TIME: {
    id: 'distributed-order-time',
    name: 'distributed-order-time',
    title: 'breadcrumb.timeDistribution'
  },
  LEAVE_LIST: {
    id: 'leave-management',
    name: 'leave-management',
    title: 'breadcrumb.leaves'
  },
  LEAVE_CREATE: {
    id: 'create-leave',
    name: 'create-leave',
    title: 'breadcrumb.create'
  },
  LEAVE_REQUEST: {
    id: 'request-leave',
    name: 'request-leave',
    title: 'breadcrumb.requestLeave'
  },
  LEAVE: {
    id: 'leave',
    name: 'leave',
    title: 'breadcrumb.leave'
  },
  SICK_NOTE_LIST: {
    id: 'sick-note-management',
    name: 'sick-note-management',
    title: 'breadcrumb.sickNotes'
  },
  SICK_NOTE_CREATE: {
    id: 'create-sick-note',
    name: 'create-sick-note',
    title: 'breadcrumb.create'
  },
  SICK_NOTE_SUBMIT: {
    id: 'create-sick-note',
    name: 'create-sick-note',
    title: 'breadcrumb.submitSickNote'
  },
  SICK_NOTE: {
    id: 'sick-note',
    name: 'sick-note',
    title: 'breadcrumb.sickNote'
  },
  UPCOMING_HOLIDAY_LIST: {
    id: 'upcoming-holidays',
    name: 'upcoming-holidays',
    title: 'breadcrumb.upcomingHolidays'
  },
  UPCOMING_HOLIDAY_CREATE: {
    id: 'upcoming-holidays',
    name: 'upcoming-holidays',
    title: 'breadcrumb.create'
  },
  UPCOMING_HOLIDAY: {
    id: 'holiday',
    name: 'holiday',
    title: 'breadcrumb.holiday'
  },
  PUBLIC_HOLIDAY_LIST: {
    id: 'public-holidays',
    name: 'public-holidays',
    title: 'breadcrumb.publicHolidays'
  },
  STAFF_LIST: {
    id: 'staff-list',
    name: 'staff-list',
    title: 'breadcrumb.staff'
  },
  STAFF_CREATE: {
    id: 'staff-create',
    name: 'staff-create',
    title: 'breadcrumb.create'
  },
  STAFF: {
    id: 'staff',
    name: 'staff',
    title: 'breadcrumb.staff'
  },
  STAFF_TIME_ACCOUNT: {
    id: 'staff-time-account',
    name: 'staff-time-account',
    title: 'breadcrumb.timeAccount'
  },
  STAFF_TIME_SHEET_LIST: {
    id: 'staff-time-sheet-list',
    name: 'staff-time-sheet-list',
    title: 'breadcrumb.timeSheetList'
  },
  STAFF_SCHEDULED_BREAKS: {
    id: 'staff-scheduled-breaks',
    name: 'staff-scheduled-breaks',
    title: 'breadcrumb.scheduledBreaks'
  },
  STAFF_SCHEDULED_BREAKS_CREATE: {
    id: 'staff-scheduled-breaks-create',
    name: 'staff-scheduled-breaks-create',
    title: 'breadcrumb.scheduledBreaksCreate'
  },
  STAFF_TIME_SHEET: {
    id: 'staff-time-sheet',
    name: 'staff-time-sheet',
    title: 'breadcrumb.timeSheet'
  },
  STAFF_TIME_SHEET_EDIT: {
    id: 'staff-time-sheet-edit',
    name: 'staff-time-sheet-edit',
    title: 'breadcrumb.timeSheetEdit'
  },
  STAFF_TIME_ACCOUNT_WORKING_WEEK: {
    id: 'staff-time-account-working-week',
    name: 'staff-time-account-working-week',
    title: 'breadcrumb.workingWeek'
  },
  STAFF_LEAVE_ACCOUNT: {
    id: 'staff-leave-account',
    name: 'staff-leave-account',
    title: 'breadcrumb.leaveAccount'
  },
  STAFF_WORKING_TIME_AGREEMENT_LIST: {
    id: 'staff-working-time-agreement-list',
    name: 'staff-working-time-agreement-list',
    title: 'breadcrumb.workingTimeAgreements'
  },
  STAFF_WORKING_TIME_AGREEMENT: {
    id: 'staff-working-time-agreement',
    name: 'staff-working-time-agreement',
    title: 'breadcrumb.workingTimeAgreement'
  },
  STAFF_WORKING_TIME_AGREEMENT_CREATE: {
    id: 'staff-working-time-agreement-create',
    name: 'staff-working-time-agreement-create',
    title: 'breadcrumb.create'
  },
  STAFF_LEAVE_AGREEMENT_LIST: {
    id: 'staff-leave-agreement-list',
    name: 'staff-leave-agreement-list',
    title: 'breadcrumb.leaveAgreements'
  },
  STAFF_LEAVE_AGREEMENT: {
    id: 'staff-leave-agreement',
    name: 'staff-leave-agreement',
    title: 'breadcrumb.leaveAgreement'
  },
  STAFF_LEAVE_AGREEMENT_CREATE: {
    id: 'staff-leave-agreement-create',
    name: 'staff-leave-agreement-create',
    title: 'breadcrumb.create'
  },
  STAFF_TIME_RECORD_LIST: {
    id: 'staff-time-record-list',
    name: 'staff-time-record-list',
    title: 'breadcrumb.recordedTimes'
  },
  STAFF_TIME_RECORD: {
    id: 'staff-time-record',
    name: 'staff-time-record',
    title: 'breadcrumb.timeRecord'
  },
  STAFF_TIME_DISTRIBUTION_DISTRIBUTABLE_DAY_LIST: {
    id: 'staff-time-distribution-distributable-day',
    name: 'staff-time-distribution-distributable-day',
    title: 'breadcrumb.distributedTimes'
  },
  STAFF_TIME_DISTRIBUTION_DISTRIBUTABLE_DAY: {
    id: 'staff-time-distribution-distributable-day',
    name: 'staff-time-distribution-distributable-day',
    title: 'breadcrumb.distributableDay'
  },
  STAFF_TIME_DISTRIBUTION_DISTRIBUTED_ORDER_TIME_LIST: {
    id: 'staff-time-distribution-distributed-order-time-list',
    name: 'staff-time-distribution-distributed-order-time-list',
    title: 'breadcrumb.distributedTimes'
  },
  STAFF_TIME_DISTRIBUTION_DISTRIBUTED_ORDER_TIME_CREATE: {
    id: 'staff-time-distribution-distributed-order-time-create',
    name: 'staff-time-distribution-distributed-order-time-create',
    title: 'breadcrumb.distribute'
  },
  STAFF_TIME_DISTRIBUTION_DISTRIBUTED_ORDER_TIME: {
    id: 'staff-time-distribution-distributed-order-time',
    name: 'staff-time-distribution-distributed-order-time',
    title: 'breadcrumb.timeDistribution'
  },
  STAFF_LEAVE_LIST: {
    id: 'staff-leave-list',
    name: 'staff-leave-list',
    title: 'breadcrumb.leaves'
  },
  STAFF_WORKING_TIME_LAW_VIOLATION_LIST: {
    id: 'staff-working-time-law-violation-list',
    name: 'staff-working-time-law-violation-list',
    title: 'breadcrumb.leaves'
  },
  STAFF_LEAVE_CREATE: {
    id: 'staff-leave-create',
    name: 'staff-leave-create',
    title: 'breadcrumb.create'
  },
  STAFF_LEAVE: {
    id: 'staff-leave',
    name: 'staff-leave',
    title: 'breadcrumb.leave'
  },
  STAFF_OVERTIME: {
    id: 'staff-overtime',
    name: 'staff-overtime',
    title: 'breadcrumb.overtime'
  },
  STAFF_SICK_NOTE_LIST: {
    id: 'staff-sick-note-list',
    name: 'staff-sick-note-list',
    title: 'breadcrumb.sickNotes'
  },
  STAFF_SICK_NOTE_CREATE: {
    id: 'staff-sick-note-create',
    name: 'staff-sick-note-create',
    title: 'breadcrumb.create'
  },
  STAFF_SICK_NOTE: {
    id: 'staff-sick-note',
    name: 'staff-sick-note',
    title: 'breadcrumb.sickNote'
  },
  ORGANIZATIONAL_UNIT_LIST: {
    id: 'organizational-unit-management',
    name: 'organizational-unit-management',
    title: 'breadcrumb.organizationalUnits'
  },
  ORGANIZATIONAL_UNIT_CREATE: {
    id: 'create-organizational-unit',
    name: 'create-organizational-unit',
    title: 'breadcrumb.create'
  },
  ORGANIZATIONAL_UNIT: {
    id: 'organizational-unit',
    name: 'organizational-unit',
    title: 'breadcrumb.organizationalUnit'
  },
  ORGANIZATIONAL_UNIT_MEMBERS: {
    id: 'organizational-unit-member',
    name: 'organizational-unit-member',
    title: 'breadcrumb.members'
  },
  ORGANIZATIONAL_UNIT_TASKS: {
    id: 'organizational-unit-task',
    name: 'organizational-unit-task',
    title: 'breadcrumb.tasks'
  },
  ATTENDANCE_MONITOR_LIST: {
    id: 'attendance-monitor-management',
    name: 'attendance-monitor-management',
    title: 'breadcrumb.attendanceMonitors'
  },
  ATTENDANCE_MONITOR: {
    id: 'attendance-monitor',
    name: 'attendance-monitor',
    title: 'breadcrumb.attendanceMonitor'
  },
  ORDER_LIST: {
    id: 'order-management',
    name: 'order-management',
    title: 'breadcrumb.orders'
  },
  ORDER_CREATE: {
    id: 'create-order',
    name: 'create-order',
    title: 'breadcrumb.create'
  },
  ORDER: {
    id: 'order',
    name: 'order',
    title: 'breadcrumb.order'
  },
  CUSTOMER_LIST: {
    id: 'customer-management',
    name: 'customer-management',
    title: 'breadcrumb.customers'
  },
  CUSTOMER_CREATE: {
    id: 'create-customer',
    name: 'create-customer',
    title: 'breadcrumb.create'
  },
  CUSTOMER: {
    id: 'customer',
    name: 'customer',
    title: 'breadcrumb.customer'
  },
  INVOICE_LIST: {
    id: 'invoices',
    name: 'invoices',
    title: 'breadcrumb.invoices'
  },
  INVOICE: {
    id: 'invoice',
    name: 'invoice',
    title: 'breadcrumb.invoice'
  },
  INVOICE_CREATE: {
    id: 'invoicing',
    name: 'invoicing',
    title: 'breadcrumb.create'
  },
  INVOICE_LINE_ITEM_LIST: {
    id: 'invoice-line-item-list',
    name: 'invoice-line-item-list',
    title: 'breadcrumb.invoiceLineItems'
  },
  INVOICE_LINE_ITEM_ORDERS_INVOICE: {
    id: 'invoice-line-item-orders-invoice',
    name: 'invoice-line-item-orders-invoice',
    title: 'breadcrumb.invoiceOrders'
  },
  INVOICE_LINE_ITEM_CREATE: {
    id: 'invoice-line-item-create',
    name: 'invoice-line-item-create',
    title: 'breadcrumb.create'
  },
  INVOICE_LINE_ITEM: {
    id: 'invoice-line-item',
    name: 'invoice-line-item',
    title: 'breadcrumb.invoiceLineItem'
  },
  INVOICE_DOWNLOAD: {
    id: 'invoice-download',
    name: 'invoice-download',
    title: 'breadcrumb.download'
  },
  WORKING_TIME_LIST: {
    id: 'working-time',
    name: 'working-time',
    title: 'breadcrumb.workingTime'
  },
  ABSENCE_LIST: {
    id: 'absence-overview',
    name: 'absence-overview',
    title: 'breadcrumb.absences'
  },
  INVOICE_TEMPLATE: {
    id: 'invoice-template',
    name: 'invoice-template',
    title: 'breadcrumb.invoiceTemplate'
  },
  INVOICE_TEMPLATE_LIST: {
    id: 'invoice-template-list',
    name: 'invoice-template-list',
    title: 'breadcrumb.invoiceTemplates'
  },
  INVOICE_TEMPLATE_CREATE: {
    id: 'invoice-template-create',
    name: 'invoice-template-create',
    title: 'breadcrumb.create'
  },
  ORDER_ANALYSIS: {
    id: 'order-analysis',
    name: 'order-analysis',
    title: 'breadcrumb.orderAnalysis'
  },
  CUSTOMER_ANALYSIS: {
    id: 'customer-analysis',
    name: 'customer-analysis',
    title: 'breadcrumb.customerAnalysis'
  },
  SCHEDULED_BREAK_LIST: {
    id: 'scheduled-break-list',
    name: 'scheduled-break',
    title: 'breadcrumb.scheduledBreaks'
  },
  TO_DO_LIST: {
    id: 'to-do-list',
    name: 'to-do-list',
    title: 'breadcrumb.toDos'
  },
  TIME_ACCOUNT: {
    id: 'time-account',
    name: 'time-account',
    title: 'breadcrumb.timeAccount'
  },
  TIME_ACCOUNT_WORKING_WEEK: {
    id: 'time-account-working-week',
    name: 'time-account-working-week',
    title: 'breadcrumb.timeAccountWorkingWeek'
  },
  WORKPLACE: {
    id: 'workplace',
    name: 'workplace',
    title: 'breadcrumb.workplace'
  },
  WORKPLACE_LIST: {
    id: 'workplace-list',
    name: 'workplace-management',
    title: 'breadcrumb.workplaces'
  },
  WORKPLACE_CREATE: {
    id: 'workplace-create',
    name: 'workplace-create',
    title: 'breadcrumb.workplaceCreate'
  }
}
