export default {
  messages: {
    required: (field) => `${field} is required.`,
    email: (field) => `${field} is invalid.`,
    daySameOrBefore: (field, { _target_ }) => 'Invalid period.',
    daySameOrAfter: (field, { _target_ }) => 'Invalid period.',
    daySameOrAfterNow: (field) => `${field} must not be in the past.`,
    confirmed: () => 'Value does not match.',
    min: (field, { length }) => `${field} must be a ${length} digit value.`,
    digits: (field, { length }) => `${field} must be a ${length} digit value.`,
    max: (field, { length }) => `${field} may be a at most a ${length} digit value.`,
    validAnnualLeave: () => 'Leave can only be agreed on in half-day steps.',
    min_value: (field, { min }) => `${field} must be at least ${min}.`,
    integer: (field) => `${field} must be an integer.`,
    image: (field) => `${field} must be an image.`,
    csv: (field) => `${field} must be a csv file.`,
    noLeadingOrTrailingWhitespace: (field) => `${field} must have no leading or trailing whitespace.`,
  }
}
