<template>
  <full-size-page-layout>
    <form-card-alive-check
      class="max-w-2xl mx-auto"
      @alive="handleTryAgain"
    />
  </full-size-page-layout>
</template>

<script>
  import { LayoutOutsideOrganization } from '@/layouts'
  import FormCardAliveCheck from '@/components/form/form-card-alive-check.vue'

  export default {
    name: 'NetworkError',
    components: { FormCardAliveCheck, FullSizePageLayout: LayoutOutsideOrganization },
    created () {
      document.addEventListener('visibilitychange', this.handleVisibilityChange, false)
    },
    beforeDestroy () {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange, false)
    },
    methods: {
      handleVisibilityChange () {
        if (document.visibilityState === 'visible') {
          this.handleTryAgain()
        }
      },
      async handleTryAgain (value) {
        if(value) {
          this.$router.replace({ path: this.$route.query.from })
        }
      }
    }
  }
</script>
