import { mapMutations, mapState } from 'vuex'
import { MUTATIONS, STATE } from '@/store'

export function useParentSidebar () {
  return this.$parent.sidebar
}

export function changeSidebarTitle ({ getTitle }) {
  return {
    computed: {
      ...mapState({
        sidebar: state => state[STATE.SIDEBAR] || { links: [] }
      }),
      sidebarTitle: getTitle
    },
    methods: {
      ...mapMutations({
        setSidebar: MUTATIONS.SET_SIDEBAR
      }),
    },
    watch: {
      sidebarTitle (value) {
        const index = this.sidebar.links.findIndex(it => it.heading === true)
        if (index === -1) return

        this.sidebar.links[index].text = value
        this.setSidebar({ ...this.sidebar })
      }
    }
  }
}

const useSidebar = (sidebar) => ({
  methods: mapMutations({
    setSidebar: MUTATIONS.SET_SIDEBAR
  }),
  created () {
    this.setSidebar(sidebar)
  }
})

export default useSidebar
