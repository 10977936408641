import Vue from 'vue'

Vue.filter('number', number)

function number (value, options=undefined) {
  if (value == null) return

  return new Intl.NumberFormat(navigator.language, options).format(value)
}

export default number
