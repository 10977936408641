import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const ATTENDANCE_MONITOR_PAGE = gql`
  query attendanceMonitorPage($input: AttendanceMonitorPageInput!) {
    attendanceMonitorPage(input: $input) {
      attendanceMonitors {
        id
        deviceName
        description
        monitorDashboardUrl
        accessToken
      }
      totalCount
    }
  }
`

const ATTENDANCE_MONITOR = gql`
  query attendanceMonitor($input: AttendanceMonitorInput!) {
    attendanceMonitor(input: $input) {
      id
      deviceName
      description
    }
  }
`

const EDIT_ATTENDANCE_MONITOR = gql`
  mutation editAttendanceMonitor($input: EditAttendanceMonitorInput!) {
    editAttendanceMonitor(input: $input) {
      id
    }
  }
`

const DELETE_ATTENDANCE_MONITOR = gql`
  mutation deleteAttendanceMonitor($input: DeleteAttendanceMonitorInput!) {
    deleteAttendanceMonitor(input: $input)
  }
`

const attendanceMonitorManagementService = {
  attendanceMonitorPage (variables) {
    return watchQuery({ query: ATTENDANCE_MONITOR_PAGE, variables })
  },

  attendanceMonitor (variables) {
    return watchQuery({ query: ATTENDANCE_MONITOR, variables })
  },

  createAttendanceMonitor (input) {
    return mutate({ mutation: CREATE_ATTENDANCE_MONITOR, variables: { input } })
  },

  editAttendanceMonitor (input) {
    return mutate({ mutation: EDIT_ATTENDANCE_MONITOR, variables: { input } })
  },

  deleteAttendanceMonitor (input) {
    return mutate({ mutation: DELETE_ATTENDANCE_MONITOR, variables: { input } })
  }
}

export default attendanceMonitorManagementService
