import { gql, mutate, watchQuery } from '../../services/apollo/apollo'
import axios from '@/services/axios/axios'

const TIME_ACCOUNT_PAGE = gql`
    query timeAccountPage($input: TimeAccountPageInput!) {
      timeAccountPage(input: $input) {
        workingWeeks {
          id
          weekNumber
          year
          targetWorkingTime
          workedTime
          accountedOvertime
        }
        overtimesAtEndOfWeek(input: $input){
          id
          overtime
        }
        totalCount(input: $input)
      }
    }
`

const WORKING_WEEK_PAGE = gql`
  query workingWeekPage($input: WorkingWeekPageInput!) {
    workingWeekPage(input: $input) {
      workingWeek {
        id
        weekNumber
        year
        targetWorkingTime
        workedTime
        accountedOvertime
        workingDays {
          day
          targetWorkingTime
          agreedWorkingTime
          workedTime
          overtime
        }
      }
      uncanceledTimeRecordings {
        day
        uncanceledTimeRecordings {
          id
          start
          end
        }
      }
      holidays {
        day
        holidays {
          id
          dayLength
        }
      }
      acceptedSickNotes {
        day
        acceptedSickNotes {
          id
        }
      }
      acceptedLeaves {
        day
        acceptedLeaves {
          id
          start
          end
          totalCost
          type
          days {
            day
            applicationLength
            cost
          }
        }
      }
      overtimeRecords {
          creator {
            id
            firstName
            lastName
          }
          reason
          creationDate
          value
          id
          type
          week
      }
      breakTimes {
        day
        breakTimes {
          start
          end
          duration
        }
      }
    }
  }
`
const CREATE_TIME_SHEET = gql`mutation createTimeSheet($input: CreateTimeSheetInput!){
  createTimeSheet(input: $input) {
    name
  }
}`
const EDIT_TIME_SHEET = gql`mutation createTimeSheet($input: EditTimeSheetInput!){
  editTimeSheet(input: $input) {
    name
  }
}`
const DELETE_TIME_SHEET = gql`mutation deleteTimeSheet($input: TimeSheetInput!){
  deleteTimeSheet(input: $input)
}`

const SUBMIT_TIME_SHEET = gql`mutation submitTimeSheet($input: TimeSheetInput!){
  submitTimeSheet(input: $input){
    name
    id
  }
}`

const ACCEPT_TIME_SHEET = gql`mutation submitTimeSheet($input: TimeSheetInput!){
  acceptTimeSheet(input: $input){
    name
    id
  }
}`

const REJECT_TIME_SHEET = gql`mutation submitTimeSheet($input: TimeSheetInput!){
  rejectTimeSheet(input: $input){
    name
    id
  }
}`

const EXPORT_TIME_SHEET_TO_DATEV = gql`mutation submitTimeSheet($input: TimeSheetInput!){
  exportTimeSheetToDatev(input: $input){
    name
    id
  }
}`

const TIME_SHEET_PAGE = gql`
  query timeSheetPage($input: TimeSheetPageInput!) {
    timeSheetPage(input: $input) {
     timeSheets {
       id
       name
       start
       end
       status
       totalWorkingTime
     }
      totalCount(input: $input)
    }
  }
`
const TIME_SHEET = gql`
  query timeSheet($input: TimeSheetQueryInput!) {
    timeSheet(input: $input) {
      name
      staff {
        firstName
        lastName
      }
      start
      end
      status
      id
      totalWorkingTime
      entries {
        day
        start
        end
        totalBreakTime
        totalWorkingTime
        comments
      }
      processedBy {
        firstName
        lastName
      }
      processedAt
      executableTasks {
        definition {
          category,
          predicate
        }
      }
    }
  }
`

const TIME_SHEET_WITH_TRACKING_STATUS = gql`
  query timeSheet($input: TimeSheetQueryInput!) {
    timeSheet(input: $input) {
      name
      staff {
        firstName
        lastName
      }
      start
      end
      status
      id
      totalWorkingTime
      entries {
        day
        start
        end
        totalBreakTime
        totalWorkingTime
        comments
      }
      processedBy {
        firstName
        lastName
      }
      processedAt
      executableTasks {
        definition {
          category,
          predicate
        }
      }
      trackingStatus
      trackingId
    }
  }
`
const timeAccountManagementService = {
  getTimeAccountPage(variables) {
    return watchQuery({ query: TIME_ACCOUNT_PAGE, variables })
  },
  getWorkingWeekPage(variables) {
    return watchQuery({ query: WORKING_WEEK_PAGE, variables })
  },
  createTimeSheet(input) {
    return mutate({ mutation: CREATE_TIME_SHEET, variables: { input } })
  },
  timeSheetPage (variables) {
    return watchQuery({ query: TIME_SHEET_PAGE, variables })
  },

  timeSheet(variables) {
    return watchQuery({ query: TIME_SHEET, variables })
  },
  timeSheetWithTrackingStatus(variables) {
    return watchQuery({ query: TIME_SHEET_WITH_TRACKING_STATUS, variables })
  },
  editTimeSheet(input) {
    return mutate({ mutation: EDIT_TIME_SHEET, variables: { input } })
  },
  deleteTimeSheet(input) {
    return mutate({ mutation: DELETE_TIME_SHEET, variables: { input } })
  },
  exportTimeSheet(input) {
    return axios.get(
      `/time-sheet/${input.timeSheetId}/export`
    ).then(({ data }) => {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${input.fileName}.txt`)
    })
  },
  downloadTimeSheetPdf(input) {
    return axios.get(
      `/time-sheet/${input.timeSheetId}/download-pdf`,
        { responseType: 'blob' }
    ).then(({ data }) => {
      saveAs(data, input.fileName)})
  },
  submitTimeSheet(input) {
    return mutate({ mutation: SUBMIT_TIME_SHEET, variables: { input } })
  },
  acceptTimeSheet(input) {
    return mutate({ mutation: ACCEPT_TIME_SHEET, variables: { input } })
  },
  rejectTimeSheet(input) {
    return mutate({ mutation: REJECT_TIME_SHEET, variables: { input } })
  },
  exportTimeSheetToDatev(input) {
    return mutate({ mutation: EXPORT_TIME_SHEET_TO_DATEV, variables: { input } })
  },
  exportTimeIntervals(filename, input) {
    return axios.post(
      '/export-time-records',
        input
    ).then(({ data }) => {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${filename}.csv`)
    })
  },
}

export default timeAccountManagementService
