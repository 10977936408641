import axios from '../../services/axios/axios'
import apollo, { gql, mutate, watchQuery } from '../../services/apollo/apollo'
import { DateTime } from 'luxon'

const ME_QUERY = gql`
  query MeQuery($input: OrganizationInput!) {
    me {
      id
      license(input: $input)
      staff(input: $input) {
        id,
        admin,
        owner,
        onboarding {
          showCreateOwnLeaveAgreement,
          showCreateOwnWorkingTimeAgreement,
          showChooseOrganizationFunctionality
        }
      }
      email {
        address
      }
      staff(input: $input) {
        id
        firstName
        lastName
      }
      executableTasks(input: $input) {
        definition {
            category
            predicate
        }
        subject {
            name
        }
        object {
          name
        }
      }
      displayTimeDistributionTile(input: $input)
    }
  }
`

const ME_QUERY_WITHOUT_STAFF_DATA = gql`
  query MeQueryWithoutStaffData {
    me {
      id
      email {
        address,
        confirmed
      }
    }
  }
`

const ME_QUERY_WITH_STAFF_DATA = gql`
  query MeQueryWithStaffData($input: OrganizationInput!) {
    me {
      id
      license(input: $input)
      staff(input: $input) {
        id
        firstName
        lastName
      }
      email {
        address,
        confirmed
      }
    }
  }
`

const ME_QUERY_WITH_CURRENT_CLOCK_STATE = gql`
  query MeQueryWithCurrentClockState($input: OrganizationInput!) {
    me {
      id
      license(input: $input)
      staff(input: $input) {
        id
        firstName
        lastName
        currentClockState {
          clockedIn
          clockedInSince
          clockedTimeForCurrentDay
        }
      }
      executableTasks(input: $input) {
        definition {
          category
          predicate
        }
        subject {
          name
        }
        object {
          name
        }
      }
    }
  }
`

const ME_QUERY_WITH_CURRENT_WORKING_WEEK_AND_TIME_ACCOUNT = gql`
  query MeQueryWithCurrentWorkingweekAndTimeAccount($input: OrganizationInput!) {
    me {
      id
      license(input: $input)
      staff(input: $input) {
        id
        firstName
        lastName
        currentWorkingWeek {
          id
          targetWorkingTime
          weekNumber
          workedTime
          workingDays {
            agreedWorkingTime
            day
            overtime
            targetWorkingTime
            workedTime
          }
        }
          overtime
      }
      executableTasks(input: $input) {
        definition {
          category
          predicate
        }
        subject {
          name
        }
        object {
          name
        }
      }
    }
  }
`

const ME_QUERY_WITH_CURRENT_LEAVE_YEAR = gql`
  query MeQueryWithLeaveAccount($input: OrganizationInput!) {
    me {
      id
      license(input: $input)
      staff(input: $input) {
        id
        firstName
        lastName
        leaveAccount {
          id
          currentLeaveYear {
            receivedLeave
            remainingLeave
            takenLeave
            targetLeave
            year
          }
        }
        upcomingLeaves {
          id
          start
          end
          totalCost
          type
        }
      }
      executableTasks(input: $input) {
        definition {
          category
          predicate
        }
        subject {
          name
        }
        object {
          name
        }
      }
    }
  }
`

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`

const CHANGE_PIN = gql`
  mutation changePin($input: ChangePinInput!) {
    changePin(input: $input)
  }
`

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      email {
        address,
        confirmed
      }
    }
  }
`

const RESEND_REGISTRATION_MAIL = gql`
  mutation resendRegistrationMail {
    resendRegistrationMail
  }
`

const logoutStatusCodes = [400, 401, 403, 405]

function clearCache (response) {
  this.setAuthenticated(false)
  this.setEmailConfirmed(false)
  this.setXsrfToken(null)
  this.setAccessToken(null)
  this.setRefreshToken(null)
  this.setClientId(null)
  this.setAccessExpiry(null)
  apollo.clearStore()
  return response
}

function cacheData (response) {
  this.setAuthenticated(true)
  this.setXsrfToken(response.data.xsrfToken)
  this.setAccessToken(response.data.accessToken)
  this.setRefreshToken(response.data.refreshToken)
  this.setAccessExpiry(response.data.expiresIn)
  this.setClientId(response.data.clientId)
  return response
}

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
   // convert bytes to hex string
  return hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

const userManagementService = {
  login ({ username, password }) {
    const response = axios.post('/user/sign-in', { username, password })
      .then(cacheData.bind(this))

    digestMessage(username).then((digestHex) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'event': 'user-logged-in', 'userId': digestHex })
    })

    return response
  },

  async access () {
    let response = null
    try {
      response = await axios.post('/user/access', { clientId: this.getClientId(), refreshToken: this.getRefreshToken() })
      this.setXsrfToken(response.data.xsrfToken)
      this.setAccessExpiry(response.data.expiresIn)
      this.setAccessToken(response.data.accessToken)
      this.setRefreshToken(response.data.refreshToken)
    } catch (e) {
      if (logoutStatusCodes.indexOf(e.response.status > -1)) {
        this.setAuthenticated(false)
      }
    }
    return response
  },

  isAccessExpired () {
    return DateTime.fromISO(this.getAccessExpiry()) <= DateTime.now()
  },

  setAccessExpiry (expiresInSeconds) {
    expiresInSeconds
      ? localStorage.setItem('accessExpiry', DateTime.now().plus({ seconds: expiresInSeconds * 0.75 }).toString())
      : localStorage.removeItem('accessExpiry')
  },

  getAccessExpiry () {
    return localStorage.getItem('accessExpiry')
  },

  registerUser (input) {
    return axios.post('/user/register', { ...input, locale: navigator.language }).then((response) => {
      this.setEmailConfirmed(response.data.email.confirmed)
      return response
    })
  },

  deleteUser () {
    return axios.post('/user/delete')
      .then(clearCache.bind(this))
  },

  isMailTaken (input) {
    return axios.post('/user/mail-taken', input)
  },

  requestPasswordReset (input) {
    return axios.post('/user/request-password-reset', input)
  },

  resetPassword (input) {
    return axios.post('/user/reset-password', input)
  },

  logout () {
    this.setAuthenticated(false)
    return axios.post('/user/sign-out', { clientId: this.getClientId() }).finally(clearCache.bind(this))
  },

  isAuthenticated () {
    return localStorage.getItem('authenticated') != null
  },

  setAuthenticated (authenticated) {
    authenticated ? localStorage.setItem('authenticated', true) : localStorage.removeItem('authenticated')
  },

  setXsrfToken (token) {
    token ? localStorage.setItem('x-xsrf-token', token) : localStorage.removeItem('x-xsrf-token')
  },

  getXsrfToken () {
    return localStorage.getItem('x-xsrf-token')
  },

  setAccessToken (token) {
    token ? localStorage.setItem('access-token', token) : localStorage.removeItem('access-token')
  },

  getAccessToken () {
    return localStorage.getItem('access-token')
  },

  setRefreshToken (token) {
    token ? localStorage.setItem('refresh-token', token) : localStorage.removeItem('refresh-token')
  },

  getRefreshToken () {
    return localStorage.getItem('refresh-token')
  },

  isEmailConfirmed () {
    return localStorage.getItem('emailConfirmed') != null
  },

  setEmailConfirmed (emailConfirmed) {
    emailConfirmed ? localStorage.setItem('emailConfirmed', true) : localStorage.removeItem('emailConfirmed')
  },

  getClientId () {
    return localStorage.getItem('clientId')
  },

  setClientId (clientId) {
    clientId ? localStorage.setItem('clientId', clientId) : localStorage.removeItem('clientId')
  },

  getMe (variables, { fetchPolicy }) {
    return watchQuery({ query: ME_QUERY, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  fetchLoggedInUser (variables) {
    return watchQuery({ query: ME_QUERY_WITHOUT_STAFF_DATA, variables, fetchPolicy: 'cache-first' })
  },

  getMeWithBasicStaffData (variables) {
    return watchQuery({ query: ME_QUERY_WITH_STAFF_DATA, variables, fetchPolicy: 'cache-first' })
  },

  getMeWithCurrentClockState (variables, { pollInterval }) {
    return watchQuery({ query: ME_QUERY_WITH_CURRENT_CLOCK_STATE, variables, pollInterval })
  },

  getMeWithCurrentWorkingWeekAndTimeAccount (variables) {
    return watchQuery({ query: ME_QUERY_WITH_CURRENT_WORKING_WEEK_AND_TIME_ACCOUNT, variables })
  },

  getMeWithCurrentLeaveYear (variables) {
    return watchQuery({ query: ME_QUERY_WITH_CURRENT_LEAVE_YEAR, variables })
  },

  changePassword (input) {
    return mutate({ mutation: CHANGE_PASSWORD, variables: { input } })
  },

  changePin (input) {
    return mutate({ mutation: CHANGE_PIN, variables: { input } })
  },

  confirmEmail (variables) {
    return mutate({ mutation: CONFIRM_EMAIL, variables }).then((fetchResult) => {
      if (fetchResult.errors) return fetchResult
      userManagementService.setEmailConfirmed(true)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'event': 'email-confirmed' })
      return fetchResult
    })
  },

  resendRegistrationMail (input) {
    return mutate({ mutation: RESEND_REGISTRATION_MAIL })
  }
}

export default userManagementService
export { ME_QUERY_WITH_CURRENT_CLOCK_STATE, ME_QUERY }
