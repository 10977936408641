const common = {
  absences: 'Absences',
  all: 'All',
  annualLeave: 'Annual leave',
  attendanceMonitors: 'Attendance monitors',
  audits: 'Audits',
  budget: 'Budget',
  clockIn: 'Clock in',
  clockOut: 'Clock out',
  comment: 'Comment',
  confirmPassword: 'Confirm password',
  contactPerson: 'Contact person',
  create: 'Create',
  currentPassword: 'Current password',
  customer: 'Customer',
  customerAddress: 'Address',
  customerAnalysis: 'Customer analysis',
  customerName: 'Name',
  customers: 'Customers',
  description: 'Description',
  deviceName: 'Device name',
  distributable: 'Distributable',
  distribute: 'Distribute',
  distributed: 'Distributed',
  distributions: 'Time distributions',
  download: 'Download',
  employee: 'Employee',
  end: 'End',
  forgotPassword: 'Forgot password?',
  holiday: 'Holiday',
  hourlyRate: 'Hourly rate',
  invoicableTime: 'Invoicable',
  invoice: 'Invoice',
  invoiceLineItems: 'Invoice line items',
  invoicedTime: 'Invoiced',
  invoiceNumber: 'Invoice number',
  invoices: 'Invoices',
  invoiceTemplate: 'Invoice template',
  invoiceTemplates: 'Invoice templates',
  leave: 'Leave',
  leaveAccount: 'Leave account',
  leaveAgreements: 'Leave agreements',
  leaveAndTimeAccounts: 'Leave and time accounts',
  leaves: 'Leaves',
  logout: 'Sign out',
  members: 'Members',
  name: 'Name',
  newPassword: 'New password',
  number: 'Number',
  order: 'Order',
  orderAnalysis: 'Order analysis',
  orderName: 'Name',
  orders: 'Orders',
  organization: 'Organization',
  organizationalUnit: 'Organizational unit',
  organizationalUnits: 'Organizational units',
  organizations: 'Organizations',
  overtime: 'Overtime',
  password: 'Password',
  publicHolidays: 'Public holidays',
  recordings: 'Time records',
  requestLeave: 'Request leave',
  recordedTimes: 'Recorded times',
  role: 'Role',
  security: 'Security',
  sickNote: 'Sick note',
  sickNotes: 'Sick notes',
  signIn: 'Sign in',
  signUp: 'Sign up',
  staff: 'Staff',
  start: 'Start',
  status: 'Status',
  submitSickNote: 'Submit sick note',
  supervisor: 'Supervisor',
  takenLeave: 'Taken leave',
  targetLeave: 'Leave entitlement',
  tasks: 'Tasks',
  time: 'Time',
  timeAccount: 'Time account',
  timeRecord: 'Time record',
  toDos: 'To-dos',
  upcomingHolidays: 'Upcoming holidays',
  vat: 'VAT',
  workingTime: 'Working time',
  workingTimeAgreements: 'Working time agreements',
  workingWeek: 'Working Week',
}

export default {
  breadcrumb: {
    absences: common.absences,
    attendanceMonitor: 'Attendance monitor',
    attendanceMonitors: common.attendanceMonitors,
    create: common.create,
    customer: common.customer,
    customerAnalysis: common.customerAnalysis,
    customers: common.customers,
    distributableDay: 'Distributable day',
    distribute: 'Distribute',
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    download: common.download,
    holiday: common.holiday,
    home: 'Home',
    invoice: common.invoice,
    invoiceLineItem: 'Invoice line item',
    invoiceLineItems: common.invoiceLineItems,
    invoiceOrders: 'Invoice orders',
    invoices: common.invoices,
    invoiceTemplate: common.invoiceTemplate,
    invoiceTemplates: common.invoiceTemplates,
    leave: common.leave,
    leaveAccount: common.leaveAccount,
    leaveAgreement: 'Leave agreement',
    leaveAgreements: common.leaveAgreements,
    leaves: common.leaves,
    order: common.order,
    orderAnalysis: common.orderAnalysis,
    orders: common.orders,
    organizationalUnit: common.organizationalUnit,
    organizationalUnits: common.organizationalUnits,
    overtime: common.overtime,
    members: common.members,
    publicHolidays: common.publicHolidays,
    recordedTimes: common.recordedTimes,
    recordings: common.recordings,
    registerAttendanceMonitor: 'Register',
    requestLeave: common.requestLeave,
    scheduledBreaks: 'Scheduled breaks',
    sickNote: 'Sick note ',
    sickNotes: common.sickNotes,
    staff: common.staff,
    submitSickNote: common.submitSickNote,
    tasks: common.tasks,
    timeAccount: common.timeAccount,
    timeDistribution: 'Time distribution',
    timeRecord: common.timeRecord,
    timeSheetEdit: 'Edit time sheet',
    timeSheetList: 'Time sheets',
    timeSheet: 'Time sheet',
    toDos: common.toDos,
    upcomingHolidays: common.upcomingHolidays,
    workingTime: common.workingTime,
    workingTimeAgreement: 'Working time agreement',
    workingTimeAgreements: common.workingTimeAgreements,
    workingWeek: common.workingWeek,
    workplace: 'Workplace',
    workplaces: 'Workplaces',
    workplaceCreate: 'Create workplace',
  },
  button: {
    accept: 'Accept',
    activate: 'Enable',
    add: 'Add',
    approve: 'Approve',
    back: 'Back',
    cancel: 'Cancel',
    cancelPinInput: 'Cancel',
    chooseEssentials: 'Choose Essentials',
    chooseProfessional: 'Choose Professional',
    clockIn: common.clockIn,
    clockOut: common.clockOut,
    close: 'Close',
    connectWithDatev: 'Connect with DATEV',
    create: common.create,
    deactivate: 'Deactivate',
    decrement: 'Decrement',
    delete: 'Delete',
    disable: 'Disable',
    distribute: common.distribute,
    done: 'Done',
    download: common.download,
    downloadTimeSheetExportConfigurationFile: 'Download DATEV configuration file',
    edit: 'Save',
    enable: 'Enable',
    enter: 'Enter',
    export: 'Export',
    hideFeatures: 'hide features',
    import: 'Import',
    importStaff: 'Import staff',
    increment: 'Increment',
    invite: 'Invite',
    invoice: 'Invoice',
    invoiceOrders: 'Invoice orders',
    leaveOrganization: 'Leave',
    letterhead: 'Use letterhead',
    letsGo: 'Let\'s go',
    logout: common.logout,
    markAsDefault: 'Mark as default',
    next: 'Next',
    passOrganizationOwner: 'Send request',
    printPdf: 'Print (PDF)',
    register: 'Register',
    reject: 'Reject',
    remove: 'Remove',
    reopen: 'Reopen',
    request: 'Request',
    resend: 'Resend',
    reset: 'Reset',
    retryDatevExport: 'Export to DATEV',
    revoke: 'Revoke',
    revokeAuthorization: 'Revoke DATEV connection',
    save: 'Save',
    saveAndNext: 'Save and continue',
    showFeatures: 'show features',
    signIn: common.signIn,
    signUp: common.signUp,
    skip: 'Skip',
    start: 'Start',
    startNow: 'Start now',
    stop: 'Stop',
    submit: 'Submit',
    switchToEssentials: 'Switch to Essentials',
    switchToProfessional: 'Switch to Professional',
    timeSheet: 'Time sheet',
    tryAgain: 'Try again',
    turnOff: 'Turn off',
  },
  checkbox: {
    commentRequired: 'Comment required',
    halfDay: 'Half day',
    newsletter: 'I would like to receive regular news about ZeitFabrik.',
    privacy: 'I agree to the {0}.',
    tac: 'I agree to the {0} of CodeFabrik GmbH for the use of ZeitFabrik.'
  },
  enum: {
    accepted: 'Approved',
    active: 'Active',
    all: common.all,
    allOrders: 'All orders',
    closedOrdersOnly: 'Closed orders only',
    created: 'Created',
    distributable: common.distributable,
    distributed: common.distributed,
    employee: common.employee,
    inactive: 'Inactive',
    invited: 'Invited',
    leaveType: {
      overtime_compensation: 'Overtime compensation',
      regular: common.leave,
      special: 'Special leave'
    },
    new: 'New',
    openOrdersOnly: 'Open orders only',
    overbooked: 'Overbooked',
    pending: 'Pending',
    rejected: 'Rejected',
    supervisor: common.supervisor,
    submitted: 'Submitted'
  },
  fileName: {
    timeSheet: 'Time sheet',
    timeRecords: 'Time records'
  },
  help: {
    authenticationFailed: 'Authentication failed. Please check your credentials.',
    createStaffAnnualLeaveTargetLeave: 'The leave entitlement per year.',
    createStaffDatesContractEnd: 'The contract is considered open-ended if no date is entered.',
    createStaffDatesContractStart: 'The hiring date of {firstName}.',
    createStaffDatesStartUsage: 'From which date should {firstName} record times?',
    csvUpload: 'Click on the input to select a csv file',
    currentPassword: 'The current password is required in order to change it.',
    currentPasswordForPinChange: 'The current password is required in order to change the pin.',
    distributedOrderTimeAlreadyInvoiced: 'Time distribution has already been invoiced.',
    distributedOrderTimeForFilterMissing: 'There are no distributed times for this filter settings.',
    distributedOrderTimeInIntervalMissing: 'There are no distributed times in this interval.',
    deactivateStaffDisabledReasonAdmin: 'The employee is an admin and therefore cannot be deactivated.',
    deactivateStaffDisabledReasonPendingOwner: 'The employee is the recipient of an open organization ownership request and therefore cannot be deactivated.',
    deleteInvoiceTemplateDisabled: 'The default invoice template cannot be deleted.',
    deleteLeaveDisabled: 'Task to delete leave is missing.',
    deleteSickNoteDisabled: 'Task to delete sick notes is missing',
    deleteStaffDisabled: 'The staff has distributed times.',
    deleteTimeRecordDisabled: 'Task to delete time records is missing.',
    emailIsTaken: 'Email is already registered.',
    emailUsername: 'This will be your username.',
    imageUpload: 'The letterhead must be uploaded as an image file. For example .jpeg or .png.',
    invalidPassword: 'Invalid password.',
    invoiceMessage: 'An optional message to your customer.',
    invoicePartyAddress: 'Will be shown above the address header of your invoice.',
    invoiceRecipient: 'The customer to bill.',
    invoiceRecipientAddress: 'This is the address of the selected customer.',
    leaveAgreementEnd: 'If no end date is set, the leave agreement will be open-ended.',
    manageDistributedOrderTimeTaskMissing: 'Task to manage time distributions is missing.',
    name: 'Your company or team name is suited best.',
    organizationName: 'Your company or team name is suited best.',
    privacy: 'Agreement to privacy policy',
    receivedLeave: 'The amount of leave that has carried over from previous years.',
    requestPasswordReset: 'Your email for signing in.',
    tac: 'Agreement to terms and conditions',
    takenLeave: 'The amount of leave this staff has already taken.',
    targetLeave: 'The target leave for the current year.',
    unsubscribeAtAnyTime: 'You can unsubscribe at any time.',
    workingTimeAgreementEnd: 'If no end date is set, the working time agreement will be open-ended.',
  },
  label: {
    address: 'Address',
    annualLeave: common.annualLeave,
    all: 'No restriction',
    balance: 'Balance',
    break: 'Break',
    breakTime: 'Break time',
    budget: common.budget,
    calendarWeekAbbreviation: 'CW',
    change: 'Change',
    changedBy: 'Changed by',
    clientNumber: 'Client number',
    clockedIn: 'Clocked In',
    clockedOut: 'Clocked Out',
    comment: common.comment,
    confirm: 'Confirm',
    confirmPassword: common.confirmPassword,
    connectionCreatedByDatevUser: 'Connection created by DATEV user:',
    consultantNumber: 'Consultant number',
    contactPerson: common.contactPerson,
    creationDate: 'Creation date',
    crn: 'CRN',
    csv: 'CSV File',
    currentEdition: 'Current Edition',
    currentPassword: common.currentPassword,
    currentMonth: 'Current month',
    currentWeek: 'Current week',
    customer: common.customer,
    customerAddress: common.customerAddress,
    customerName: common.customerName,
    customerReferenceNumber: 'Customer reference number',
    date: 'Date',
    datevClientId: 'DATEV ClientId: ',
    datevExportFormat: 'DATEV export format',
    datevNotificationEmailAddress: 'Email address for notifications (optional)',
    datevFileStatus: 'DATEV file status',
    datevLODAS: 'LODAS',
    datevLUG: 'LUG',
    datevRefreshTokenExpiryDate: 'Refresh Token Expiry date:',
    day: 'Day',
    dayOff: 'Day off',
    days: 'Days',
    default: 'Default',
    description: common.description,
    deviceName: common.deviceName,
    distributedTime: 'Distributed time:',
    duration: 'Duration',
    email: 'Email',
    employee: common.employee,
    end: common.end,
    error: 'Error',
    firstName: 'First name',
    friday: 'Friday',
    from: 'from',
    functionality: 'Functionality',
    halfHoliday: 'Half holiday',
    hello: 'Hello',
    holiday: common.holiday,
    hourlyRate: common.hourlyRate,
    hours: 'Hours',
    importable: 'Importable',
    importPossible: 'Import possible',
    importImpossible: 'Import impossible',
    importStaffIdentificationNumberAsCompanyStaffIdentificationNumber: 'Additionally import staff identification number as company staff identification number?',
    invoicableTime: common.invoicableTime,
    invoicedTime: common.invoicedTime,
    invoiceDate: 'Invoice date',
    invoiceDeliveryPeriod: 'Delivery period',
    invoiceMessage: 'Message',
    invoiceNumber: common.invoiceNumber,
    invoicePartyAddress: 'Invoice party address',
    invoicePaymentTerms: 'Payment terms',
    invoiceRecipient: 'Invoice recipient',
    invoiceRecipientAddress: 'Invoice recipient address',
    invoiceTemplate: common.invoiceTemplate,
    invoicingParty: 'Invoicing party',
    invoicingPartyVatNumber: 'Invoicing party VAT identification number',
    lastName: 'Last name',
    leave: common.leave,
    leaveHalfDay: (ctx) => `Half day ${ctx.list(0).toLowerCase()}`,
    leaveType: 'Leave type',
    legallyCompliant: 'Legally compliant time tracking',
    letterhead: 'Letterhead',
    monday: 'Monday',
    month: 'Month',
    months: 'Months',
    name: common.name,
    newPassword: common.newPassword,
    newPin: 'New pin',
    number: common.number,
    of: 'of',
    order: common.order,
    orderName: common.orderName,
    organization: common.organization,
    organizationalUnit: common.organizationalUnit,
    overtime: common.overtime,
    overtimeAccount: 'Overtime account',
    password: common.password,
    percent: 'Percent',
    price: 'Gross',
    priceBeforeTax: 'Net',
    processedAt: 'Processed At',
    processedBy: 'Processed By',
    totalPrice: 'Gross total',
    totalPriceBeforeTax: 'Net total',
    type: 'Art',
    quantity: 'QTY',
    reason: 'Reason',
    recordedWorkingTime: 'Recorded working time',
    remainingLeave: 'Remaining leave',
    role: common.role,
    saturday: 'Saturday',
    search: 'Search',
    sixMonths: 'Six Months',
    staff: common.staff,
    staffIdentificationNumber: 'Staff identification number',
    start: common.start,
    status: common.status,
    sick: 'Sick',
    sunday: 'Sunday',
    supervisor: common.supervisor,
    takenLeave: common.takenLeave,
    targetLeave: common.targetLeave,
    targetWorkingTime: 'Target working time',
    thursday: 'Thursday',
    time: common.time,
    timeRecord: common.timeRecord,
    timeSheet: 'Timesheet',
    to: 'to',
    transferredLeave: 'Transferred leave',
    tuesday: 'Tuesday',
    unitPrice: 'Unit price',
    upcomingLeave: 'Upcoming leave',
    vat: common.vat,
    vatNumber: 'VAT number',
    wednesday: 'Wednesday',
    week: 'Week',
    workingTime: common.workingTime,
    year: 'Year',
    years: 'Years',
    yearCycle: 'Year cycle',
    priceTable: 'Price table',
    currentLicense: 'Current license'
  },
  link: {
    absences: common.absences,
    account: 'User account',
    accountingSettings: 'Accounting settings',
    attendanceMonitors: common.attendanceMonitors,
    audits: common.audits,
    back: 'Back',
    connect: 'Connect',
    controlling: 'Controlling',
    cookieConsent: 'Cookie consent',
    createOrganization: 'Create organization',
    customerAnalysis: common.customerAnalysis,
    customers: common.customers,
    datev: 'DATEV',
    datevConnectedApplications: 'Manage connected applications',
    datevHelpPage: 'DATEV Help',
    demo: 'Book a demo',
    distribute: common.distribute,
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    done: 'Done',
    download: common.download,
    edit: 'Edit',
    faq: 'FAQ',
    firstSteps: 'First steps',
    forgotPassword: common.forgotPassword,
    goToOptions: 'Go to options',
    help: 'Help',
    helpCenter: 'Visit help center',
    here: 'here',
    imprint: 'Imprint',
    invoice: common.invoice,
    invoices: common.invoices,
    invoiceLineItems: common.invoiceLineItems,
    invoiceTemplates: common.invoiceTemplates,
    leaveAccount: common.leaveAccount,
    leaveAgreements: common.leaveAgreements,
    leaveAndTimeAccounts: common.leaveAndTimeAccounts,
    leaves: common.leaves,
    logout: common.logout,
    manageLicense: 'Manage license',
    manageOrganization: 'Manage organization',
    members: common.members,
    more: 'More',
    orderAnalysis: common.orderAnalysis,
    orders: common.orders,
    organization: 'Organization',
    organizationalUnits: common.organizationalUnits,
    organizations: common.organizations,
    overtime: common.overtime,
    privacy: 'privacy policy',
    privacyFooter: 'Privacy policy',
    profile: 'Profile',
    publicHolidays: common.publicHolidays,
    recordedTimes: common.recordedTimes,
    recordings: common.recordings,
    scheduledBreaks: 'Scheduled breaks',
    security: common.security,
    sickNotes: common.sickNotes,
    signIn: common.signIn,
    signUp: common.signUp,
    staff: common.staff,
    tac: 'terms and conditions',
    tacFooter: 'Terms and conditions',
    tasks: common.tasks,
    timeAccount: common.timeAccount,
    timeIntervalExport: 'Time interval export',
    times: 'Times',
    timeSheet: 'Time sheets',
    upcomingHolidays: common.upcomingHolidays,
    workplaces: 'Workplaces',
    workingTime: common.workingTime,
    workingTimeAgreements: common.workingTimeAgreements,
    workingTimeLaw: 'Working Time Law',
    workingTimeLawChecks: 'Checks'
  },
  notification: {
    adminPrivilegesAdded: 'Admin privileges have been successfully added.',
    adminPrivilegesRemoved: 'Admin privileges have been successfully removed.',
    attendanceMonitorDeleted: 'Attendance monitor has successfully been deleted.',
    attendanceMonitorEdited: 'Attendance monitor has successfully been saved.',
    attendanceMonitorRegistered: 'Attendance monitor has successfully been registered.',
    breakScheduled: 'Break scheduled successfully',
    customerCreated: 'Customer has successfully been created.',
    customerDeleted: 'Customer has successfully been deleted.',
    customerEdited: 'Customer has successfully been saved.',
    datevAuthorizationRevoked: 'DATEV authorization successfully revoked.',
    datevConfigurationCreated: 'DATEV configuration has been successfully created.',
    datevConfigurationEdited: 'DATEV configuration has been successfully edited.',
    datevExportActivated: 'DATEV export has been successfully activated.',
    datevExportDeactivated: 'DATEV export has been successfully deactivated.',
    distributedOrderTimeDeleted: 'Distributed order time has successfully been deleted.',
    distributedOrderTimeEdited: 'Distributed order time has successfully been saved.',
    edited: 'User has been successfully saved.',
    germanWorkingTimeLawChecksDisabled: 'Automatic check of the german working time law disabled.',
    germanWorkingTimeLawChecksEnabled: 'Automatic check of the german working time law enabled.',
    holidayCreated: 'Holiday has been successfully created.',
    holidayDeleted: 'Holiday has been successfully deleted.',
    invitationAccepted: 'Invitation has been successfully accepted.',
    invitationDeclined: 'Invitation has been successfully declined.',
    invitationEmailSent: 'Invitation email successfully sent',
    invitationRevoked: 'Invitation successfully revoked',
    invoiceCreated: 'Invoice has been successfully created.',
    invoiceEdited: 'Invoice has been successfully saved.',
    invoiceDeleted: 'Invoice has been successfully deleted.',
    invoiceLineItemCreated: 'Invoice line item has been successfully created.',
    invoiceLineItemEdited: 'Invoice line item has been successfully saved.',
    invoiceLineItemDeleted: 'Invoice line item has been successfully deleted.',
    invoiceOrdersInvoiced: 'Orders have been successfully invoiced.',
    invoiceTemplateCreated: 'Invoice template has successfully been created.',
    invoiceTemplateDeleted: 'Invoice template has successfully been deleted.',
    invoiceTemplateEdited: 'Invoice template has successfully been edited.',
    invoiceTemplateMarkedAsDefault: 'Invoice template has successfully been marked as default.',
    leaveAccepted: 'Leave has been successfully approved.',
    leaveAccountEdited: 'Leave account has been successfully saved.',
    leaveAgreementCreated: 'Leave agreement successfully created.',
    leaveAgreementEdited: 'Leave agreement has successfully been saved.',
    leaveAgreementDeleted: 'Leave agreement has successfully been deleted.',
    leaveCreated: 'Leave has been successfully created.',
    leaveDeleted: 'Leave has been successfully deleted.',
    leaveEdited: 'Leave has been successfully saved.',
    leaveRejected: 'Leave has been successfully rejected.',
    mailResent: 'Email has successfully been sent.',
    memberRemoved: 'Member successfully removed.',
    membersAdded: 'Members successfully added.',
    manualTimeSheetExportToDatevSuccessful: 'Export successful',
    orderClosed: 'Order has successfully been closed.',
    orderCreated: 'Order has successfully been created.',
    orderDeleted: 'Order has successfully been deleted.',
    orderEdited: 'Order has successfully been saved.',
    orderReopened: 'Order has successfully been reopened.',
    organizationalUnitCreated: 'Organizational unit successfully created.',
    organizationalUnitDeleted: 'Organizational unit successfully deleted.',
    organizationalUnitEdited: 'Organizational unit successfully saved.',
    organizationCreated: 'Organization has been successfully created.',
    organizationEdited: 'Organization has successfully been saved.',
    organizationDeleted: 'Organization has been deleted successfully.',
    organizationLeft: 'You successfully left the organization.',
    organizationLicenseChanged: 'License successfully changed.',
    organizationOwnershipAccepted: 'Organization ownership accepted.',
    organizationOwnershipDeclined: 'Organization ownership declined.',
    overtimeDecremented: 'Overtime has been successfully decremented.',
    overtimeIncremented: 'Overtime has been successfully incremented.',
    passOrganizationOwnerRequestCreated: 'Pass organization owner request created.',
    passOrganizationOwnerRequestRevoked: 'Pass organization owner request revoked.',
    passwordChanged: 'Password has successfully been changed.',
    pinChanged: 'Pin has successfully been changed.',
    passwordReset: 'Password has been successfully reset.',
    passwordResetRequested: 'E-Mail with instructions has been sent.',
    publicHolidaysReplaced: 'Public holidays have been successfully saved.',
    sickNoteCreated: 'Sick note has been successfully created.',
    sickNoteSubmitted: 'Sick note has been successfully submitted.',
    sickNoteDeleted: 'Sick note has been successfully deleted.',
    sickNoteAccepted: 'Sick note has been successfully accepted.',
    sickNoteRejected: 'Sick note has been successfully rejected.',
    sickNoteEdited: 'Sick note has been successfully saved.',
    staffClockedIn: 'Clock in successful.',
    staffClockedOut: 'Clock out successful.',
    staffCreated: 'Staff successfully created.',
    staffDeactivated: 'Staff successfully deactivated.',
    staffDeleted: 'Staff successfully deleted.',
    staffEdited: 'Staff successfully saved.',
    staffExcluded: 'Staff successfully excluded from monitoring.',
    staffImported: 'Staff members successfully imported',
    staffIncluded: 'Staff successfully included for monitoring.',
    taskTypesReplaced: 'Tasks successfully saved.',
    timeDistributed: 'Time has successfully been distributed.',
    timeRecordCanceled: 'Time record has successfully been canceled.',
    timeRecordCreated: 'Time record has successfully been created.',
    timeRecordDeleted: 'Time record has successfully been deleted.',
    timeRecordEdited: 'Time record has successfully been saved.',
    timeSheetCreated: 'Time sheet successfully created.',
    timeSheetEdited: 'Time sheet successfully edited.',
    timeSheetDeleted: 'Time sheet successfully deleted.',
    timeSheetSubmitted: 'Time sheet successfully submitted.',
    timeSheetAccepted: 'Time sheet successfully accepted.',
    timeSheetRejected: 'Time sheet successfully rejected.',
    workingTimeAgreementCreated: 'Working time agreement successfully created.',
    workingTimeAgreementDeleted: 'Working time agreement successfully deleted.',
    workingTimeAgreementEdited: 'Working time agreement successfully saved.',
    workplaceCreated: 'Workplace successfully created.',
    workplaceDisabled: 'Workplace successfully disabled.',
    workplaceEnabled: 'Workplace successfully enabled.',
  },
  paragraph: {
    absenceOverview: 'Absence Overview',
    allowedWorkingTimeExceeded: 'Maximum permissible working time of 10 hours exceeded by {duration}',
    averageWorkingTimeLimitExceeded: 'Daily working time averaged over the last 24 weeks {duration} over 8 hours',
    breakTimeInsufficientSixToNineHours: 'Break times in total {duration} less than 30 minutes for working time between 6 and 9 hours. Only counts breaks of minimum 15 minutes according to the law.',
    breakTimeInsufficientNinePlusHours: 'Break times in total {duration} less than 45 minutes for working time of over 9 hours. Only counts breaks of minimum 15 minutes according to the law.',
    licenseMissing: 'Unfortunately, the {organizationName} organization does not have a valid license.',
    alreadyUserHint: 'Already a ZeitFabrik user?',
    chooseFunctionalityBasic: 'Record working time.',
    chooseFunctionalityAdvanced: 'Record working time, use time account incl. vacation and sick days.',
    clockedInSince: 'Clocked in since',
    clockedTimeForCurrentDay: 'Time',
    configureOrganization: 'We can configure your organization depending on your needs. You can change your mind at any time and alter the configuration.',
    customerAnalysis: 'Customer Analysis',
    customerCreate: 'Create a customer {0}.',
    customerManagement: 'Customer Management',
    datevAuthorizationNotPresent: 'To enable automated workflows between ZeitFabrik and DATEV, you need to connect ZeitFabrik to DATEV. The connection is currently not active.',
    datevAuthorizationPending: 'DATEV Autorisierung pending. Please wait...',
    datevAuthorizationPresent: 'ZeitFabrik is successfully connected to DATEV.',
    datevAuthorizationSuccessful: 'Successfully authorized. You will be redirected automatically in {countdown} seconds.',
    datevConfigurationMissing: 'In order to connect to DATEV, you first need to create a configuration.',
    datevConfiguration: 'The DATEV Configuration is present.',
    datevExportActive: 'Automatic export of timesheets to DATEV active.',
    datevExportDeactivated: 'Aktiviere den automatischen Export von Stundenzetteln, damit jeder akzeptierte Stundenzettel automatisch zu DATEV exportiert wird.',
    datevTimeSheetExportFailed: 'The automatic DATEV export of the time sheet failed. Your can manually trigger the export with the button below. If the export still won\'t work, consider our knowledge base: {0}',
    days: 'Days',
    distributable: common.distributable,
    distributableMoreToDistribute: '...and more',
    distributableNothingToDistribute: 'All time records have been distributed. No more chores left to do.',
    distributed: common.distributed,
    duration: '{duration} Hours',
    emailSupport: 'E-Mail Support',
    enterYourEmail: 'Enter your email.',
    enterPin: 'Please enter your PIN',
    exemptFromAudit: 'This person is exempt from audit. You can include them by going to the options and removing them from the list of exempt persons there.',
    exemptFromAuditSupervisor: 'This person is exempt from audit. An administrator can include them by going to the options and removing them from the list of exempt persons there.',
    exportToDATEV: 'DATEV-Export',
    features1: 'Includes',
    features2: 'everything from Essentials',
    features3: 'plus:',
    germanWorkingTimeLawChecks: {
      checksDisabled: 'Checks disabled',
      descriptionBreakTimesCheck:'ZeitFabrik monitors that the necessary break times are respected (§4 ArbZG).',
      descriptionDailyWorkingTimeCheck:'ZeitFabrik monitors that the daily maximum working hours are respected (§3 ArbZG).',
      descriptionRestPeriodsCheck: 'ZeitFabrik monitors that the rest period between two working days is respected (§5 ArbZG).',
      descriptionTimeRecordingNotPresentCheck: 'ZeitFabrik monitors that the working times are being recorded. That means every calendar day with a positive target working time should have at least one time recording (requires the use of time accounts in combination with working time agreements, leaves, sick days and holidays).',
      headingBreakTimesCheck: 'Monitoring of break times',
      headingDailyWorkingTimeCheck: 'Monitoring of working times',
      headingRestPeriodsCheck: 'Monitoring of rest times',
      headingTimeRecordingNotPresentCheck: 'Monitoring of time recordings',
      summary: 'ZeitFabrik verifies for you that the regulations of the Working Time Law (ArbZG) are respected.',
      summaryDisabled: 'The monitoring of the regulations of the Working Time Law (ArbZG) are deactivated.',
      youAreSafe: 'You\'re safe',
    },
    invitationError: 'The provided information does not match the invitation. Please check the link and try again.',
    invitationExpired: 'The invitation is expired. Please ask the invitee for a new invitation.',
    invoiceCustomerMissing: 'Invoices require a customer.',
    invoicing: 'Invoicing',
    invoiceOrdersMissing: 'The customer has no orders. For invoicing, times must be distributed to orders.',
    invoiceTemplates: 'Invoice Templates',
    lawViolation: 'Check for ArbZG violations',
    leaveAgreementMissing: 'Everyone needs a rest sometimes. We can keep track of your leave entitlement.',
    leaveRequest: 'Leave Request',
    leaveRequestToDoDescription: 'Answer leave request of {firstName} {lastName}.',
    letterhead: 'Letterhead',
    noStaffImportable: 'No importable staff found.',
    noOrdersFound: 'No orders found.',
    notAnAdmin: 'Only Administrators can access more details of {firstName} {lastName}.',
    orderAnalysis: 'Order Analysis',
    orderCreate: 'Create an order {0}.',
    orderManagement: 'Order Management',
    orderMissing: 'Time can only be distributed on active orders that are assigned to your organizational unit.',
    organizationalUnits: 'Organizational Units',
    organizationalUnitManagement: 'Organizational Unit Management',
    organizationOwnershipToDoDescription: 'Answer organization ownership request.',
    overtimeBalance: 'Current overtime balance:',
    perUserMonth: 'per User / month excl. taxes',
    restTimeInsufficient: 'Rest time between working days {duration} below the 11 hour minimum.',
    sickNote: 'Sick Note',
    sickNoteSubmissionToDoDescription: 'Review sick note of {firstName} {lastName}.',
    specialLeaveRequestToDoDescription: 'Review special leave request of {firstName} {lastName}.',
    overtimeCompensationLeaveRequestToDoDescription: 'Review overtime compensation leave request of {firstName} {lastName}.',
    staffManagement: 'Staff Management',
    taskEditDisabled: 'Some tasks cannot be edited because trust based flex time is enabled. Go to the dashboard to disable trust based flex time.',
    timeAccount: 'Time Account',
    timeDistribution: 'Time Distribution',
    timeLeft: 'Time left',
    timeRecordingNotPresent: 'Time recording missing (Target working time: {duration}).',
    timeSheet: 'Time Sheet',
    timeTracking: 'Time Tracking',
    timeSheetToDoSubscription: 'Review time sheet of {firstName} {lastName}.',
    trustBasedFlexTimeDisabled: 'Trust based flex time disabled',
    trustBasedFlexTimeEnabled: '✌️ Trust based flex time enabled ✌️',
    trustBasedFlexTimeExplained: 'If trust based flex time is enabled, supervisors won\'t be able to see employees\' working hours but will still be notified of working time law violations.',
    workedFromTo: 'worked from {0} to {1} ({2} hours)',
    workedFrom: 'worked {0} from {1} hours',
    workingTimeAgreementMissing: 'Since you record your times in ZeitFabrik we could sum them for you. Provide us your working times to put things into perspective!',
    workingTimeOverview: 'Working Time Overview',
    newHint: 'New to ZeitFabrik?',
    notifySupervisor: 'Please notify your supervisor.',
    rememberedPassword: 'Remember your password?'
  },
  placeholder: {
    all: common.all,
    annualLeave: common.annualLeave,
    budget: common.budget,
    confirmPassword: common.confirmPassword,
    contactPerson: common.contactPerson,
    contractOpenEnded: 'Open-ended',
    currentPassword: common.currentPassword,
    customerAddress: common.customerAddress,
    customerName: common.customerName,
    customerReferenceNumber: 'CRN',
    description: common.description,
    deviceName: common.deviceName,
    durationFormat: '00:00',
    email: 'Email',
    end: common.end,
    firstName: 'John',
    holidayName: 'Holiday',
    holidayYearCycle: 'Year cycle',
    hourlyRate: common.hourlyRate,
    invoiceMessage: 'Thanks for the cooperation.',
    invoicePartyAddress: 'Company | Street | City',
    invoicePaymentTerms: 'Payable within 14 days.',
    invoiceTemplateName: 'Invoice template #1',
    invoicingPartyVatNumber: 'VAT identification number',
    lastName: 'Doe',
    newPassword: common.newPassword,
    newPin: 'New pin',
    noResults: 'No results.',
    number: common.number,
    numberZero: '0.0',
    orderName: common.orderName,
    organizationAddress: 'Address of your organization',
    organizationDeleteSafetyText: 'Enter name of your organization to enable delete',
    organizationName: 'Name of your organization',
    organizationVatNumber: 'VAT number of your organization',
    organizationalUnit: common.organizationalUnit,
    organizationalUnitName: 'E.g. Human resources department',
    password: common.password,
    pleaseSelect: 'Please select',
    quantity: '1.00',
    searchMember: 'Search for members',
    selectAStaff: 'Select a staff',
    selectDayOfWeek: 'Select day of week',
    selectOrder: 'Select an order',
    selectOrganizationalUnit: 'Select an organizational unit',
    selectTime: 'Type a number',
    staff: common.staff,
    start: common.start,
    unitPrice: '$100.99',
    username: 'Username',
    vat: common.vat,
  },
  subtitle: {
    acceptInvitation: 'Please sign in to accept the invitation.',
    addAdminPrivileges: 'An administrator can change and see every aspect of the organization. Only trusted users should be granted these privileges.',
    answerLeaveRequest: 'Approving the leave will update the time and leave account of the staff.',
    answerPassOrganizationOwnershipRequest: 'The current owner of the organization has asked you to become the new owner. In order to do this you have to accept the terms and conditions. After accepting you will have additional rights, like deleting the organization.',
    answerSickNote: 'Approving the sick note will update the time and leave account of the staff.',
    assignColumnHeaders: 'Assign the column headers of your provided file to the corresponding values in ZeitFabrik.',
    assignOrganizationalUnit: 'Choose an organizational unit and a role for the staff.',
    cancelTimeRecord: 'The cancellation of a time record can not be undone.',
    changePassword: 'Successfully changing the password will result in a redirection to the login page.',
    changePin: 'The pin can be used to clock in and out with an attendance monitor.',
    checkAndTryAgain: 'Please check your internet connection and try again.',
    chooseStaffRole: 'How and what for should {firstName} use ZeitFabrik?',
    closeOrder: 'A closed order can be reopened. No times can be distributed on a closed order.',
    confirm: 'We have to make sure that we can reach you. Please check your inbox for a message with a confirmation link.',
    confirmEmailLogin: 'Please sign in to confirm your email address.',
    createOrganizationStaffName: 'This is how other users in your organization will know it\'s you.',
    createStaffAnnualLeave: 'How many annual leave days are entitled to {firstName}?',
    createStaffDates: 'For the calculation of the time and leave accounts of {firstName} we need the contract details and the start of use of ZeitFabrik.',
    createStaffLeaveAccount: 'After the setup, the account will be automatically updated by us.',
    createStaffLeaveAccountReceivedLeave: 'Are there any unused leave days from previous years?',
    createStaffLeaveAccountTakenLeave: 'How many days of leave that were not recorded in ZeitFabrik has {firstName} already taken this year?',
    createStaffName: 'The staff\'s name is displayed in various places in your organization.',
    createStaffSummary: 'Create staff {firstName} {lastName} now.',
    createStaffTimeAccount: 'After the setup, the account will be automatically updated by us.',
    createStaffWorkingTimes: 'Set the daily working hours of {firstName}.',
    deactivateStaff: 'Sets the status to inactive. The user will be removed from this staff. You can invite a new user afterwards.',
    deleteAccount: 'Attention: This will delete your user in ZeitFabrik. You cannot undo this step. As long as you are still part of an organization you cannot delete your user account.',
    deleteAttendanceMonitor: 'Deleted attendance monitors cannot be restored!',
    deleteCustomer: 'Deleted customers cannot be restored!',
    deleteCustomerRequirement: 'A customer can only be deleted if it is not referenced by any order or invoice.',
    deleteDistributedOrderTime: 'Deleted time distributions cannot be restored!',
    deleteHoliday: 'Holiday will be deleted immediately. Public holidays are restorable under \'Absences - Public holidays\'.',
    deleteInvoice: 'Invoiced times are released for re-invoicing.',
    deleteInvoiceLineItem: 'Any invoiced times will be reopened for invoicing.',
    deleteInvoiceTemplate: 'Deleted invoice templates cannot be restored!',
    deleteLeave: 'Deleted leaves cannot be restored!',
    deleteLeaveAgreement: 'Deleting a leave agreement leads to a recalculation of the leave account for the respective interval.',
    deleteOrder: 'Deleted orders cannot be restored!',
    deleteOrderRequirement: 'An order can only be deleted, if it has no distributable order times.',
    deleteOrganizationalUnit: 'Deleted organizational units cannot be restored!',
    deleteStaff: 'Deleted staff cannot be restored!',
    deleteStaffRequirement: 'A staff can only be deleted, if the staff has no distributed times.',
    deleteSickNote: 'Deleted sick notes cannot be restored!',
    deleteTimeRecord: 'Deleted time records cannot be restored!',
    deleteTimeSheet: 'Deleted time sheets cannot be restored!',
    deleteWorkingTimeAgreement: 'Deleting a working time agreement leads to a recalculation of the time account for the respective interval.',
    disableGermanWorkingTimeLawChecks: 'If you don\'t want to use the monitoring, you can disable it here. You can reactivate the verification at any time.',
    disableWorkplace: 'You can disable this workplace. Employees will no longer be able to clock time on this workplace. You can always enable the workplace if you change your mind.',
    downloadInvoice: 'The letterhead should be in a DIN A4 format to work properly.',
    editLeaveAccount: 'Be aware that the leave account is calculated automatically. Only edit the leave account to reflect changes that are not tracked in ZeitFabrik.',
    editLeaveAgreement: 'Editing a leave agreement leads to a recalculation of the leave account for the respective interval.',
    editOvertime: 'The overtime is updated on a weekly basis. You can, in addition, grant or reduce overtime.',
    email: 'Start your account creation with your email.',
    emailConfirmed: 'Thanks for confirming your email address. You can start using ZeitFabrik now!',
    enableGermanWorkingTimeLawChecks: 'Enable automatic monitoring.',
    enableWorkplace: 'You can enable this workplace. Employees will be able to clock time on this workplace. You can always disable the workplace if you change your mind.',
    excludePersons: 'Specify for which persons no monitoring should be performed.',
    exportTimeRecords: 'You can export time records to a CSV file for further use e.g. in third party systems.',
    forgotPassword: 'Enter your email and we will send you a link to reset your password.',
    hoursPerCustomerPerYear: 'Each bar represents the annual distributed time of a customer.',
    hoursPerCustomerInLastThreeMonths: 'Each bar represents the monthly distributed time of the last three month per customer.',
    hoursPerCustomerRelativeShareInLastTwelveMonths: 'Each line represents the monthly relative share of distributed time of the last twelve month per customer.',
    hoursPerOrder: 'Each bar represents the distributed times in the selected aggregation mode per order. The displayed data can be customized with the filters above.',
    importStaff: 'The following staff members were found in the file and checked for their importable state.',
    invitationAccepted: 'Track your time for the organization {organizationName} now.',
    invitationDeclined: 'We have informed the organization that you declined their invitation. Thank you for taking your time. You may close this tab now.',
    inviteUser: 'Invite a person to activate this staff.',
    leaveOrganization: 'Warning: You can not undo this step!',
    deleteOrganization: 'Warning: You can not undo this step! Any time records, invoices, orders etc. associated with this organization will be lost',
    markInvoiceTemplateAsDefault: 'Existing default flags on other invoice templates will be removed!',
    noEmailReceived: 'Just hit resend and we\'ll send it again.',
    organization: 'Enter your organisation and start tracking your time.',
    passOrganizationOwner: 'You can ask another organization member to take ownership of the organization. The person will have to accept the terms and conditions. After taking ownership, the person will be able to delete the organization. You will no longer be able to do so and won\'t be able to regain this power by yourself. You can pass ownership if no request to pass ownership has been made. You can revoke a request to pass ownership if the requestee has not yet accepted.',
    password: 'Choose a unique and secure password. The password requires a minimum length of six characters.',
    provideFile: 'Provide a csv file with a header row containing the column headings "Vorname", "Nachname" and "E-Mail". The columns must be separated by a semicolon (";").',
    removeAdminPrivileges: 'Admin privileges will be removed from this user. The user will not be able to see sensitive data or be able to change the configuration of this organization anymore.',
    reopenOrder: 'A reopened order can be closed again.',
    resetPassword: 'Enter and confirm your new password. You can sign in with it immediately.',
    revokeInvitation: 'When the invitation has been revoked successfully, a user can be invited again',
    revokePassOrganizationOwner: 'You can revoke passing an ownership of the organization to another organization member. After revoking you can ask another organization member to take ownership of the organization again.',
    setWorkingTimes: 'The planned working time for each day of a week.',
    selectOrganizationalUnit: 'Select organizational unit for staff import',
    staffsWithoutDatevCompliantIdentificationNumber: 'Time sheets of staffs without DATEV-compliant identification number cannot be exported to DATEV.',
    workingTimeAgreementDescription: 'Creating a working time agreement enables the automated calculation of overtimes in the respective validity period.',
    workingTimeAgreementFurtherDescription: 'The calculation of overtimes takes place at the end of a week by comparing the recorded times with the respective planned working times.',
  },
  title: {
    assignColumnHeaders: 'Assign column headers',
    email: 'Email',
    pricingTable: 'Price table',
    licenseMissing: 'No valid license',
    absences: common.absences,
    accepting: 'Accepting invitation...',
    acceptInvitation: 'Accept invitation',
    activateAutomaticTimesheetDatexExport: '3. Active automatic timesheet export to DATEV',
    addAdminPrivileges: 'Add administrator privileges',
    answerLeaveRequest: 'Answer leave request',
    answerPassOrganizationOwnershipRequest: 'Accept organisation ownership request',
    answerSickNote: 'Answer sick note',
    assignOrganizationalUnit: 'Assign organizational unit',
    attendanceMonitors: common.attendanceMonitors,
    audits: common.audits,
    cancelTimeRecord: 'Cancel time record',
    changePassword: 'Change password',
    changePin: 'Change pin',
    checkResult: 'Check result',
    chooseStaffRole: 'Choose scope and role',
    clockIn: common.clockIn,
    clockOut: common.clockOut,
    closeOrder: 'Close order',
    comment: common.comment,
    configureGermanWorkingTimeLaw: 'Working Time Law',
    configureOrganization: 'Configure organization',
    confirm: 'Check your inbox!',
    confirming: 'Confirming email...',
    connectZeitFabrikWithDatev: '2. Connect ZeitFabrik with DATEV',
    contactPerson: 'Contact person',
    contract: 'Contract',
    contractDetails: 'Contract details',
    contractOpenEnded: 'open-ended',
    create: common.create,
    createCustomer: 'Create customer',
    createDatevConfiguration: '1. Create DATEV configuration',
    createHoliday: 'Create holiday',
    createInvoice: 'Create invoice',
    createInvoiceLineItem: 'Create invoice line item',
    createInvoiceTemplate: 'Create invoice template',
    createLeave: 'Create leave',
    createLeaveAgreement: 'Create leave agreement',
    createOrder: 'Create order',
    createOrganizationalUnit: 'Create organizational unit',
    createOrganizationName: 'What\'s the name of your organization or team?',
    createOrganizationStaffName: 'Enter your name.',
    createScheduledBreak: 'Schedule break',
    createSickNote: 'Create sick note',
    createStaffAnnualLeave: 'Time for rest',
    createStaffDates: 'Set important dates',
    createStaffDatesStartUsage: 'Start of use of ZeitFabrik',
    createStaffLeaveAccount: 'Transfer leave account to ZeitFabrik',
    createStaffLeaveAccountReceivedLeave: 'Remaining leave from previous years',
    createStaffLeaveAccountTakenLeave: 'Already taken leave',
    createStaffName: 'Let\'s start with the name',
    createStaffSummary: 'Is all data correct?',
    createStaffTimeAccount: 'Transfer overtime to ZeitFabrik',
    createStaffWorkingTimes: 'Enter daily working times',
    createTimeRecord: 'Create time record',
    createTimeSheet: 'Create time sheet',
    createWorkplace: 'Create workplace',
    createWorkingTimeAgreement: 'Create working time agreement',
    customer: common.customer,
    customerAnalysis: common.customerAnalysis,
    customerMissing: 'A customer is missing',
    customers: common.customers,
    dashboard: 'Dashboard',
    date: 'Date',
    datevExport: 'DATEV export',
    datevConfigurationMissing: 'DATEV configuration missing',
    dayOfWeek: 'Day of week',
    daysOfLeave: 'Days of leave',
    deactivateStaff: 'Deactivate staff',
    declining: 'Declining invitation...',
    decrementOvertime: 'Decrement overtime',
    defineAValidity: 'Define a validity period',
    deleteAccount: 'Delete user account',
    deleteAttendanceMonitor: 'Delete attendance monitor',
    deleteCustomer: 'Delete customer',
    deleteDistributedOrderTime: 'Delete time distribution',
    deleteHoliday: 'Delete holiday',
    deleteInvoice: 'Delete invoice',
    deleteInvoiceLineItem: 'Delete invoice line item',
    deleteInvoiceTemplate: 'Delete invoice template',
    deleteLeave: 'Delete leave',
    deleteLeaveAgreement: 'Delete leave agreement',
    deleteOrder: 'Delete order',
    deleteOrganizationalUnit: 'Delete organizational unit',
    deleteSickNote: 'Delete sick note',
    deleteStaff: 'Delete staff',
    deleteTimeRecord: 'Delete time record',
    deleteTimeSheet: 'Delete time sheet',
    deleteWorkingTimeAgreement: 'Delete working time agreement',
    description: common.description,
    deviceName: common.deviceName,
    disableGermanWorkingTimeLawChecks: 'Disable monitoring',
    disableWorkplace: 'Disable workplace',
    distributableTime: 'Distributable time',
    distributeTime: 'Distribute time',
    distributedTimes: common.distributedTimes,
    distributions: common.distributions,
    downloadInvoice: 'Download invoice',
    downloadTimeSheet: 'Download timesheet',
    editAttendanceMonitor: 'Edit attendance monitor',
    editCustomer: 'Edit customer',
    editDatevConfiguration: 'Edit datev configuration',
    editInvoice: 'Edit invoice',
    editInvoiceLineItem: 'Edit invoice line item',
    editInvoiceTemplate: 'Edit invoice template',
    editLeave: 'Edit leave',
    editLeaveAccount: 'Edit leave account',
    editLeaveAgreement: 'Edit leave agreement',
    editOrder: 'Edit order',
    editOrganization: 'Edit organization',
    editOrganizationalUnit: 'Edit organizational unit',
    editOvertime: 'Edit overtime',
    editSickNote: 'Edit sick note',
    editStaff: 'Edit staff',
    editTimeDistribution: 'Edit time distribution',
    editTimeRecord: 'Edit time record',
    editTimeSheet: 'Edit time sheet',
    editWorkingTimeAgreement: 'Edit working time agreement',
    emailConfirmed: 'Welcome to ZeitFabrik.',
    enabled: 'enabled',
    enableGermanWorkingTimeLawChecks: 'Enable monitoring',
    enableWorkplace: 'Enable workplace',
    end: common.end,
    essentials: 'Essentials',
    excludePersons: 'Exclude persons',
    exemptFromAudit: 'Exempt from audit',
    exportTimeRecords: 'Export time records',
    forgotPassword: common.forgotPassword,
    hoursPerCustomerPerYear: 'Hours per customer per year',
    hoursPerCustomerInLastThreeMonths: 'Hours per customer per month',
    hoursPerCustomerRelativeShareInLastTwelveMonths: 'Relative share of distributed time per customer per month',
    hoursPerOrder: 'Hours per Order',
    importStaff: 'Import staff',
    incrementOvertime: 'Increment overtime',
    invitationAccepted: 'Invitation accepted.',
    invitationDeclined: 'Invitation declined.',
    invitationError: 'Invitation error',
    invitationExpired: 'Invitation expired',
    invitationTo: 'Invitation to',
    inviteUser: 'Invite user',
    invoicableTime: common.invoicableTime,
    invoice: common.invoice,
    invoiceDate: 'Invoice date',
    invoiceOrdersMissing: 'Orders are missing',
    invoicedTime: common.invoicedTime,
    invoiceLineItems: common.invoiceLineItems,
    invoiceOrders: 'Invoice orders',
    invoiceNumber: common.invoiceNumber,
    invoices: common.invoices,
    invoiceTemplates: common.invoiceTemplates,
    lawViolation: 'Law violation audit',
    leave: common.leave,
    leaveAccount: common.leaveAccount,
    leaveAgreementMissing: 'Unlock your leave account',
    leaveAgreements: common.leaveAgreements,
    leaveAndTimeAccounts: common.leaveAndTimeAccounts,
    leaveOrganization: 'Leave organization',
    deleteOrganization: 'Delete organization',
    leaves: common.leaves,
    markInvoiceTemplateAsDefault: 'Mark invoice template as default',
    member: 'Member',
    members: common.members,
    name: common.name,
    noEmailReceived: 'No email received?',
    noInternetConnection: 'No internet connection.',
    notAnAdmin: 'No admin rights',
    number: common.number,
    optional: 'Optional',
    order: common.order,
    orderAnalysis: common.orderAnalysis,
    orders: common.orders,
    orderStopWatch: 'Order stop watch',
    orderMissing: 'An order is missing',
    organization: common.organization,
    organizationalUnit: common.organizationalUnit,
    organizationalUnits: common.organizationalUnits,
    organizations: common.organizations,
    overtime: common.overtime,
    overtimeAccount: 'Overtime account',
    overtimeRecords: 'Overtime records',
    passOrganizationOwner: 'Pass organization ownership',
    password: 'Security matters.',
    period: 'Period',
    professional: 'Professional',
    provideFile: 'Upload file',
    publicHolidays: common.publicHolidays,
    receivedLeave: 'Leave carryover',
    recipient: 'Recipient',
    recordedTimes: common.recordedTimes,
    recordings: common.recordings,
    registerAttendanceMonitor: 'Register attendance monitor',
    registrationHelp: 'Need help?',
    remainingLeave: 'Remaining leave',
    removeAdminPrivileges: 'Remove administrator privileges',
    reopenOrder: 'Reopen order',
    requestLeave: common.requestLeave,
    resetPassword: 'Reset password',
    revokeInvitation: 'Revoke invitation',
    revokePassOrganizationOwner: 'Revoke passing organization ownership',
    role: common.role,
    scheduledBreaks: 'Scheduled breaks',
    security: common.security,
    selectOrganizationalUnit: 'Select organizational unit',
    setWorkingTimes: 'Set working times',
    sickNote: common.sickNote,
    sickNotes: common.sickNotes,
    signIn: common.signIn,
    slogan: 'Simply.More.Time',
    staff: common.staff,
    staffsWithoutDatevCompliantIdentificationNumber: 'Staffs without DATEV-compliant identification number',
    start: common.start,
    status: common.status,
    submitSickNote: common.submitSickNote,
    takenLeave: common.takenLeave,
    targetLeave: common.targetLeave,
    tasks: common.tasks,
    time: common.time,
    timeAccount: common.timeAccount,
    timeClock: 'Time clock',
    timeRecord: common.timeRecord,
    timeSheet: 'Time sheet',
    toDos: common.toDos,
    trustBasedFlexTime: 'Trust based flextime',
    upcomingHolidays: common.upcomingHolidays,
    userAccount: 'User account',
    workplaces: 'Workplaces',
    workingTime: common.workingTime,
    workingTimeAgreementMissing: 'Unlock your time account',
    workingTimes: 'Working times',
    workingTimeAgreements: common.workingTimeAgreements,
    workingTimeLawChecks: 'Checks',
    yourOrganizations: 'Your organizations',
    zfUsageStart: 'Start of use of ZeitFabrik',
  },
  backend: {
    datev: {
      error: {
        invalidDatevRequest: 'Communication failure with DATEV'
      }
    },
    networkError: 'ZeitFabrik is not reachable.',
    illegalAccess: 'Access denied.',
    attendanceMonitor: {
      error: {
        invalidPin: 'You have entered an invalid PIN.',
        deviceNameIsTaken: 'Device name already in use.',
        maximumExceeded: 'You have exceeded the allowed maximum of attendance monitors. Contact support if you wish to raise the maximum.'
      }
    },
    invitation: {
      error: {
        userIsAlreadyAMember: 'You are already a member of this organization.',
        expired: 'Invitation is expired.'
      }
    },
    scheduledBreak: {
      error: {
        intersecting: 'The scheduled break is intersecting with an existing scheduled break.'
      }
    },
    timeInterval: {
      error: {
        endIsBeforeOrEqualStart: 'The end of the interval is before or equal to the start.',
        endIsBeforeStart: 'End is before start.',
        tooLong: 'The interval would exceed the maximum length.',
        intervalIsIntersectingAnotherInterval: 'The entry is intersecting with an existing interval.'
      }
    },
    timeRecord: {
      error: {
        recordAtTimeAlreadyPresent: 'A record at \'{time}\' already exists.',
        recordIsIntersectingAnInterval: 'The entry is intersecting with an existing interval.',
        recordWithSameTypeAdjacent: 'An adjacent record has the same type.',
        invalidFirstEntry: 'The first entry must not be an clock out entry.'
      }
    },
    timeAccount: {
      error: {
        durationNegativeOrZero: 'The entered value must be greater than zero.',
      }
    },
    distributedOrderTime: {
      error: {
        orderTimeIsZero: 'Time must be greater than zero.'
      }
    },
    leave: {
      error: {
        endIsBeforeStart: 'End is before start.',
        leaveAlreadyPresent: 'Given interval intersects with an already present leave.'
      }
    },
    sickNote: {
      error: {
        endIsBeforeStart: 'End is before start.',
        sickNoteAlreadyPresent: 'Given interval intersects with an already present sick note.'
      }
    },
    staffImportValidation: {
      error: {
        fileIsEmpty: 'The given file is empty',
        tooFewColumns: 'The file has too few columns',
      }
    },
    publicHoliday: {
      'Good Friday': 'Good Friday',
      'Epiphany': 'Epiphany',
      'Womens Day': 'Women\'s Day',
      'Corpus Christi': 'Corpus Christi',
      'Peace Festival': 'Peace Festival',
      'Assumption Day': 'Assumption Day',
      'All Saints': 'All Saints',
      'Repentance and Prayer Day': 'Repentance and Prayer Day',
      'Easter Monday': 'Easter Monday',
      'Labor Day': 'Labor Day',
      'Ascension Day': 'Ascension Day',
      'Whit Monday': 'Whit Monday',
      'German Unity Day': 'German Unity Day',
      'Reformation Day': 'Reformation Day',
      'Christmas Eve': 'Christmas Eve',
      'Christmas Day': 'Christmas Day',
      'Boxing Day': 'Boxing Day',
      'New Years Eve': 'New Years Eve',
      'New Years Day': 'New Years Day'
    },
    staff: {
      error: {
        staffWithEmailAlreadyExists: 'A staff with this email already exists in this organization.',
        onlyInvitationForInvitedStaffCanBeAccepted: 'Invitation is expired.',
        invitationIdDoesNotMatch: 'Invitation is expired.',
        staffForMeAlreadyExists: 'You\'re already a staff in this organisation.'
      }
    },
    workingTimeAgreement: {
      error: {
        workingTimeAgreementAlreadyPresent: 'Given interval intersects with an already present working time agreement.',
        leaveAlreadyPresent: 'IThere is already leave accepted in the given interval. Please delete leave first.',
        workingTimesImmutableDueToOvertimeCompensation: 'Working times are immutable due to accepted overtime compensations within the working time intervals.'
      }
    },
    leaveAgreement: {
      error: {
        leaveAgreementAlreadyPresent: 'Given interval intersects with an already present leave agreement.',
      }
    },
    organization: {
      error: {
        userIsNotAnAdmin: 'User is not an admin.',
        userIsNotAnAdminOrOwner: 'User is not an admin or an owner.'
      }
    },
    organizationalUnit: {
      error: {
        nameIsTaken: 'Name is already in use.',
        nameIsBlank: 'Name must not be blank.'
      }
    },
    device: {
      error: {
        deviceNameIsTaken: 'Device name already in use.',
        descriptionIsTaken: 'Description is already in use.'
      }
    },
    order: {
      error: {
        canNotDeleteOrderWithSpentTime: 'The order is not deletable due to spent time.',
        commentRequired: 'A comment is required.'
      }
    },
    customer: {
      error: {
        customerAlreadyPresent: 'The name is already taken.',
        canNotDeleteCustomerWithOrders: 'The customer is not deletable due to existing orders.',
        canNotDeleteCustomerWithInvoices: 'The customer is not deletable due to existing invoices.',
      }
    },
    invoicing: {
      creation: {
        error: {
          invoiceNumberAlreadyExists: 'This invoice number is already taken!'
        }
      }
    },
    invoice: {
      error: {
        differentContactPersonsInInvoice: 'Selected orders belong to different contact persons.',
        badFormat: 'The invoice number must be in this pattern YYYY/XX.',
        invoiceNumberInUse: 'Invoice number already in use.',
        invoiceNumberIsBlank: 'Invoice number is empty.',
      }
    },
    invoiceTemplate: {
      error: {
        invoiceTemplateNameInUse: 'The name is already taken.'
      }
    },
    user: {
      error: {
        hasNoPin: 'The user has not pin. Set a pin in the user menu in order to use this feature.',
        tokenResetLinkExpired: 'The link is expired.',
        passwordInvalid: 'Invalid password',
        emailAddressInUse: 'Email is already in use.',
        illegalAccess: 'Illegal access.',
        registrationExpired: 'Registration is expired.',
        alreadyConfirmed: 'Email already confirmed.',
        isInOrganization: 'You\'re still part of an organization.'
      }
    },
    timesheet: {
      error: {
        nameNotUnique: 'Name is already in use.',
        endBeforeStart: 'Start date must be before end date.',
        cannotRetrieveJobStatus: 'Cannot retrieve file status.',
        invalidJobStatus: 'Unknown file status.',
        timeSheetContainsDayWithoutWorkingTimeAgreement: 'Any day of the month must be covered by a working time agreement.'
      },
      enum: {
        UPLOADED: 'Uploaded',
        IMPORTED: 'Imported',
        CORRUPTED: 'Corrupted',
        DELETED: 'Deleted',
        AUTO_DELETED: 'Automatically deleted',
      }
    },
    timeSheetExport: {
      error: {
        datevConfigurationMissing: 'Please add a datev configuration first.',
        staffIdentificationNumberInvalid: 'Please use a numeric staff identification number with a maximum of 5 characters.',
      }
    },
    datevAuthorization: {
      error: {
        invalidToken: 'The received token is invalid. Please try again later.',
        alreadyExists: 'This organization is already authorized.',
        invalidDatevRequest: 'DATEV is currently not available. Please try again later.',
      }
    },
    datevAuthorizationRequest: {
      error: {
        invalidToken: 'The received token is invalid. Please try again later.',
        notFound: 'Invalid session. Please start a new authorization request.',
        userDoesNotMatch: 'Invalid session. Please start a new authorization request.',
        invalidDatevRequest: 'DATEV is currently not available. Please try again later.',
        accessDenied: 'Access denied.',
      }
    },
    taskTypes: {
      'timeRecording': 'Time recording',
      'supervisorsClockTimeForThemselves': 'Supervisors clock for themselves',
      'employeesClockTimeForThemselves': 'Employees clock for themselves',
      'employeesManageTimeIntervalsForThemselves': 'Employees manage time records for themselves',
      'employeesSubmitTimeSheetsForThemselves': 'Employees submit time sheets for themselves',
      'supervisorsManageTimeIntervalsForThemselves': 'Supervisors manage time records for themselves',
      'supervisorsManageTimeIntervalsForEmployees': 'Supervisors manage time records for employees',
      'supervisorsViewTimeAccountForEmployees': 'Supervisors view time account for employees',
      'supervisorsViewTimeAccountForThemselves': 'Supervisors view time account for themselves',
      'employeesViewTimeAccountForThemselves': 'Employees view time account for themselves',
      'leaveAndSickNote': 'Leave and sick note',
      'supervisorsManageLeaveForEmployees': 'Supervisors manage leave for employees',
      'supervisorsManageLeaveForThemselves': 'Supervisors manage leave for themselves',
      'employeesRequestLeaveForThemselves': 'Employees request leave for themselves',
      'supervisorsManageSickNoteForEmployees': 'Supervisors manage sick notes for employees',
      'supervisorsManageSickNoteForThemselves': 'Supervisors manage sick notes for themselves',
      'supervisorsManageTimeSheetsForThemselves': 'Supervisors manage time sheets for themselves',
      'supervisorsManageTimeSheetsForEmployees': 'Supervisors manage time sheets for employees',
      'employeesSubmitSickNoteForThemselves': 'Employees submit sick notes for themselves',
      'timeDistribution': 'Time distribution',
      'employeesManageDistributedTimesForThemselves': 'Employees manage time distributions for themselves',
      'supervisorsManageDistributedTimesForThemselves': 'Supervisors manage time distributions for themselves',
      'supervisorsManageDistributedTimesForEmployees': 'Supervisors manage time distributions for employees',
      'customerAndInvoice': 'Customer and invoice',
      'supervisorsManageCustomers': 'Supervisors manage customers',
      'employeesManageCustomers': 'Employees manage customers',
      'supervisorsManageInvoices': 'Supervisors manage invoices',
      'employeesManageInvoices': 'Employees manage invoices',
      'organization': 'Organization',
      'supervisorsManageAttendanceMonitors': 'Supervisors manage attendance monitors',
      'employeesManageAttendanceMonitors': 'Employees manage attendance monitors',
      'report': 'Reports',
      'supervisorsEvaluateWorkingTime': 'Supervisors evaluate working time',
      'employeesEvaluateWorkingTime': 'Employees evaluate working time',
      'supervisorsEvaluateAbsences': 'Supervisors evaluate absences',
      'employeesEvaluateAbsences': 'Employees evaluate absences',
      'employeesViewLeaveAndTimeAccounts': 'Employees view balance of leave and time accounts',
      'supervisorsViewLeaveAndTimeAccounts': 'Supervisors view balance of leave and time accounts',
      'analysis': 'Analysis',
      'supervisorsAnalyzeOrders': 'Supervisors analyze orders',
      'employeesAnalyzeOrders': 'Employees analyze orders',
      'supervisorsAnalyzeCustomers': 'Supervisors analyze customers',
      'employeesAnalyzeCustomers': 'Employees analyze customers',
      'supervisorsViewWorkingTimeLawViolationsForThemselves': 'Supervisors view working time law violations for themselves',
      'supervisorsViewWorkingTimeLawViolationsForEmployees': 'Supervisors view working time law violations for employees',
      'employeesViewWorkingTimeLawViolationsForThemselves': 'Employees view working time law violations for themselves',
      'supervisorsViewScheduledBreaksForThemselves': 'Supervisors view scheduled breaks for themselves',
      'supervisorsViewScheduledBreaksForEmployees': 'Supervisors view scheduled breaks for employees',
      'employeesViewScheduledBreaksForThemselves': 'Employees view scheduled breaks for themselves',
    },
    workplace: {
      error: {
        nameTaken: 'This name is already taken'
      }
    }
  },
  organization: {
    error: {
      canNotDelete: 'Could not delete organization',
      requestAlreadyPending: 'There is already a pending request.',
      requesterIsNotOwner: 'The requester is not the owner of the organization.',
      accepterIsNotNewOwner: 'The accepter is not the new owner of the organization.'
    }
  },
  payment: {
    success: 'The account creation was successful.'
  },
  overtimeRecord: {
    type: {
      INITIAL: 'Initial record',
      WORKING_WEEK: 'Working week accounting',
      ADJUSTMENT_MANUAL: 'Manual adjustment',
      ADJUSTMENT_SYSTEM: 'Adjustment by system',
      OVERTIME_COMPENSATION_LEAVE: 'Overtime compensation leave'
    }
  },
  staffImport: {
    importableState: {
      IMPORTABLE: 'Importable',
      FIRST_NAME_MISSING: 'First name is missing.',
      LAST_NAME_MISSING: 'Last name is missing',
      EMAIL_DUPLICATE_IN_FILE: 'Email duplicate in import file',
      EMAIL_INVALID: 'Email invalid',
      EMAIL_ALREADY_REGISTERED: 'Email already registered',
    }
  },
  timeSheet: {
    status:{
      CREATED: 'created',
      SUBMITTED: 'submitted',
      ACCEPTED: 'accepted',
      REJECTED: 'declined'
    },
    entry: {
      comment: {
        REGULAR_LEAVE: 'Leave of absence',
        OVERTIME_COMPENSATION_LEAVE: 'Overtime compensation leave',
        SPECIAL_LEAVE: 'Special leave',
        SICK_DAY: 'Sick day',
        HOLIDAY: 'Public holiday'
      }
    }
  },
}
