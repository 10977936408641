import { paginated } from '../helpers/paginated'

const initialState = {
  page: {
    customerId: null,
    status: 'OPEN',
    limit: 30,
    offset: 0
  }
}

const orderModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  }
}

export default orderModule
export { initialState }
