import { paginated } from '../helpers/paginated'

const initialState = {
  page: {
    limit: 30,
    offset: 0
  }
}

const customerModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  },
}

export default customerModule
export { initialState }
