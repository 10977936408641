<template>
  <div class="p-4 max-w-screen-xl min-h-screen mx-auto flex flex-col space-y-8">
    <nav class="flex justify-between items-center">
      <img
        src="../../public/zeitfabrik-logo-landscape.svg"
        alt="ZeitFabrik Logo"
        class="max-h-12"
      >
      <slot name="right" />
    </nav>
    <main class="flex-1">
      <slot />
    </main>
    <the-footer class="w-fit text-center p-4 mx-auto" />
  </div>
</template>

<script>
  import TheFooter from '@/components/the-footer.vue'

  export default {
    name: 'FullSizePageLayout',
    components: {
      TheFooter,
    }
  }
</script>
