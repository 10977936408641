import Vue from 'vue'
import VueRouter from 'vue-router'
import { organizationManagementService, userManagementService } from '../../api'
import AttendanceMonitorDashboard from '@/pages/attendance-monitor-dashboard.vue'
import AttendanceMonitorAdminLogin from '@/pages/attendance-monitor-admin-login.vue'
import ConfirmEmail from '@/pages/email-confirm.vue'
import ResetPassword from '@/pages/password-reset.vue'
import NetworkError from '@/pages/network-error.vue'
import BREADCRUMBS from '../../mixins/breadcrumbs/BREADCRUMBS'

Vue.use(VueRouter)

function checkAuthentication(to, from, next) {
  if (!userManagementService.isAuthenticated()) {
    return next({ name: 'login', replace: true })
  }

  if (!userManagementService.isEmailConfirmed()) {
    return next({ name: 'email-unconfirmed' })
  }

  next()
}

function redirectToDashboardIfAuthenticated() {
  return (to, from, next) => {
    if (userManagementService.isAuthenticated()) {

      organizationManagementService.getOrganizationPage({ input: { limit: 1, offset: 0 } }).then((result) => {
        if(result.data.organizationPage.totalCount === 1) {
          next({ name: 'dashboard', params: { organizationId: result.data.organizationPage.organization[0].id }, replace: true })
        } else {
          next({ name: 'user', replace: true })
        }
      })
    } else {
      next()
    }
  }
}

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '',
      redirect: {
        path: '/login'
      }
    },
    {
      path: '/attendance-monitor-admin-login',
      name: 'attendance-monitor-admin-login',
      component: AttendanceMonitorAdminLogin
    }, {
      path: '/attendance-monitor-dashboard',
      name: 'attendance-monitor-dashboard',
      component: AttendanceMonitorDashboard
    },
    {
      path: '/email-confirmation',
      name: 'email-confirmation',
      component: ConfirmEmail
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../../pages/login.vue'),
      beforeEnter: redirectToDashboardIfAuthenticated()
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../../pages/register.vue'),
      beforeEnter: redirectToDashboardIfAuthenticated()
    },
    {
      path: '/answer-invitation',
      name: 'answer-invitation',
      component: () => import('../../pages/invitation-answer.vue')
    },
    {
      path: '/network-error',
      name: 'network-error',
      component: NetworkError
    },
    {
      path: '/email-unconfirmed',
      name: 'email-unconfirmed',
      component: () => import('../../pages/email-unconfirmed.vue')
    },
    {
      path: '/user',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */'../../pages/user.vue'),
      beforeEnter: checkAuthentication,
      children: [
        {
          path: 'account',
          name: 'account',
          component: () => import(/* webpackChunkName: "user" */'../../pages/user/account.vue'),
        },
        {
          path: 'organization',
          name: 'organization-list',
          component: () => import(/* webpackChunkName: "user" */'../../pages/user/organization-list.vue'),
        },
        {
          path: 'organization/create',
          name: 'create-organization',
          component: () => import(/* webpackChunkName: "user" */'../../pages/user/organization-create.vue'),
        },
        {
          path: 'organization/:organizationId',
          name: 'organization',
          component: () => import(/* webpackChunkName: "user" */'../../pages/user/organization.vue'),
        },
        {
          path: 'logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "user" */'../../pages/user/logout.vue'),
        },
      ]
    },
    {
      path: '/datev/authorization-confirmation',
      name: 'datev-authorization-confirmation',
      component: () => import(/* webpackChunkName: "datev" */'../../pages/zeitfabrik/datev/datev-authorization-confirmation.vue')
    },
    {
      path: '/:organizationId',
      name: 'zeitfabrik',
      component: () => import(/* webpackChunkName: "zeitfabrik" */'../../pages/zeitfabrik.vue'),
      beforeEnter: checkAuthentication,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */'../../pages/zeitfabrik/dashboard/dashboard.vue'),
        },
        {
          path: 'recording',
          name: 'recording',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timerecord/time-record-list.vue'),
          meta: {
            breadcrumbs: [BREADCRUMBS.HOME, BREADCRUMBS.TIME_RECORDING_LIST]
          }
        },
        {
          path: 'recording/create',
          name: 'create-time-interval',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/timerecord/time-record-create.vue'),
          meta: {
            breadcrumbs: [BREADCRUMBS.HOME, BREADCRUMBS.TIME_RECORDING_LIST, BREADCRUMBS.TIME_RECORDING_CREATE]
          }
        },
        {
          path: 'recording/:id',
          name: 'time-interval',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timerecord/time-record.vue'),
        },
        {
          path: 'time-sheet-list',
          name: 'time-sheet-list',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timesheet/time-sheet-list.vue'),
        },
        {
          path: 'scheduled-break-list',
          name: 'scheduled-break-list',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/scheduledbreaks/scheduled-break-list.vue'),
        },
        {
          path: 'time-sheet/create',
          name: 'create-time-sheet',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timesheet/time-sheet-create.vue'),
        },
        {
          path: 'time-sheet/:timeSheetId',
          name: 'time-sheet',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timesheet/time-sheet.vue'),
        },
        {
          path: 'time-sheet/:timeSheetId/edit',
          name: 'edit-time-sheet',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/timesheet/time-sheet-edit.vue'),
        },
        {
          path: 'working-time-law-violations',
          name: 'working-time-law-violations',
          component: () => import(/* webpackChunkName: "recording" */'../../pages/zeitfabrik/workingtimelaw/working-time-law-violations.vue'),
        },
        {
          path: 'distributable-day',
          name: 'time-distribution',
          component: () => import(/* webpackChunkName: "time-distribution" */'../../pages/zeitfabrik/timedistribution/time-distribution-distributable-day-list'),
        },
        {
          path: 'distributable-day/:id',
          component: () => import(/* webpackChunkName: "time-distribution" */'../../pages/zeitfabrik/timedistribution/time-distribution-distributable-day'),
          children: [
            {
              path: '',
              name: 'distributable-day',
              component: () => import(/* webpackChunkName: "time-distribution" */'../../pages/zeitfabrik/timedistribution/time-distribution-distributed-order-time-list'),
            },
            {
              path: 'distribute',
              name: 'distribute-time',
              component: () => import(/* webpackChunkName: "time-distribution" */'../../pages/zeitfabrik/timedistribution/time-distribution-distributed-order-time-create'),
            },
            {
              path: 'distributed-order-time/:distributedOrderTimeId',
              name: 'distributed-order-time',
              component: () => import(/* webpackChunkName: "time-distribution" */'../../pages/zeitfabrik/timedistribution/time-distribution-distributed-order-time'),
            },
          ]
        },
        {
          path: 'leave',
          name: 'leave-management',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/leave/leave-list'),
        },
        {
          path: 'leave/create',
          name: 'create-leave',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/leave/leave-create.vue'),
        },
        {
          path: 'leave/request',
          name: 'request-leave',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/leave/leave-request.vue'),
        },
        {
          path: 'leave/:leaveId',
          name: 'leave',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/leave/leave.vue'),
        },
        {
          path: 'sick-note',
          name: 'sick-note-management',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/sicknote/sick-note-list.vue'),
        },
        {
          path: 'sick-note/create',
          name: 'create-sick-note',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/sicknote/sick-note-create.vue'),
        },
        {
          path: 'sick-note/submit',
          name: 'submit-sick-note',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/sicknote/sick-note-submit.vue'),
        },
        {
          path: 'sick-note/:sickNoteId',
          name: 'sick-note',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/sicknote/sick-note.vue'),
        },
        {
          path: 'upcoming-holiday',
          name: 'upcoming-holidays',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/holiday/holiday-list.vue'),
        },
        {
          path: 'upcoming-holiday/create',
          name: 'create-holiday',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/holiday/holiday-create.vue'),
        },
        {
          path: 'upcoming-holiday/:id',
          name: 'holiday',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/holiday/holiday.vue'),
        },
        {
          path: 'public-holiday',
          name: 'public-holidays',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/publicholiday/public-holiday-list.vue'),
        },
        {
          path: 'staff',
          name: 'staff-list',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-list.vue'),
        },
        {
          path: 'staff/create',
          name: 'staff-create',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-create.vue'),
        },
        {
          path: 'staff/import',
          name: 'staff-import',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-import.vue'),
        },
        {
          path: 'staff/:staffId',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff.vue'),
          children: [
            {
              path: '',
              name: 'staff',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-details.vue'),
            },
            {
              path: 'time-account',
              name: 'staff-time-account',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-account.vue')
            },
            {
              path: 'time-sheet',
              name: 'staff-time-sheet-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-sheet-list.vue')
            },
            {
              path: 'time-sheet-create',
              name: 'staff-time-sheet-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-sheet-create.vue')
            },
            {
              path: 'time-sheet/:timeSheetId/edit',
              name: 'staff-time-sheet-edit',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-sheet-edit.vue')
            },
            {
              path: 'time-account/:workingWeekId',
              name: 'staff-time-account-working-week',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-account-working-week.vue')
            },
            {
              path: 'overtime',
              name: 'staff-overtime',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-overtime.vue')
            },
            {
              path: 'leave-account',
              name: 'staff-leave-account',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-account.vue')
            },
            {
              path: 'working-time-agreement',
              name: 'staff-working-time-agreement-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-working-time-agreement-list.vue')
            },
            {
              path: 'working-time-agreement/create',
              name: 'staff-working-time-agreement-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-working-time-agreement-create.vue')
            },
            {
              path: 'working-time-agreement/:workingTimeAgreementId',
              name: 'staff-working-time-agreement',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-working-time-agreement.vue')
            },
            {
              path: 'leave-agreement',
              name: 'staff-leave-agreement-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-agreement-list.vue')
            },
            {
              path: 'leave-agreement/create',
              name: 'staff-leave-agreement-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-agreement-create.vue')
            },
            {
              path: 'leave-agreement/:leaveAgreementId',
              name: 'staff-leave-agreement',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-agreement.vue')
            },
            {
              path: 'time-record',
              name: 'staff-time-record-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-record-list')
            },
            {
              path: 'time-record/create',
              name: 'staff-time-record-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-record-create')
            },
            {
              path: 'time-record/:id',
              name: 'staff-time-record',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-record-details')
            },
            {
              path: 'leave',
              name: 'staff-leave-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-list.vue')
            },
            {
              path: 'leave/create',
              name: 'staff-leave-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave-create')
            },
            {
              path: 'leave/:leaveId',
              name: 'staff-leave',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-leave')
            },
            {
              path: 'time-sheet/:timeSheetId',
              name: 'staff-time-sheet',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-sheet')
            },
            {
              path: 'scheduled-breaks',
              name: 'staff-scheduled-breaks',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-scheduled-breaks.vue')
            },
            {
              path: 'scheduled-breaks/create',
              name: 'staff-scheduled-breaks-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-scheduled-breaks-create.vue')
            },
            {
              path: 'sick-note',
              name: 'staff-sick-note-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-sick-note-list.vue')
            },
            {
              path: 'sick-note/create',
              name: 'staff-sick-note-create',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-sick-note-create')
            },
            {
              path: 'sick-note/:sickNoteId',
              name: 'staff-sick-note',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-sick-note')
            },
            {
              path: 'working-time-law-violation',
              name: 'staff-working-time-law-violation-list',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-working-time-law-violation-list.vue')
            },
            {
              path: 'distributable-day',
              name: 'staff-time-distribution-distributable-day',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-distribution-distributable-day-list')
            },
            {
              path: 'distributable-day/:id',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-distribution-distributable-day'),
              children: [
                {
                  path: '',
                  name: 'staff-time-distribution-distributed-order-time-list',
                  component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-distribution-distributed-order-time-list'),
                },
                {
                  path: 'distribute',
                  name: 'staff-time-distribution-distributed-order-time-create',
                  component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-distribution-distributed-order-time-create'),
                },
                {
                  path: 'staff-time-distribution-distributed-order-time/:distributedOrderTimeId',
                  name: 'staff-time-distribution-distributed-order-time',
                  component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/staff/staff-time-distribution-distributed-order-time'),
                },
              ]
            },
          ]
        },
        {
          path: 'organizational-unit',
          name: 'organizational-unit-management',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit-list.vue'),
        },
        {
          path: 'organizational-unit/create',
          name: 'create-organizational-unit',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit-create.vue'),
        },
        {
          path: 'organizational-unit/:id',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit.vue'),
          children: [
            {
              path: '',
              name: 'organizational-unit',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit-details.vue'),
            },
            {
              path: 'member',
              name: 'organizational-unit-member',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit-members.vue'),
            },
            {
              path: 'task',
              name: 'organizational-unit-task',
              component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/organizationalunit/organizational-unit-tasks.vue'),
            },
          ]
        }, {
          path: 'workplace',
          name: 'workplace-management',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/workplace/workplace-list.vue'),
        },
        {
          path: 'workplace/create',
          name: 'workplace-create',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/workplace/workplace-create.vue'),
        },
        {
          path: 'workplace/:id',
          name: 'workplace',
          component: () => import(/* webpackChunkName: "master-data" */'../../pages/zeitfabrik/workplace/workplace.vue'),
        },
        {
          path: 'attendance-monitor',
          name: 'attendance-monitor-management',
          component: () => import(/* webpackChunkName: "device" */'../../pages/zeitfabrik/attendancemonitor/attendance-monitor-list.vue'),
        },
        {
          path: 'attendance-monitor/:deviceName',
          name: 'attendance-monitor',
          component: () => import(/* webpackChunkName: "device" */'../../pages/zeitfabrik/attendancemonitor/attendance-monitor.vue'),
        },
        {
          path: 'customer',
          name: 'customer-management',
          component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-list.vue'),
        },
        {
          path: 'customer/create',
          name: 'create-customer',
          component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-create.vue'),
        },
        {
          path: 'customer/:customerId',
          component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer.vue'),
          children: [
            {
              path: '',
              name: 'customer',
              component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-details.vue'),
            },
            {
              path: 'order',
              name: 'order-management',
              component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-order-list.vue'),
            },
            {
              path: 'order/create',
              name: 'create-order',
              component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-order-create.vue'),
            },
            {
              path: 'order/:orderId',
              name: 'order',
              component: () => import(/* webpackChunkName: "project" */'../../pages/zeitfabrik/customer/customer-order.vue'),
            },
          ]
        },
        {
          path: 'invoice',
          name: 'invoices',
          component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-list.vue'),
        },
        {
          path: 'invoice/create',
          name: 'invoicing',
          component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-create.vue'),
        },
        {
          path: 'invoice/:invoiceId',
          component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice.vue'),
          children: [
            {
              path: '',
              name: 'invoice',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-details.vue'),
            },
            {
              path: 'line-item',
              name: 'invoice-line-item-list',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-line-item-list.vue'),
            },
            {
              path: 'line-item/invoice',
              name: 'invoice-line-item-invoice-orders',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-line-item-orders-invoice.vue'),
            },
            {
              path: 'line-item/create',
              name: 'invoice-line-item-create',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-line-item-create.vue'),
            },
            {
              path: 'line-item/:invoiceLineItemId',
              name: 'invoice-line-item',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-line-item.vue'),
            },
            {
              path: 'download',
              name: 'invoice-download',
              component: () => import(/* webpackChunkName: "invoice" */'../../pages/zeitfabrik/invoice/invoice-download.vue'),
            }
          ]
        },
        {
          path: 'invoice-template',
          name: 'invoice-template-list',
          component: () => import(/* webpackChunkName: "invoice-template" */'../../pages/zeitfabrik/invoicetemplate/invoice-template-list.vue'),
        },
        {
          path: 'invoice-template/create',
          name: 'invoice-template-create',
          component: () => import(/* webpackChunkName: "invoice-template" */'../../pages/zeitfabrik/invoicetemplate/invoice-template-create.vue')
        },
        {
          path: 'invoice-template/:invoiceTemplateId',
          name: 'invoice-template',
          component: () => import(/* webpackChunkName: "invoice-template" */'../../pages/zeitfabrik/invoicetemplate/invoice-template.vue')
        },
        {
          path: 'working-time',
          name: 'working-time',
          component: () => import(/* webpackChunkName: "working-time" */'../../pages/zeitfabrik/workingtime/working-time-list.vue'),
        },
        {
          path: 'absence',
          name: 'absence-overview',
          component: () => import(/* webpackChunkName: "absence" */'../../pages/zeitfabrik/absence/absence-list.vue'),
        },
        {
          path: 'to-do',
          name: 'to-do-list',
          component: () => import(/* webpackChunkName: "to-do" */'../../pages/zeitfabrik/todo/to-do-list.vue'),
        },
        {
          path: 'order-analysis',
          name: 'order-analysis',
          component: () => import(/* webpackChunkName: "order-analysis" */'../../pages/zeitfabrik/analysis/order-analysis.vue')
        },
        {
          path: 'customer-analysis',
          name: 'customer-analysis',
          component: () => import(/* webpackChunkName: "customer-analysis" */'../../pages/zeitfabrik/analysis/customer-analysis.vue')
        },
        {
          path: 'time-account',
          name: 'time-account',
          component: () => import(/* webpackChunkName: "time-account" */'../../pages/zeitfabrik/timeaccount/time-account.vue')
        },
        {
          path: 'time-account/working-week/:workingWeekId',
          name: 'time-account-working-week',
          component: () => import(/* webpackChunkName: "time-account-working-week" */'../../pages/zeitfabrik/timeaccount/time-account-working-week.vue')
        },
        {
          path: 'leave-and-time-accounts',
          name: 'leave-and-time-accounts',
          component: () => import(/* webpackChunkName: "leave-and-time-accounts" */'../../pages/zeitfabrik/leaveandtimeaccounts/leave-and-time-accounts.vue')
        },
        {
          path: 'audit',
          name: 'audit',
          component: () => import(/* webpackChunkName: "leave-and-time-accounts" */'../../pages/zeitfabrik/audit/audit.vue')
        },
        {
          path: 'working-time-law',
          name: 'working-time-law',
          component: () => import(/* webpackChunkName: "working-time-law.vue" */'../../pages/zeitfabrik/workingtimelaw/working-time-law.vue')
        },
        {
          path: 'time-interval-export',
          name: 'time-interval-export',
          component: () => import(/* webpackChunkName: "datev" */'../../pages/zeitfabrik/export/time-interval-export.vue')
        },
        {
          path: 'datev',
          name: 'datev',
          component: () => import(/* webpackChunkName: "datev" */'../../pages/zeitfabrik/datev/datev.vue')
        },
        {
          path: 'datev-configuration/create',
          name: 'create-datev-configuration',
          component: () => import(/* webpackChunkName: "datev-configuration-create" */'../../pages/zeitfabrik/datev/datev-configuration-create.vue')
        },
        {
          path: 'datev-configuration/edit',
          name: 'edit-datev-configuration',
          component: () => import(/* webpackChunkName: "datev-configuration-edit" */'../../pages/zeitfabrik/datev/datev-configuration-edit.vue')
        },
      ]
    },
    {
      path: '*',
      redirect: {
        name: 'login'
      }
    }
  ],
  linkActiveClass: 'is-active'
})
