import { paginated } from '../helpers'
import { DateTime, Duration } from 'luxon'

const initialState = {
  page: {
    organizationalUnitId: null,
    range: {
      start: DateTime.now().toFormat('YYYY-MM-DD'),
      endInclusive: DateTime.now().plus(Duration.fromObject({ months: 6 })).toFormat('YYYY-MM-DD')
    },
    limit: 30,
    offset: 0
  }
}

const absenceOverviewModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  },
  actions: {
    selectOrganizationalUnit ({ commit, state }, organizationalUnitId) {
      commit('setPage', {
        ...state.page,
        organizationalUnitId,
        limit: 30,
        offset: 0
      })
    },
    updateRange ({ commit, state }, range) {
      commit('setPage', {
        ...state.page,
        limit: 30,
        offset: 0,
        range: {
          start: range.start,
          endInclusive: range.endInclusive
        }
      })
    }
  },
}

export default absenceOverviewModule
export { initialState }
