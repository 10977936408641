import { MUTATIONS } from '@/store'

export function handleMutationSuccess (notification, route) {
  return function () {
    if (route) {
      this.$router.push(
        typeof route === 'function' ? route(this) : { name: route },
        () => this.$store.dispatch(MUTATIONS.SET_NOTIFICATION_OLD, notification)
      )
    } else {
      this.$store.dispatch(MUTATIONS.SET_NOTIFICATION_OLD, notification)
    }
  }
}

/**
 * @param {Object} mutation
 * @param {Function} mutation.serviceFn - the service function
 * @param {string} [mutation.name] - name of the component method, name of serviceFn will be used otherwise
 * @param {Function} [mutation.onSuccess] - the function to call if the mutation succeeded
 */
export const useMutation = ({ name, serviceFn, onSuccess }) => ({
  methods: {
    async [serviceFn.name || name] (...args) {
      const { data, errors } = await serviceFn(...args)
      if (errors != null) throw errors

      onSuccess && onSuccess.call(this, data)

      return data
    }
  }
})
