export default {
  button: 'px-4 py-2 border rounded text-white bg-blue-800 font-semibold hover:bg-blue-600 disabled:hover:bg-blue-800 disabled:opacity-50 disabled:cursor-not-allowed',
  form: '',
  input: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputNumber: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputFile: {
    default: 'border rounded px-0 py-0 cursor-pointer file:font-semibold file:cursor-pointer file:tracking-widest file:rounded file:border-0 file:text-gray-700 file:px-4 file:py-2 file:mr-4 file:uppercase',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputDecimal: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputRadio: '',
  inputRadioLabel: 'ml-1',
  inputRadioContainer: '',
  inputCheckbox: '',
  inputCheckboxLabel: 'ml-1',
  inputCheckboxContainer: '',
  inputCurrency: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputPercentage: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },


  inputDuration: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputDurationHours: 'outline-none select-all bg-transparent tabular-nums',
  inputDurationMinutes: 'outline-none select-all bg-transparent tabular-nums',
  inputDurationDelimiter: '',

  inputDateContainer: 'block relative text-gray-700',
  inputDate: {
    layout: 'relative',
    default: 'w-full px-4 py-2 border rounded bg-white disabled:bg-gray-100 disabled:text-gray-500',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputDateMenu: 'absolute w-max z-10 bg-white rounded shadow-lg p-4 text-gray-700 break-normal',
  inputDateMenuNav: 'flex items-center justify-between',
  inputDateMenuNavButtonPreviousMonth: 'w-8 h-8 rounded flex justify-center items-center',
  inputDateMenuNavButtonNextMonth: 'w-8 h-8 rounded flex justify-center items-center',
  inputDateMenuNavSelectMonth: 'appearance-none pl-2 py-2 pr-6 font-semibold',
  inputDateMenuNavSelectMonthIcon: 'w-5 h-5 text-gray-400 absolute right-0 mr-2 pointer-events-none',
  inputDateMenuNavSelectYear: 'ml-1 appearance-none pl-2 py-2 pr-8 font-semibold',
  inputDateMenuNavSelectYearIcon: 'w-5 h-5 text-gray-400 absolute right-0 mr-2 pointer-events-none',
  inputDateMenuDaysContainer: 'mt-4 grid grid-cols-7 md:grid-cols-7 gap-2 text-center',
  inputDateMenuDaysInWeek: 'text-sm font-semibold text-gray-800',
  inputDateMenuDaysOutsideOfMonth: 'rounded-full w-8 sm:w-10 h-8 sm:h-10 px-2 py-1 leading-none text-gray-400 flex justify-center items-center',
  inputDateMenuDaysInMonth: 'rounded-full w-8 sm:w-10 h-8 sm:h-10 px-2 py-1 leading-none text-gray-700 flex justify-center items-center',
  inputDateMenuDaysInMonthSelected: '!bg-blue-800 !text-blue-100',
  inputDateMenuDaysInMonthToday: 'bg-blue-100 text-blue-800',
  inputDateMenuActions: 'flex justify-between items-center border-t mt-1',
  inputDateMenuActionsReset: 'px-2 mt-2',
  inputDateMenuActionsDone: 'px-2 mt-2 font-semibold',

  inputTime: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },

  inputYearMonth: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  inputYearMonthContainer: 'flex flex-row space-x-2',
  inputYearMonthSelectMonth: 'pl-4 pr-8 py-2 w-full border rounded appearance-none',
  inputYearMonthSelectMonthIcon: 'w-5 h-5 absolute right-0 mr-2 pointer-events-none',
  inputYearMonthSelectYear: 'pl-4 pr-8 py-2 w-full border rounded appearance-none',
  inputYearMonthSelectYearIcon: 'w-5 h-5 absolute right-0 mr-2 pointer-events-none',

  textarea: {
    default: 'px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  select: {
    default: 'pl-4 pr-8 py-2 w-full border rounded appearance-none bg-white',
    error: 'border-rose-500 bg-rose-100 text-rose-700 placeholder-rose-500'
  },
  selectIcon: {
    default: 'w-5 h-5 absolute right-0 mr-2 pointer-events-none',
    error: 'text-rose-700'
  },
  selectContainer: 'relative inline-flex items-center',
  formField: 'flex flex-col',
  formFieldLabel: {
    default: 'font-semibold mb-1',
    error: 'text-rose-500'
  },
  formFieldDescription: {
    default: 'h-4 text-sm leading-none mt-1',
    error: 'text-rose-500'
  },
  selectAutocomplete: {
    default: 'w-full px-4 py-2 border rounded',
    error: 'border-rose-500 bg-rose-100 placeholder-rose-500'
  },
  selectAutocompleteContainer: 'relative',
  selectAutocompleteMenu: 'absolute z-10 shadow-lg rounded bg-white w-full',
  selectAutocompleteMenuList: 'space-y-1 p-2 overflow-auto max-h-64',
  selectAutocompleteMenuListItem: 'p-2 w-full rounded text-left hover:bg-gray-100 focus:bg-gray-100 inline-block',
  selectAutocompleteGroupedMenuList: 'space-y-1 p-2 overflow-auto max-h-64',
  selectAutocompleteGroupedMenuListItem: 'p-2 w-full rounded text-left hover:bg-gray-100 focus:bg-gray-100 inline-block flex flex-row',
  selectAutocompleteGroupedMenuListItemGroup: 'font-bold w-1/2',
  selectAutocompleteGroupedMenuListItemLabel: 'w-1/2',

  multiselectAutocomplete: {
    default: 'px-4 py-2 w-full border rounded bg-white flex flex-wrap gap-1',
    error: 'border-red-500 bg-red-100 placeholder-red-500'
  },
  multiselectAutocompleteContainer: 'relative',
  multiselectAutocompleteSelectedElement: 'flex text-sm text-blue-100 rounded border border-blue-900 overflow-hidden',
  multiselectAutocompleteSelectedElementGroup: ' px-1 bg-blue-900 ',
  multiselectAutocompleteSelectedElementLabel: ' px-1 text-blue-900 bg-blue-100',
  multiselectAutocompleteFilterField: 'flex-1 focus:outline-none',
  multiselectAutocompleteMenu: 'absolute z-10 shadow-lg rounded bg-white w-full',
  multiselectAutocompleteMenuList: 'space-y-1 p-2 overflow-auto max-h-64',
  multiselectAutocompleteMenuListItem: 'p-2 w-full rounded text-left hover:bg-gray-100 inline-block flex',
  multiselectAutocompleteMenuListItemSelection: 'p-2 w-full rounded text-left bg-gray-100 border-l-4 border-blue-200 inline-block flex',
  multiselectAutocompleteGroupedMenuListItemGroup: 'font-bold w-1/2',
  multiselectAutocompleteGroupedMenuListItemLabel: 'w-1/2',
}
