import { paginated } from '@/store/helpers'

const initialState = {
  page: {
    limit: 30,
    offset: 0,
    staffId: null
  }
}

const workingTimeAgreementModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  },
  actions: {
    updateStaff ({ commit, state }, staffId) {
      commit('setPage', {
        ...state.page,
        staffId,
        limit: 30,
        offset: 0
      })
    }
  },
}

export default workingTimeAgreementModule
export { initialState }
