<template>
  <form-card
    :heading="$t('title.forgotPassword')"
    :description="$t('subtitle.forgotPassword')"
    :error-message="errorMessage"
    @submit="handleRequestPasswordReset"
  >
    <template #content>
      <form-field
        v-slot="props"
        :label="$t('label.email')"
        :description="$t('help.requestPasswordReset')"
        rules="required|email"
      >
        <base-input
          v-model="email"
          v-bind="props"
          type="text"
        />
      </form-field>
    </template>
    <template #footer>
      <button-primary
        :text="$t('button.reset')"
      />
    </template>
  </form-card>
</template>

<script>
  import FormCard from '@/components/form/form-card'
  import ButtonPrimary from '@/components/button/button-primary'
  import { mapActions } from 'vuex'
  export default {
    components: { FormCard, ButtonPrimary },
    data () {
      return {
        email: '',
        errorMessage: ''
      }
    },
    methods: {
      ...mapActions('user', ['requestPasswordReset']),
      handleRequestPasswordReset () {
        this.requestPasswordReset({
          email: this.email
        })
          .then(() => {
            this.$emit('password-reset-request')
          })
      },
    }
  }
</script>
