export default function usePagination () {
  return {
    computed: {
      limit () {
        return 25
      },
      offset () {
        return (this.$route.query.page - 1) * this.limit || 0
      }
    }
  }
}
