<template>
  <footer>
    <nav class="text-sm text-gray-600 space-x-2 flex flex-wrap justify-center">
      <a
        href="https://help.zeitfabrik.de"
        target="_blank"
      >{{ $t('link.help') }}</a>
      <span>&bull;</span>
      <a
        :href="`${marketingPageUrl}/imprint`"
        target="_blank"
      >{{ $t('link.imprint') }}</a>
      <span>&bull;</span>
      <a
        :href="`${marketingPageUrl}/privacy-policy`"
        target="_blank"
      >{{ $t('link.privacyFooter') }}</a>
      <span>&bull;</span>
      <a
        :href="`${marketingPageUrl}/tac`"
        target="_blank"
      >{{ $t('link.tacFooter') }}</a>
      <span>&bull;</span>
      <a
        :href="'javascript: Cookiebot.renew()'"
      >{{ $t('link.cookieConsent') }}</a>
    </nav>
  </footer>
</template>

<script>
  export default {
    name: 'TheFooter',
    computed: {
      marketingPageUrl() {
        return process.env.VUE_APP_MARKETING_PAGE_URL
      }
    },
  }
</script>
