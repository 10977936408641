<template>
  <form-base
    :heading="heading"
    :description="description"
    class="w-full max-w-screen-md mx-auto bg-white rounded-md border border-gray-300 shadow-xl"
    v-on="$listeners"
  >
    <template #default>
      <div class="flex flex-col space-y-3 px-6 py-4">
        <slot
          name="content"
        />
      </div>
    </template>
    <template
      #footer
    >
      <div
        class="w-full bg-gray-100 flex flex-col justify-end px-6 py-4 mt-4 rounded-b space-y-4 sm:space-y-0 sm:space-x-4 sm:items-center sm:flex-row"
      >
        <div
          v-if="errorMessage"
          class="text-rose-600 flex-1"
        >
          {{ errorMessage }}
        </div>
        <slot
          name="footer"
        />
      </div>
    </template>
  </form-base>
</template>

<script>
  import FormBase from '@/components/form/form-base'

  export default {
    components: { FormBase },
    props: {
      heading: {
        type: String,
        required: true
      },
      description: {
        type: String,
        default () {
          return ''
        }
      },
      errorMessage: {
        type: String,
        required: false,
        default () {
          return ''
        }
      }
    }
  }
</script>
