const taskService = {
  canExecute ({ category, predicate }, tasks) {
    if (category == null) return true

    if (predicate === '*') {
      return tasks && tasks.some(it => it.category === category)
    } else {
      return tasks && tasks.some(it => it.category === category && it.predicate === predicate)
    }
  }
}

export function hasTask(tasks, task, subject = SUBJECT.ANY, object = OBJECT.ANY) {
  let list = tasks

  if(subject !== SUBJECT.ANY) {
    list = list.filter((it) => it.subject.name === subject || it.subject.name === SUBJECT.MEMBER)
  }

  if(object !== OBJECT.ANY) {
    list = list.filter((it) => it.object?.name === object)
  }

  const definitions = list.map(it => it.definition)
  return taskService.canExecute(task, definitions)
}

export function hasTaskForOthers(tasks, task, subject = SUBJECT.ANY) {
  return hasTask(tasks.filter(it => it.object?.name !== OBJECT.SELF), task, subject)
}

export const SUBJECT = {
  SUPERVISOR: 'supervisor',
  EMPLOYEE: 'employee',
  MEMBER: 'member',
  ANY: 'any'
}

export const OBJECT = {
  SUPERVISOR: 'supervisor',
  EMPLOYEE: 'employee',
  SELF: 'self',
  ANY: 'any'
}

export const TASKS = {
  TIME_RECORD_ANY: { category: 'timeRecording', predicate: '*' },
  CLOCK_TIME: { category: 'timeRecording', predicate: 'clockTime' },
  MANAGE_TIME_INTERVAL: { category: 'timeRecording', predicate: 'manageTimeInterval' },
  VIEW_TIME_ACCOUNT: { category: 'timeRecording', predicate: 'viewTimeAccount' },
  VIEW_WORKING_TIME_LAW_VIOLATIONS: { category: 'timeRecording', predicate: 'viewWorkingTimeLawViolations' },
  VIEW_SCHEDULED_BREAKS: { category: 'timeRecording', predicate: 'viewScheduledBreaks' },
  MANAGE_TIME_SHEET: { category: 'timeRecording', predicate: 'manageTimeSheet' },
  SUBMIT_TIME_SHEET: { category: 'timeRecording', predicate: 'submitTimeSheet' },

  TIME_DISTRIBUTION_ANY: { category: 'timeDistribution', predicate: '*' },
  MANAGE_DISTRIBUTED_TIMES: { category: 'timeDistribution', predicate: 'manageDistributedTimes' },

  REQUEST_LEAVE: { category: 'leaveAndSickNote', predicate: 'requestLeave' },
  MANAGE_LEAVE: { category: 'leaveAndSickNote', predicate: 'manageLeave' },
  MANAGE_SICK_NOTE: { category: 'leaveAndSickNote', predicate: 'manageSickNote' },
  SUBMIT_SICK_NOTE: { category: 'leaveAndSickNote', predicate: 'submitSickNote' },

  MANAGE_CUSTOMERS: { category: 'customerAndInvoice', predicate: 'manageCustomers' },
  MANAGE_INVOICES: { category: 'customerAndInvoice', predicate: 'manageInvoices' },

  MANAGE_ATTENDANCE_MONITORS: { category: 'organization', predicate: 'manageAttendanceMonitors' },

  ANALYZE_ORDERS: { category: 'analysis', predicate: 'analyzeOrders' },
  ANALYZE_CUSTOMERS: { category: 'analysis', predicate: 'analyzeCustomers' },

  EVALUATE_WORKING_TIME: { category: 'report', predicate: 'evaluateWorkingTime' },
  EVALUATE_ABSENCES: { category: 'report', predicate: 'evaluateAbsences' },
  VIEW_LEAVE_AND_TIME_ACCOUNTS: { category: 'report', predicate: 'viewLeaveAndTimeAccounts' },
}

export const LICENSES = {
  PROFESSIONAL: 'PROFESSIONAL',
  ESSENTIAL: 'ESSENTIAL',
  NONE: 'NONE'
}

export default taskService
