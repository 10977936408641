import Vue from 'vue'

Vue.filter('currency', currency)

function currency (value) {
  if (value == null) return

  const split = value.split(' ', 2)

  return new Intl.NumberFormat(
    navigator.language,
    { style: 'currency', currency: split[0] }
  ).format(split[1])
}

export default currency
