<template>
  <full-size-page-layout class="max-w-screen-md">
    <template v-if="!token">
      <form-card-password-reset-request @password-reset-request="handlePasswordResetRequest" />
      <p class="text-center mt-4">
        {{ $t('paragraph.rememberedPassword') }}
        <router-link
          class="text-primary"
          :to="{ name: 'login' }"
        >
          {{ $t('link.signIn') }}
        </router-link>
      </p>
    </template>
    <form-card-password-reset
      v-else
      @password-reset="handlePasswordReset"
    />
  </full-size-page-layout>
</template>

<script>
  import { LayoutOutsideOrganization } from '@/layouts'
  import FormCardPasswordResetRequest from '@/components/form/form-card-password-reset-request'
  import FormCardPasswordReset from '@/components/form/form-card-password-reset'
  export default {
    name: 'PasswordReset',
    components: { FormCardPasswordReset, FormCardPasswordResetRequest, FullSizePageLayout: LayoutOutsideOrganization },
    computed: {
      token () {
        return this.$route.query.token
      },
    },
    methods: {
      handlePasswordResetRequest() {
        this.$router.push({ name: 'login' })
      },
      handlePasswordReset() {
        this.$router.push({ name: 'login' })
      }
    }
  }
</script>
