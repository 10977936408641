import { gql, watchQuery } from '@/services/apollo/apollo'

const TODO_PAGE = gql`
  query toDoPage($input: ToDoPageInput!) {
    toDoPage(input: $input) {
      toDo {
        id
        affected {
          id
          firstName
          lastName
        }
        type
        creationDate
        itemId
      }
      totalCount
    }
  }
`

const toDoService = {
  toDoPage (variables, { fetchPolicy, pollInterval }) {
    return watchQuery({ query: TODO_PAGE, variables, fetchPolicy, pollInterval })
  },
}

export default toDoService
