import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from './de'
import en from './en'

Vue.use(VueI18n)

const numberFormats = {
  'de': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'en': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
}

function getLocale () {
  return navigator.language.slice(0, 2)
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: 'en',
  silentFallbackWarn: process.env.NODE_ENV === 'production',
  messages: { en, de },
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  numberFormats
})
