<template>
  <form-card
    :heading="heading"
    :description="description"
    :error-message="errorMessage"
    @submit="signIn"
  >
    <template #content>
      <form-field
        v-slot="props"
        :label="$t('placeholder.username')"
        rules="required|email"
      >
        <base-input
          v-model="username"
          v-bind="props"
          type="text"
          :readonly="emailReadonly"
          :disabled="emailReadonly"
          autocomplete="email"
        />
      </form-field>
      <form-field
        v-slot="props"
        :label="$t('placeholder.password')"
        rules="required"
      >
        <base-input
          v-model="password"
          v-bind="props"
          type="password"
          autocomplete="current-password"
        />
      </form-field>
      <div>
        <router-link
          :to="{ name: 'reset-password' }"
          class="text-primary"
        >
          {{ $t('link.forgotPassword') }}
        </router-link>
      </div>
    </template>
    <template #footer>
      <button-primary
        :text="$t('button.signIn')"
      />
    </template>
  </form-card>
</template>

<script>
  import FormCard from '@/components/form/form-card'
  import ButtonPrimary from '@/components/button/button-primary'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: { FormCard, ButtonPrimary },
    props: {
      heading: {
        type: String,
        required: false,
        default () {
          return this.$t('title.signIn')
        }
      },
      description: {
        type: String,
        required: false,
        default() {
          return ''
        }
      },
      initialEmail: {
        type: String,
        default () {
          return ''
        }
      },
      emailReadonly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        username: this.initialEmail,
        password: '',
        errorMessage: ''
      }
    },
    computed: {
      ...mapGetters('user', ['fetchLoggedInUser']),
    },
    methods: {
      ...mapActions('user', ['login', 'setEmailConfirmed']),
      async signIn () {
        try {
          await this.login({ username: this.username, password: this.password })
          this.fetchLoggedInUser().subscribe(({ data, loading }) => {
            if (loading) return
            if (data) {
              this.setEmailConfirmed(data.me.email.confirmed)
              this.$emit('login')
            }
          })
        } catch (e) {
          this.errorMessage = this.$t('help.authenticationFailed')
        }
      }
    }
  }
</script>
