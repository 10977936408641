import { paginated } from '../helpers/paginated'

const initialState = {
  page: {},
  staff: {},
}

const recordingModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations,
    setStaff (state, staff) {
      state.staff = staff
    }
  },
  actions: {
    updateStaff ({ commit }, staff) {
      commit('setStaff', staff)
      commit('setPage', { staffId: staff.id, limit: 30, offset: 0 })
    }
  }
}

export default recordingModule
export { initialState }
