import { userManagementService } from '@/api'
import router from '@/services/vuerouter/vueRouter'
import { Mutex } from 'async-mutex'
import gql from 'graphql-tag'
import { onError as onApolloError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'

const mutex = new Mutex()

function installApollo ({
                          httpLinkConfig = { uri: '/graphql', credentials: 'include' },
                          inMemoryCacheConfig,
                          beforeRequest,
                          onError
                        }) {
  const errorLink = onError && onApolloError(onError)
  const requestLink = beforeRequest && createRequestLink(beforeRequest)
  const httpLink = new HttpLink(httpLinkConfig)

  const apollo = new ApolloClient({
    link: ApolloLink.from([errorLink, requestLink, httpLink]),
    cache: new InMemoryCache(inMemoryCacheConfig),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  })
  return apollo
}

function createRequestLink (beforeRequest) {
  return new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => beforeRequest(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  )
}

function isApolloInitialized () {
  if (typeof apollo === 'undefined') {
    return false
  } else {
    return true
  }
}

const graphQlErrorHandler = new function () {
  this.handle = function () {}
}()

const beforeRequest = async operation => {
  const release = await mutex.acquire()
  try {
    const accessExpired = userManagementService.isAccessExpired()
    if (accessExpired) await userManagementService.access()
    operation.setContext({
      headers: {
        'X-XSRF-TOKEN': userManagementService.getXsrfToken(),
        'Authorization': 'Bearer ' + userManagementService.getAccessToken()
      }
    })
  } finally {
    release()
  }
}

const onError = error => {
  if (error.networkError) {
      router.push({ name: 'network-error', query: {
          from: router.currentRoute.query.from || router.currentRoute.fullPath
        } }
      )
  } else if (error.graphQLErrors) {
    graphQlErrorHandler.handle(error.graphQLErrors[0])
  }
}

const apollo = installApollo({
  httpLinkConfig: { uri: `${process.env.VUE_APP_API_URL}/graphql`, credentials: 'include' },
  beforeRequest: beforeRequest,
  onError: onError
})

function query(queryOptions) {
  return apollo.query(queryOptions)
}

function watchQuery ({ query, variables, fetchPolicy, pollInterval }) {
  if (!isApolloInitialized()) return

  let options = { query, variables }
  fetchPolicy != null && (options['fetchPolicy'] = fetchPolicy)
  pollInterval != null && (options['pollInterval'] = pollInterval)

  return apollo.watchQuery(options)
}

function mutate ({ mutation, variables, update, refetchQueries }) {
  if (!isApolloInitialized()) return

  return apollo.mutate({
    mutation: mutation,
    variables: variables,
    update: update,
    refetchQueries: refetchQueries
  })
}

export default apollo
export { gql, watchQuery, query, mutate }
