import Vue from 'vue'
import Vuex from 'vuex'
import recordingModule, { initialState as initialRecordingState } from './module/recordingModule'
import leaveModule, { initialState as initialLeaveState } from './module/leaveModule'
import sickNoteModule, { initialState as initialSickNoteState } from './module/sickNoteModule'
import staffModule, { initialState as initialStaffState } from './module/staffModule'
import attendanceMonitorModule, { initialState as initialAttendanceMonitorState } from './module/attendanceMonitorModule'
import organizationModule, { initialState as initialOrganizationState } from './module/organizationModule'
import orderModule, { initialState as initialOrderState } from './module/orderModule'
import customerModule, { initialState as initialCustomerState } from './module/customerModule'
import userModule from './module/userModule'
import invoiceModule, { initialState as initialInvoiceState } from './module/invoiceModule'
import workingTimeModule, { initialState as initialWorkingTimeState } from './module/workingTimeModule'
import absenceOverviewModule, { initialState as initialAbsenceOverviewState } from './module/absenceOverviewModule'
import workingTimeAgreementModule, {
  initialState as initialWorkingTimeAgreementState
} from './module/workingTimeAgreementModule'
import leaveAgreementModule, { initialState as initialLeaveAgreementState } from './module/leaveAgreementModule'
import aliveActions from '@/store/actions/aliveActions'
import invoiceTemplateModule, { initialState as initialInvoiceTemplateState } from '@/store/module/invoiceTemplateModule'

Vue.use(Vuex)

export const MUTATIONS = {
  SET_BREADCRUMBS: 'setBreadcrumbs',
  SET_SIDEBAR: 'setSidebar',
  RESET_NOTIFICATION: 'resetNotification',
  SET_NOTIFICATION: 'setNotification',
  SET_NOTIFICATION_OLD: 'setNotification',
  SET_NOTIFICATION_ERROR: 'setNotificationError'
}

export const STATE = {
  BREADCRUMBS: 'breadcrumbs',
  SIDEBAR: 'sidebar',
  NOTIFICATION: 'notification',
}

const store = new Vuex.Store({
  state: {
    [STATE.BREADCRUMBS]: [],
    [STATE.SIDEBAR]: null,
    [STATE.NOTIFICATION]: null,
  },
  mutations: {
    reset (state) {
      state.recording = { ...initialRecordingState }
      state.leave = { ...initialLeaveState }
      state.sickNote = { ...initialSickNoteState }
      state.staff = { ...initialStaffState }
      state.attendanceMonitor = { ...initialAttendanceMonitorState }
      state.order = { ...initialOrderState }
      state.customer = { ...initialCustomerState }
      state.invoice = { ...initialInvoiceState }
      state.workingTime = { ...initialWorkingTimeState }
      state.absenceOverview = { ...initialAbsenceOverviewState }
      state.organization = { ...initialOrganizationState }
      state.workingTimeAgreement = { initialWorkingTimeAgreementState }
      state.leaveAgreement = { initialLeaveAgreementState }
      state.invoiceTemplate = { ...initialInvoiceTemplateState }
    },
    [MUTATIONS.SET_BREADCRUMBS] (state, breadcrumbs) {
      state[STATE.BREADCRUMBS] = breadcrumbs
    },
    [MUTATIONS.SET_SIDEBAR] (state, sidebar) {
      state[STATE.SIDEBAR] = sidebar
    },
    [MUTATIONS.SET_NOTIFICATION] (state, notification) {
      state[STATE.NOTIFICATION] = notification
    }
  },
  actions: {
    setNotification ({ commit }, message) {
      commit('setNotification', {
        message: message,
        type: 'success',
      })
    },
    setNotificationError ({ commit }, message) {
      commit('setNotification', {
        message: message,
        type: 'error',
      })
    },
    resetNotification ({ commit }) {
      commit('setNotification', undefined)
    },
    ...aliveActions
  },
  modules: {
    user: userModule,
    recording: recordingModule,
    leave: leaveModule,
    sickNote: sickNoteModule,
    staff: staffModule,
    attendanceMonitor: attendanceMonitorModule,
    order: orderModule,
    customer: customerModule,
    invoice: invoiceModule,
    workingTime: workingTimeModule,
    absenceOverview: absenceOverviewModule,
    organization: organizationModule,
    workingTimeAgreement: workingTimeAgreementModule,
    leaveAgreement: leaveAgreementModule,
    invoiceTemplate: invoiceTemplateModule,
  }
})

export default store
