import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const DISTRIBUTABLE_DAY_PAGE = gql`
  query distributableDayPage($input: TimeDistributionQueryInput!) {
    distributableDayPage(input: $input) {
      distributableDay {
        id
        day
        status
        time
        distributedTime
        overbookedTime
      }
      totalCount
    }
  }
`

const DISTRIBUTABLE_DAY = gql`
  query distributableDay($input: DistributableDayQueryInput!) {
    distributableDay(input: $input) {
      id
      staff {
        id
      }
      day
      status
      time
      distributedTime
      overbookedTime
      distributedOrderTime {
        id
        order {
          id
          name
          customer {
            name
          }
          commentRequired
        }
        distributedTime
        comment
        invoiced
      }
      executableTasks {
        definition {
          category
          predicate
        }
      }
    }
  }
`

const ORDERS_BY_CUSTOMER = gql`
  query ordersByCustomer($input: OrdersByCustomerInput!) {
    ordersByCustomer(input: $input) {
      customer {
        id
        name
      }
      orders {
        id
        name
        customerReferenceNumber
        commentRequired
      }
    }
  }
`

const ORDER_BY_CUSTOMER_FOR_ORDER_ANALYSIS = gql`
  query ordersByCustomersForOrderAnalysis($input: OrdersByCustomerForOrderAnalysisInput!) {
    ordersByCustomerForOrderAnalysis(input: $input) {
      customer {
        id
        name
      }
      orders {
        id
        name
        customerReferenceNumber
        commentRequired
      }
    }
  }
`

const ORDER_ANALYSIS = gql`
  query orderAnalysis($input: OrderAnalysisQueryInput!) {
    orderAnalysis(input: $input) {
      distributedOrderTimes {
        staffName
        orderName
        distributedTime
        day
      }
      durationTotal
    }
  }
`

const DISTRIBUTE_TIME = gql`
  mutation distributeTime($input: DistributeTimeInput!) {
    distributeTime(input: $input) {
      id
      order {
        id
        name
      }
      distributedTime
      comment
      invoiced
    }
  }
`

const EDIT_DISTRIBUTED_ORDER_TIME = gql`
  mutation editDistributedTime($input: EditDistributeTimeInput!) {
    editDistributedTime(input: $input) {
      id
      order {
        id
        name
      }
      distributedTime
      comment
      invoiced
    }
  }
`

const DELETE_DISTRIBUTED_ORDER_TIME = gql`
  mutation deleteDistributedOrderTime($input: DeleteDistributedOrderTimeInput!) {
    deleteDistributedOrderTime(input: $input)
  }
`

const ORDER_STOP_WATCH = gql`
  query orderStopWatch($input: OrderStopWatchInput!) {
    orderStopWatch(input: $input) {
      id,
      orderId,
      startTime,
      staffId
    }
  }
`

const START_ORDER_TIME_RECORDING = gql`
  mutation startOrderTimeRecording($input: StartOrderTimeRecordingInput!){
    startOrderTimeRecording(input: $input){
      id,
      orderId,
      startTime,
      staffId
    }
  } `
const STOP_ORDER_TIME_RECORDING = gql`
  mutation stopOrderTimeRecording($input: StopOrderTimeRecordingInput!){
    stopOrderTimeRecording(input: $input){
      id,
      orderId,
      startTime,
      staffId
    }
  } `

const timeDistributionService = {

  distributableDayPage (variables) {
    return watchQuery({ query: DISTRIBUTABLE_DAY_PAGE, variables })
  },

  distributableDay (variables, { fetchPolicy }) {
    return watchQuery({ query: DISTRIBUTABLE_DAY, variables, fetchPolicy })
  },

  ordersByCustomers (variables) {
    return watchQuery({ query: ORDERS_BY_CUSTOMER, variables })
  },

  ordersByCustomerForOrderAnalysis (variables) {
    return watchQuery({ query: ORDER_BY_CUSTOMER_FOR_ORDER_ANALYSIS, variables })
  },

  distributeTime (input) {
    return mutate({ mutation: DISTRIBUTE_TIME, variables: { input } })
  },

  deleteDistributedOrderTime (input) {
    return mutate({ mutation: DELETE_DISTRIBUTED_ORDER_TIME, variables: { input } })
  },

  editDistributedOrderTime (input) {
    return mutate({ mutation: EDIT_DISTRIBUTED_ORDER_TIME, variables: { input } })
  },

  orderStopWatch (variables) {
    return watchQuery({ query: ORDER_STOP_WATCH, variables })
  },

  orderAnalysis (variables) {
    return watchQuery({ query: ORDER_ANALYSIS, variables })
  },

  startOrderTimeRecording (input) {
    return mutate({ mutation: START_ORDER_TIME_RECORDING, variables: { input } })
  },

  stopOrderTimeRecording (input) {
    return mutate({ mutation: STOP_ORDER_TIME_RECORDING, variables: { input } })
  }
}

export default timeDistributionService
