<template>
  <full-size-page-layout>
    <div
      v-if="authenticated"
      class="space-y-4"
    >
      <div>
        <the-page-heading
          :text="$t('title.emailConfirmed')"
        />
        <p>
          {{ $t('subtitle.emailConfirmed') }}
        </p>
      </div>
      <button-primary
        :text="$t('button.startNow')"
        :loading="confirming"
        @click="handleStartNow"
      />
    </div>
    <div v-else>
      <form-card-login :description="$t('subtitle.confirmEmailLogin')" />
    </div>
  </full-size-page-layout>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { LayoutOutsideOrganization } from '@/layouts'
  import { enterZeitFabrik, useQuery } from '@/mixins'
  import FormCardLogin from '@/components/form/form-card-login'
  import ButtonPrimary from '@/components/button/button-primary.vue'
  import ThePageHeading from '@/components/the-page-heading.vue'

  export default {
    name: 'EmailConfirm',
    components: { ThePageHeading, ButtonPrimary, FormCardLogin, FullSizePageLayout: LayoutOutsideOrganization },
    data () {
      return {
        confirming: true,
        emailConfirmed: false
      }
    },
    computed: {
      ...mapState('user', ['authenticated']),
      ...mapGetters('user', ['fetchLoggedInUser']),
      notAuthenticated () {
        return !this.authenticated
      },
      token () {
        return this.$route.query.token
      }
    },
    // Ensure that token query param has been set
    beforeRouteEnter (to, from, next) {
      to.query.token == null ? next('/login') : next()
    },
    methods: {
      ...mapActions('user', ['confirmEmail']),
      dispatchConfirmEmail () {
        this.confirming = true
        this.confirmEmail({ input: { token: this.token } })
          .then(({ data, errors }) => {
            this.emailConfirmed = !errors
          })
          .finally(() => this.confirming = false)
      },
      async handleStartNow () {
        this.loading = true
        await this.handleEnter(this.organizationId)
        this.loading = false
      }
    },
    mixins: [
      enterZeitFabrik('handleEnter'),
      useQuery({
        name: 'me',
        serviceFn: 'fetchLoggedInUser',
        skipIf: 'notAuthenticated',
        onUpdate (me) {
          if (!me.email.confirmed) {
            this.dispatchConfirmEmail()
          } else {
            this.confirming = false
          }
        }
      })
    ]
  }
</script>
