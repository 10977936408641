<template>
  <div
    class="border rounded p-4 flex"
  >
    <div class="flex-1">
      {{ message }}
    </div>
    <button @click="closeNotification">
      <svg-x-circle-solid />
    </button>
  </div>
</template>

<script>
  import SvgXCircleSolid from '@/components/svg/svg-x-circle-solid.vue'
  import { MUTATIONS } from '@/store'

  export default {
    name: 'BaseNotification',
    components: { SvgXCircleSolid },
    props: {
      message: {
        type: String,
        required: true
      }
    },
    methods: {
      closeNotification() {
        this.$store.dispatch(MUTATIONS.RESET_NOTIFICATION)
      }
    }
  }
</script>
