import Vue from 'vue'
import { DateTime } from 'luxon'

Vue.filter('localDateTime', localDateTime)

function localDateTime (dateString) {
  return dateString ? DateTime.fromISO(dateString).setLocale(navigator.language).toLocaleString({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }) : '-'
}

export default localDateTime
