import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const FRAGMENTS = {
  organizationalUnit: gql`
  fragment organizationalUnit on OrganizationalUnit {
    id
    name
    supervisors {
      id
      firstName
      lastName
    }
    employees {
      id
      firstName
      lastName
    }
    tasks {
      typeId
    }
  }
  `
}

const ORGANIZATIONAL_UNITS = gql`
  query OrganizationalUnits($input: OrganizationalUnitsInput!) {
    organizationalUnits(input: $input) {
      id
      name
    }
  }
`

const ORGANIZATIONAL_UNIT = gql`
  query organizationalUnit($input: OrganizationalUnitQueryInput!) {
    organizationalUnit(input: $input) {
      ...organizationalUnit
    }
  }

  ${FRAGMENTS.organizationalUnit}
`

const TASK_TYPES_BY_CATEGORY = gql`
  query TaskTypesByCategory {
    organizationalUnitTaskTypesByCategory {
      category
      taskTypes {
        id
        name
        taskDefinition {
          predicate
        }
      }
    }
  }
`

const CREATE_ORGANIZATIONAL_UNIT = gql`
  mutation CreateOrganizationalUnit($input: CreateOrganizationalUnitInput!) {
    createOrganizationalUnit(input: $input) {
      id
    }
  }
`

const POSSIBLE_SUPERVISORS = gql`
  query PossibleSupervisors($input: PossibleMemberInput!) {
    possibleSupervisors(input: $input) {
      staff {
        id
        firstName
        lastName
      },
      employee,
      supervisor
    }
  }
`

const POSSIBLE_EMPLOYEES = gql`
  query PossibleEmployees($input: PossibleMemberInput!) {
    possibleEmployees(input: $input) {
      staff {
        id
        firstName
        lastName
      },
      employee,
      supervisor
    }
  }
`

const ADD_SUPERVISORS = gql`
  mutation AddSupervisors($input: AddSupervisorsInput!) {
    addSupervisors(input: $input)
  }
`

const ADD_EMPLOYEES = gql`
  mutation AddEmployees($input: AddEmployeesInput!) {
    addEmployees(input: $input)
  }
`

const REMOVE_MEMBER = gql`
  mutation RemoveMember($input: RemoveMemberInput!) {
    removeMember(input: $input)
  }
`

const EDIT_ORGANIZATIONAL_UNIT = gql`
  mutation EditOrganizationalUnit($input: EditOrganizationalUnitInput!) {
    editOrganizationalUnit(input: $input) {
      name
    }
  }
`

const DELETE_ORGANIZATIONAL_UNIT = gql`
  mutation DeleteOrganizationalUnit($input: DeleteOrganizationalUnitInput!) {
    deleteOrganizationalUnit(input: $input)
  }
`

const EDIT_ORGANIZATIONAL_UNIT_TASKS = gql`
  mutation editOrganizationalUnitTasks($input: EditOrganizationalUnitTasksInput!) {
    editOrganizationalUnitTasks(input: $input) {
      ...organizationalUnit
    }
  }

  ${FRAGMENTS.organizationalUnit}
`

const organizationalUnitManagementService = {
  organizationalUnits (variables, { fetchPolicy }) {
    return watchQuery({ query: ORGANIZATIONAL_UNITS, variables, fetchPolicy })
  },

  createOrganizationalUnit (input) {
    return mutate({ mutation: CREATE_ORGANIZATIONAL_UNIT, variables: { input } })
  },

  editOrganizationalUnit ({ organizationalUnitId, name }) {
    return mutate({
        mutation: EDIT_ORGANIZATIONAL_UNIT,
        variables: { input: { organizationalUnitId, name } },
        update: (store, { data: { editOrganizationalUnit }, errors }) => {
          if (errors) return
          const data = store.readQuery({
            query: ORGANIZATIONAL_UNIT,
            variables: { input: { organizationalUnitId } }
          })

          data.organizationalUnit.name = editOrganizationalUnit.name

          store.writeQuery({
            query: ORGANIZATIONAL_UNIT,
            variables: { input: { organizationalUnitId } },
            data: data
          })
        }
      }
    )
  },

  deleteOrganizationalUnit (input) {
    return mutate({ mutation: DELETE_ORGANIZATIONAL_UNIT, variables: { input } })
  },

  organizationalUnit (variables, { fetchPolicy }) {
    return watchQuery({
      query: ORGANIZATIONAL_UNIT,
      variables: variables,
      fetchPolicy: fetchPolicy || 'cache-and-network'
    })
  },

  taskTypesByCategory () {
    return watchQuery({ query: TASK_TYPES_BY_CATEGORY, fetchPolicy: 'cache-first' })
  },

  editOrganizationalUnitTasks (input) {
    return mutate({ mutation: EDIT_ORGANIZATIONAL_UNIT_TASKS, variables: { input }, refetchQueries: () => ['MeQuery'] })
  },

  possibleSupervisors (variables) {
    return watchQuery({ query: POSSIBLE_SUPERVISORS, variables })
  },

  possibleEmployees (variables) {
    return watchQuery({ query: POSSIBLE_EMPLOYEES, variables })
  },

  addSupervisors (input) {
    return mutate({ mutation: ADD_SUPERVISORS, variables: { input }, refetchQueries: () => ['organizationalUnit'] })
  },

  addEmployees (input) {
    return mutate({ mutation: ADD_EMPLOYEES, variables: { input }, refetchQueries: () => ['organizationalUnit'] })
  },

  removeMember (input) {
    return mutate({ mutation: REMOVE_MEMBER, variables: { input }, refetchQueries: () => ['organizationalUnit'] })
  }
}

export default organizationalUnitManagementService
