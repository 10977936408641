import Vue from 'vue'
import App from './App.vue'
import './services/codefabrik-ui/codefabrik-ui'
import router from './services/vuerouter/vueRouter'
import i18n from './services/vuei18n/vueI18n'
import './filters/duration'
import './filters/localDateTime'
import './filters/weekDay'
import './filters/currency'
import './filters/number'
import store, { MUTATIONS } from './store'
import './services/echarts/echarts'
import './assets/styles/index.css'
import './services/vee-validate/vee-validate'

Vue.config.productionTip = false
window.Vue = Vue

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
