import { paginated } from '../helpers/paginated'

const initialState = {
  page: {
    limit: 30,
    offset: 0,
    status: null
  }
}

const leaveModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations,
    setStatus (state, status) {
      state.page = {
        ...state.page,
        status
      }
    }
  }
}

export default leaveModule
export { initialState }
