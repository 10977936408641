import { paginated } from '../helpers'
import { DateTime } from 'luxon'

const initialState = {
  page: {
    range: {
      start: DateTime.now().minus({ day: 30 }).toFormat('YYYY-MM-DD'),
      endInclusive: DateTime.now().toFormat('YYYY-MM-DD')
    },
    limit: 30,
    offset: 0,
    staffId: null
  }
}

const workingTimeModule = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    ...paginated.mutations
  },
  actions: {
    updateStaff ({ commit, state }, staffId) {
      commit('setPage', {
        ...state.page,
        staffId,
        limit: 30,
        offset: 0
      })
    },
    updateRange ({ commit, state }, range) {
      commit('setPage', {
        ...state.page,
        limit: 30,
        offset: 0,
        range: {
          start: range.start,
          endInclusive: range.endInclusive
        }
      })
    }
  },
}

export default workingTimeModule
export { initialState }
