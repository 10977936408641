<template>
  <div class="min-h-screen flex flex-col">
    <div class="p-4">
      <img
        src="../../public/zeitfabrik-logo-landscape.svg"
        alt="ZeitFabrik Logo"
        class="mx-auto h-12"
      >
    </div>
    <div class="flex-1 p-4 flex flex-col justify-center items-center">
      <form
        v-if="accessToken == null"
        class="w-full max-w-screen-sm flex flex-col"
        @submit.prevent="handleLogin"
      >
        <label
          class="text-2xl text-slate-700"
        >
          {{ $t('label.email') }}
          <input
            v-model="username"
            class="mt-2 p-4 rounded border w-full text-4xl tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
            type="email"
            autocomplete="username"
          >
        </label>

        <label class="mt-12 text-2xl text-slate-700">
          {{ $t('label.password') }}
          <input
            v-model="password"
            class="mt-2 p-4 rounded border w-full text-4xl tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
            type="password"
            autocomplete="current-password"
          >
        </label>
        <button
          type="submit"
          class="mt-12 mx-auto uppercase font-semibold text-4xl text-slate-700"
        >
          {{ $t('button.signIn') }}
        </button>
      </form>

      <div v-else-if="organizations.length < 1">
        Keine Organisation gefunden
      </div>

      <form
        v-else
        class="w-full max-w-screen-sm flex flex-col"
        @submit.prevent="handleConnect"
      >
        <label
          class="text-2xl text-slate-700"
        >
          {{ $t('label.name') }}
          <input
            v-model="deviceName"
            class="mt-2 p-4 rounded border w-full text-4xl tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
            type="text"
          >
        </label>
        <label
          class="mt-12 text-2xl text-slate-700"
        >
          {{ $t('label.description') }}
          <input
            v-model="description"
            class="mt-2 p-4 rounded border w-full text-4xl tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
            type="text"
          >
        </label>
        <label
          v-if="organizations.length !== 1"
          class="mt-12 text-2xl text-slate-700"
        >
          {{ $t('label.organization') }}
          <select
            v-model="organizationId"
            class="mt-2 p-4 rounded border w-full text-4xl tracking-widest focus:outline-0 focus:ring-4 focus:ring-sky-600"
          >
            <option
              v-for="organization in organizationOptions"
              :key="organization.value"
              :value="organization.value"
            >
              {{ organization.label }}
            </option>
          </select>
        </label>
        <button
          type="submit"
          class="mt-12 mx-auto uppercase font-semibold text-4xl text-slate-700"
        >
          {{ $t('button.create') }}
        </button>
      </form>

      <div
        class="mt-4 mx-auto font-semibold text-red-600 h-4"
      >
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
  import axios from '@/services/axios/axios-attendance-monitor'

  export default {
    data: () => {
      return {
        username: undefined,
        password: undefined,
        accessToken: undefined,
        xXsrfToken: undefined,
        errorMessage: undefined,
        deviceName: undefined,
        description: undefined,
        organizationId: undefined,
        organizations: []
      }
    },
    computed: {
      organizationOptions() {
        return this.organizations.map((organization) => {
          return {
            value: organization.id,
            label: organization.name,
          }
        })
      },
      axiosConfig() {
        return {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': `Bearer ${this.accessToken}`,
            'X-XSRF-TOKEN': this.xXsrfToken,
          }
        }
      },
    },
    methods: {
      async handleLogin() {
        await axios.post('/user/sign-in', { username: this.username, password: this.password })
          .then((response) => {
            this.accessToken = response.data.accessToken
            this.xXsrfToken = response.data.xsrfToken
            this.errorMessage = undefined
          })
          .catch(_ => {
            this.errorMessage = this.$t('help.authenticationFailed')
          })

        if(!this.errorMessage) {
          await axios.get('/organization', this.axiosConfig).then((response) => {
            this.organizations = response.data
            if (this.organizations.length === 1) {
              this.organizationId = this.organizations[0].id
            }
          }).catch((error) => {
            this.errorMessage = error.message
          })
        }
      },
      handleConnect() {
        const body = {
          organizationId: this.organizationId,
          deviceName: this.deviceName,
          description: this.description
        }

        axios.post('/attendance-monitor', body, this.axiosConfig).then((response) => {
          let accessToken = response.data.accessToken
          let attendanceMonitorUrl = response.data.attendanceMonitor.attendanceMonitorUrl

          const dashboardUrl = new URL(attendanceMonitorUrl)
          dashboardUrl.searchParams.append('accessToken', accessToken)
          //the webview in the android wrapper app reads this parameter and stores the url
          dashboardUrl.searchParams.append('setAsDashboardUrl', 'true')

          window.open(dashboardUrl.href, '_self')
        }).catch((error) => {
          this.errorMessage = this.$t(`backend.${error.response.data.message}`)
        })
      }
    }
  }
</script>
