const paginatedInitialState = {
  page: {}
}

const paginated = {
  mutations: {
    setPage (state, page) {
      state.page = page
    }
  }
}

export { paginated, paginatedInitialState }
