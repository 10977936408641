import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const HOLIDAYS = gql`
  query holidays($input: HolidaysInput!) {
    holidays(input: $input) {
      id,
      name,
      day,
      dayLength,
      yearCycle,
      publicHoliday
    }
  }
`

const CREATE_HOLIDAY = gql`
  mutation createHoliday($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      id,
      day,
      dayLength,
      name,
      yearCycle,
      publicHoliday
    }
  }
`

const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($input: DeleteHolidayInput!) {
    deleteHoliday(input: $input)
  }
`

const PUBLIC_HOLIDAYS = gql`
  query PublicHolidaysQuery($input: PublicHolidaysInput!) {
    publicHolidays(input: $input) {
      id,
      name,
      configured
    }
  }
`

const REPLACE_PUBLIC_HOLIDAYS = gql`
  mutation ReplacePublicHolidaysMutation($input: ReplacePublicHolidaysInput!) {
    replacePublicHolidays(input: $input)
  }
`

const holidayManagementService = {
  holidays (variables) {
    return watchQuery({
      query: HOLIDAYS,
      variables: variables,
      fetchPolicy: 'no-cache'
    })
  },

  createHoliday ({ organizationId, name, day, halfDay, yearCycle }) {
    return mutate({
      mutation: CREATE_HOLIDAY,
      variables: {
        input: {
          organizationId,
          name,
          day,
          length: halfDay ? 0.5 : 1,
          yearCycle: yearCycle || null
        }
      }
    })
  },

  deleteHoliday (input) {
    return mutate({ mutation: DELETE_HOLIDAY, variables: { input } })
  },

  publicHolidays (variables) {
    return watchQuery({ query: PUBLIC_HOLIDAYS, variables, fetchPolicy: 'network-only' })
  },

  replacePublicHolidays (input) {
    return mutate({ mutation: REPLACE_PUBLIC_HOLIDAYS, variables: { input } })
  }
}

export default holidayManagementService
