import { gql, mutate, watchQuery } from '../../services/apollo/apollo'
import { saveAs } from 'file-saver'
import axios from '../../services/axios/axios'

const FRAGMENTS = {
  invoice: gql`
    fragment invoice on Invoice {
      id
      invoicingPartyId
      invoicingPartyAddress
      invoicingPartyVatNumber
      recipientId
      recipientAddress
      date
      number
      deliveryPeriod
      message
      paymentTerms
      totalPrice
      totalPriceBeforeTax
      customer {
        id,
        name
      }
      documentUrl
      timeSheetUrl
    }
  `,
  invoiceLineItem: gql`
    fragment invoiceLineItem on InvoiceLineItem {
      id,
      description,
      quantity,
      unitPrice,
      vat,
      totalPriceBeforeTax,
      totalPrice
    }
  `,
  invoiceTemplate: gql`
    fragment invoiceTemplate on InvoiceTemplate {
      id
      name
      invoicingPartyAddress
      invoicingPartyVatNumber
      message
      paymentTerms
      default
    }
  `
}

const INVOICE_PAGE = gql`
  query invoicePage($input: InvoicePageInput!) {
    invoicePage(input: $input) {
      invoice {
        id
        customer {
          id
          name
        }
        number
        date

      }
      totalCount
    }
  }
`

const INVOICE = gql`
  query invoice($input: InvoiceInput!) {
    invoice(input: $input) {
      ...invoice
      lineItems {
        ...invoiceLineItem
      }
    }
  }

  ${FRAGMENTS.invoice}
  ${FRAGMENTS.invoiceLineItem}
`

const ORDERS_FOR_INVOICING = gql`
  query ordersForInvoicing($input: OrdersForInvoicingInput!) {
    ordersForInvoicing(input: $input) {
      id,
      name,
      customerReferenceNumber,
      contactPerson,
      billableTime
    }
  }
`

const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      ...invoice
    }
  }

  ${FRAGMENTS.invoice}
`

const EDIT_INVOICE = gql`
  mutation editInvoice($input: EditInvoiceInput!) {
    editInvoice(input: $input) {
      ...invoice
    }
  }

  ${FRAGMENTS.invoice}
`

const INVOICE_ORDERS = gql`
  mutation invoiceOrders($input: InvoiceOrdersInput!) {
    invoiceOrders(input: $input) {
      ...invoice
      lineItems {
        ...invoiceLineItem
      }
    }
  }

  ${FRAGMENTS.invoice}
  ${FRAGMENTS.invoiceLineItem}
`

const CREATE_INVOICE_LINE_ITEM = gql`
  mutation createInvoiceLineItem($input: CreateInvoiceLineItemInput!) {
    createInvoiceLineItem(input: $input) {
      ...invoice
      lineItems {
        ...invoiceLineItem
      }
    }
  }

  ${FRAGMENTS.invoice}
  ${FRAGMENTS.invoiceLineItem}
`

const EDIT_INVOICE_LINE_ITEM = gql`
  mutation editInvoiceLineItem($input: EditInvoiceLineItemInput!) {
    editInvoiceLineItem(input: $input) {
      ...invoice
      lineItems {
        ...invoiceLineItem
      }
    }
  }

  ${FRAGMENTS.invoice}
  ${FRAGMENTS.invoiceLineItem}
`

const DELETE_INVOICE_LINE_ITEM = gql`
  mutation deleteInvoiceLineItem($input: DeleteInvoiceLineItemInput!) {
    deleteInvoiceLineItem(input: $input) {
      ...invoice
      lineItems {
        ...invoiceLineItem
      }
    }
  }

  ${FRAGMENTS.invoice}
  ${FRAGMENTS.invoiceLineItem}
`

const DELETE_INVOICE = gql`
  mutation deleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input)
  }
`

const INVOICE_TEMPLATE = gql`
  query invoiceTemplate($input: InvoiceTemplateQueryInput!) {
    invoiceTemplate(input: $input) {
      ...invoiceTemplate
    }
  }
  ${FRAGMENTS.invoiceTemplate}
`

const INVOICE_TEMPLATE_PAGE = gql`
  query invoiceTemplatePage($input: InvoiceTemplatePageInput!) {
    invoiceTemplatePage(input: $input) {
      invoiceTemplates {
        ...invoiceTemplate
      }
      totalCount
    }
  }
  ${FRAGMENTS.invoiceTemplate}
`

const CREATE_INVOICE_TEMPLATE = gql`
  mutation createInvoiceTemplate($input: CreateInvoiceTemplateInput!) {
    createInvoiceTemplate(input: $input) {
      ...invoiceTemplate
    }
  }
  ${FRAGMENTS.invoiceTemplate}
`

const EDIT_INVOICE_TEMPLATE = gql`
  mutation editInvoiceTemplate($input: EditInvoiceTemplateInput!) {
    editInvoiceTemplate(input: $input) {
      ...invoiceTemplate
    }
  }
  ${FRAGMENTS.invoiceTemplate}
`

const DELETE_INVOICE_TEMPLATE = gql`
  mutation deleteInvoiceTemplate($input: DeleteInvoiceTemplateInput!) {
    deleteInvoiceTemplate(input: $input)
  }
`

const MARK_INVOICE_TEMPLATE_AS_DEFAULT = gql`
  mutation markInvoiceTemplateAsDefault($input: MarkInvoiceTemplateAsDefaultInput!) {
    markInvoiceTemplateAsDefault(input: $input) {
      ...invoiceTemplate
    }
  }
  ${FRAGMENTS.invoiceTemplate}
`

const invoicingService = {
  invoicePage (variables) {
    return watchQuery({ query: INVOICE_PAGE, variables })
  },

  invoice (variables) {
    return watchQuery({ query: INVOICE, variables, fetchPolicy: 'cache-first' })
  },

  saveInvoice (input) {
    let formData = new FormData()
    formData.append('letterhead', input.letterhead)
    return axios
      .post(
        `/${input.documentUrl}`,
        formData,
        { responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      .then(({ data }) => saveAs(data, input.fileName.replace(/\./g, '_')))
  },

  saveTimeSheet (input) {
    let formData = new FormData()
    formData.append('letterhead', input.letterhead)
    return axios.post(
      `/${input.timeSheetUrl}`,
      formData,
      {
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(({ data }) => saveAs(data, input.fileName.replace(/\./g, '_')))
  },

  createInvoice (input) {
    return mutate({
      mutation: CREATE_INVOICE,
      variables: { input }
    })
  },

  editInvoice (input) {
    return mutate({
      mutation: EDIT_INVOICE,
      variables: { input }
    })
  },

  deleteInvoice (input) {
    return mutate({
      mutation: DELETE_INVOICE,
      variables: { input }
    })
  },

  ordersForInvoicing (variables) {
    return watchQuery({ query: ORDERS_FOR_INVOICING, variables })
  },

  invoiceOrders (input) {
    return mutate({
      mutation: INVOICE_ORDERS,
      variables: { input }
    })
  },

  createInvoiceLineItem (input) {
    return mutate({
      mutation: CREATE_INVOICE_LINE_ITEM,
      variables: { input }
    })
  },

  editInvoiceLineItem (input) {
    return mutate({
      mutation: EDIT_INVOICE_LINE_ITEM,
      variables: { input }
    })
  },

  deleteInvoiceLineItem (input) {
    return mutate({
      mutation: DELETE_INVOICE_LINE_ITEM,
      variables: { input }
    })
  },

  invoiceTemplate (variables) {
    return watchQuery({ query: INVOICE_TEMPLATE, variables })
  },

  invoiceTemplatePage (variables) {
    return watchQuery({ query: INVOICE_TEMPLATE_PAGE, variables })
  },

  createInvoiceTemplate (input) {
    return mutate({
      mutation: CREATE_INVOICE_TEMPLATE,
      variables: { input }
    })
  },

  editInvoiceTemplate (input) {
    return mutate({
      mutation: EDIT_INVOICE_TEMPLATE,
      variables: { input }
    })
  },

  deleteInvoiceTemplate (input) {
    return mutate({
      mutation: DELETE_INVOICE_TEMPLATE,
      variables: { input }
    })
  },

  markInvoiceTemplateAsDefault (input) {
    return mutate({
      mutation: MARK_INVOICE_TEMPLATE_AS_DEFAULT,
      variables: { input }
    })
  }
}

export default invoicingService
