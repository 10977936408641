import Vue from 'vue'
import { DateTime } from 'luxon'

Vue.filter('weekDay', weekDay)

function weekDay (day) {
  return DateTime.fromISO(day).setLocale(navigator.language).toFormat('ccc')
}

export default weekDay
