import { gql, mutate, watchQuery } from '../../services/apollo/apollo'
import axios from '../../services/axios/axios'

const FRAGMENTS = {
  leaveAgreement: gql`
    fragment LeaveAgreement on LeaveAgreement {
      id
      start
      end
      annualLeave
      staff {
        id
        firstName
        lastName
      }
    }
  `,
  workingTimeAgreement: gql`
    fragment WorkingTimeAgreement on WorkingTimeAgreement {
      id
      start
      end
      staff {
        id
        firstName
        lastName
      }
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  `,
  staff: gql`
    fragment Staff on Staff {
      id,
      userId
      admin
      firstName
      lastName
      owner
      executive
      status
    }
  `
}

const STAFF_PAGE = gql`
  query staffPage($input: StaffPageInput!) {
    staffPage(input: $input) {
      staff {
        id
        email
        firstName
        lastName
        status
        executableTasks {
          definition {
            category
            predicate
          }
        }
      }
      totalCount
    }
  }
`
const STAFF_MANAGEMENT_PAGE = gql`
  query staffPage($input: StaffPageInput!) {
    staffPage(input: $input) {
      staff {
        id
        userId
        firstName
        lastName
        status
        owner
      }
      totalCount
    }
  }
`
const STAFFS_WITHOUT_DATEV_COMPLIANT_IDENTIFICATION_NUMBER = gql`
    query staffsWithoutDatevCompliantIdentificationNumber($input: StaffsWithoutDatevCompliantIdentificationNumberQueryInput!) {
      staffsWithoutDatevCompliantIdentificationNumber(input: $input) {
        id
        userId
        firstName
        lastName
        status
        owner
        staffIdentificationNumber
      }
    }
`
const CREATE_STAFF = gql`
  mutation CreateStaffMutation($input: CreateStaffInput!) {
    createStaff(createStaffInput: $input) {
      id
      firstName
      lastName
      staffIdentificationNumber
      status
      invitation {
        id
        email
      }
      userId
      admin
      owner
    }
  }
`

const EDIT_STAFF = gql`
  mutation EditStaffMutation($input: EditStaffInput!) {
    editStaff(editStaffInput: $input) {
      id
      firstName
      lastName
      staffIdentificationNumber
    }
  }
`

const IMPORT_STAFF = gql`
  mutation ImportStaffMutation($input: ImportStaffInput!) {
    importStaff(importStaffInput: $input) {
      id
    }
  }
`

const STAFF = gql`
  query Staff($input: StaffQueryInput!) {
    staff(input: $input) {
      id
      firstName
      lastName
      staffIdentificationNumber
      status
      invitation {
        id
        email
      }
      userId
      admin
      owner
      leaveAccount {
        id
        currentLeaveYear {
          year
          targetLeave
          takenLeave
          receivedLeave
        }
      }
      overtime
      deletable
    }
  }
`

const STAFF_EXECUTIVE = gql`
  query Staff($input: StaffQueryInput!) {
    staff(input: $input) {
      id
      firstName
      lastName
      status
      userId
      admin
      owner
      executive
    }
  }
`

const STAFF_NAME = gql`
  query Staff($input: StaffQueryInput!) {
    staff(input: $input) {
      id
      firstName
      lastName
      executableTasks {
        definition {
          category
          predicate
        }
      }
    }
  }
`

const STAFFS = gql`
  query Staffs($input: StaffsQueryInput!) {
    staffs(input: $input) {
      id
      firstName
      lastName
    }
  }
`

const WORKING_TIME_LAW_VIOLATION_PAGE = gql`
  query WorkingTimeLawViolations($input: WorkingTimeLawViolationPageInput!) {
    workingTimeLawViolationPage(input: $input) {
      workingTimeLawViolations {
        id
        staffId
        type
        affectedDay
        dailyWorkingTimeExceededByDuration
        breakTimeInsufficientDuration
        restTimeInsufficientDuration
        averageWorkingTimeExceededByDuration
        timeRecordingNotPresentTargetWorkingTime
      }
      totalCount
    }
  }
`

const AUDIT_PAGE = gql`
  query auditPage($input: AuditPageInput!) {
    auditPage(input: $input) {
      workingTimeLawViolationAudits {
        staff {
          id
          firstName
          lastName
        }
        workingTimeLawViolationAuditResult
      }
    }
  }
`

const SEND_INVITATION = gql`
  mutation sendInvitation($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      id
      email
    }
  }
`

const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      id
      email
      organizationId
      organizationName
    }
  }
`

const REVOKE_INVITATION = gql`
  mutation revokeInvitation($input: RevokeInvitationInput!) {
    revokeInvitation(input: $input)
  }
`

const WORKING_TIME_AGREEMENT_PAGE = gql`
  query workingTimeAgreementPage($input: WorkingTimeAgreementPageInput!) {
    workingTimeAgreementPage(input: $input) {
      workingTimeAgreements {
        ...WorkingTimeAgreement
      }
      totalCount
    }
  }
  ${FRAGMENTS.workingTimeAgreement}
`

const CREATE_WORKING_TIME_AGREEMENT = gql`
  mutation createWorkingTimeAgreement($input: CreateWorkingTimeAgreementInput!) {
    createWorkingTimeAgreement(input: $input) {
      ...WorkingTimeAgreement
    }
  }
  ${FRAGMENTS.workingTimeAgreement}
`

const DELETE_WORKING_TIME_AGREEMENT = gql`
  mutation deleteWorkingTimeAgreement($input: DeleteWorkingTimeAgreementInput!) {
    deleteWorkingTimeAgreement(input: $input)
  }
`

const EDIT_WORKING_TIME_AGREEMENT = gql`
  mutation editWorkingTimeAgreement($input: EditWorkingTimeAgreementInput!) {
    editWorkingTimeAgreement(input: $input) {
      ...WorkingTimeAgreement
    }
  }
  ${FRAGMENTS.workingTimeAgreement}
`

const WORKING_TIME_AGREEMENT = gql`
  query workingTimeAgreement($input: WorkingTimeAgreementQueryInput!) {
    workingTimeAgreement(input: $input) {
      ...WorkingTimeAgreement
    }
  }
  ${FRAGMENTS.workingTimeAgreement}
`

const LEAVE_AGREEMENT_PAGE = gql`
  query leaveAgreementPage($input: LeaveAgreementPageInput!) {
    leaveAgreementPage(input: $input) {
      leaveAgreements {
        ...LeaveAgreement
      }
      totalCount
    }
  }
  ${FRAGMENTS.leaveAgreement}
`

const LEAVE_AND_TIME_ACCOUNT_PAGE = gql`
    query leaveAndTimeAccountPage($input: LeaveAndTimeAccountPageInput!) {
        leaveAndTimeAccountPage(input: $input) {
            entries {
                staff {
                    id
                    firstName
                    lastName
                }
                leaveAccount {
                    currentLeaveYear {
                        receivedLeave
                        targetLeave
                        takenLeave
                        remainingLeave
                    }
                }
                overtime
            }
            totalCount(input: $input)
        }
    }
`

const CREATE_LEAVE_AGREEMENT = gql`
  mutation createLeaveAgreement($input: CreateLeaveAgreementInput!) {
    createLeaveAgreement(input: $input) {
      ...LeaveAgreement
    }
  }
  ${FRAGMENTS.leaveAgreement}
`

const EDIT_LEAVE_AGREEMENT = gql`
  mutation editLeaveAgreement($input: EditLeaveAgreementInput!) {
    editLeaveAgreement(input: $input) {
      ...LeaveAgreement
    }
  }
  ${FRAGMENTS.leaveAgreement}
`

const LEAVE_AGREEMENT = gql`
  query leaveAgreement($input: LeaveAgreementQueryInput!) {
    leaveAgreement(input: $input) {
      ...LeaveAgreement
    }
  }
  ${FRAGMENTS.leaveAgreement}
`

const DELETE_LEAVE_AGREEMENT = gql`
  mutation deleteLeaveAgreement($input: DeleteLeaveAgreementInput!) {
    deleteLeaveAgreement(input: $input)
  }
`

const EDIT_LEAVE_ACCOUNT = gql`
  mutation editLeaveAccount($input: EditLeaveAccountInput!) {
    editLeaveAccount(input: $input) {
      id,
      currentLeaveYear {
        year
        targetLeave
        takenLeave,
        receivedLeave,
        remainingLeave,
      }
    }
  }
`

const INCREMENT_OVERTIME = gql`
  mutation incrementOvertime($input: IncrementOvertimeInput!) {
    incrementOvertime(input: $input) {
      staffId,
      value,
      reason
    }
  }
`

const DECREMENT_OVERTIME = gql`
  mutation decrementOvertime($input: DecrementOvertimeInput!) {
    decrementOvertime(input: $input) {
      staffId,
      value,
      reason
    }
  }
`

const ADD_ADMIN_PRIVILEGES = gql`
  mutation addAdminPrivileges($input: AddAdminPrivilegesInput!) {
    addAdminPrivileges(input: $input) {
      id,
      admin
    }
  }
`

const REMOVE_ADMIN_PRIVILEGES = gql`
  mutation removeAdminPrivileges($input: RemoveAdminPrivilegesInput!) {
    removeAdminPrivileges(input: $input) {
      id,
      admin
    }
  }
`

const UPDATE_ONBOARDING = gql`
  mutation updateOnboarding($input: UpdateOnboardingInput!) {
    updateOnboarding(input: $input) {
      id,
      onboarding {
        showCreateOwnLeaveAgreement,
        showCreateOwnWorkingTimeAgreement,
        showChooseOrganizationFunctionality,
      }
    }
  }
`

const DEACTIVATE_STAFF = gql`
  mutation deactivateStaff($input: DeactivateStaffInput!) {
    deactivateStaff(input: $input) {
      ...Staff
    }
  }
  ${FRAGMENTS.staff}
`

const DELETE_STAFF = gql`
  mutation deleteStaff($input: DeleteStaffInput!) {
    deleteStaff(input: $input)
  }
`

const SET_STAFF_EXECUTIVE = gql`
    mutation setStaffExecutive($input: SetStaffExecutiveInput!) {
      setStaffExecutive(input: $input) {
        ...Staff
      }
    }
    ${FRAGMENTS.staff}
`

const UNSET_STAFF_EXECUTIVE = gql`
    mutation unsetStaffExecutive($input: UnsetStaffExecutiveInput!) {
      unsetStaffExecutive(input: $input) {
        ...Staff
      }
    }
    ${FRAGMENTS.staff}
`

const staffManagementService = {
  getStaffPage (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFF_PAGE, variables, fetchPolicy })
  },

  getStaffManagementPage (variables) {
    return watchQuery({ query: STAFF_MANAGEMENT_PAGE, variables })
  },

  createStaff (input) {
    return mutate({
      mutation: CREATE_STAFF,
      variables: { input },
      update: (store, { data: { createStaff }, errors }) => {
        if (errors) return
        store.writeQuery({
          query: STAFF,
          variables: { input: { staffId: createStaff.id } },
          data: {
            staff: createStaff
          }
        })
      },
      refetchQueries: () => ['MeQuery']
    })
  },

  editStaff (input) {
    return mutate({ mutation: EDIT_STAFF, variables: { input } })
  },

  importStaff(input) {
    return mutate({ mutation: IMPORT_STAFF, variables: { input } })
  },

  staff (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFF, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  staffsWithoutDatevCompliantIdentificationNumber (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFFS_WITHOUT_DATEV_COMPLIANT_IDENTIFICATION_NUMBER, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  staffExecutive (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFF_EXECUTIVE, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  staffName (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFF_NAME, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  staffs (variables, { fetchPolicy }) {
    return watchQuery({ query: STAFFS, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  workingTimeLawViolationPage(variables, { fetchPolicy }) {
    return watchQuery({ query: WORKING_TIME_LAW_VIOLATION_PAGE, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  auditPage(variables, { fetchPolicy }) {
    return watchQuery({ query: AUDIT_PAGE, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },

  sendInvitation (input) {
    return mutate({
      mutation: SEND_INVITATION,
      variables: { input },
      update: (store, { data: { sendInvitation }, errors }) => {
        if (errors) return
        const data = store.readQuery({
          query: STAFF,
          variables: { input: { staffId: input.staffId } }
        })

        data.staff.status = 'INVITED'
        data.staff.invitation = sendInvitation

        store.writeQuery({
          query: STAFF,
          variables: { input: { staffId: input.staffId } },
          data: data
        })
      }
    })
  },

  acceptInvitation (input) {
    return mutate({ mutation: ACCEPT_INVITATION, variables: { input } })
  },

  declineInvitation (input) {
    return axios.post('/staff/decline-invitation', input)
  },

  revokeInvitation (input) {
    return mutate({ mutation: REVOKE_INVITATION, variables: { input }, refetchQueries: () => ['Staff'] })
  },

  editLeaveAccount (input) {
    return mutate({ mutation: EDIT_LEAVE_ACCOUNT, variables: { input } })
  },

  incrementOvertime (input) {
    return mutate({ mutation: INCREMENT_OVERTIME, variables: { input } })
  },

  decrementOvertime (input) {
    return mutate({ mutation: DECREMENT_OVERTIME, variables: { input } })
  },

  workingTimeAgreementPage (variables) {
    return watchQuery({ query: WORKING_TIME_AGREEMENT_PAGE, variables })
  },

  createWorkingTimeAgreement (input) {
    return mutate({ mutation: CREATE_WORKING_TIME_AGREEMENT, variables: { input }, refetchQueries: () => ['MeQuery'] })
  },

  deleteWorkingTimeAgreement (input) {
    return mutate({ mutation: DELETE_WORKING_TIME_AGREEMENT, variables: { input } })
  },

  editWorkingTimeAgreement (input) {
    return mutate({ mutation: EDIT_WORKING_TIME_AGREEMENT, variables: { input } })
  },

  workingTimeAgreement (variables) {
    return watchQuery({ query: WORKING_TIME_AGREEMENT, variables })
  },

  leaveAgreementPage (variables) {
    return watchQuery({ query: LEAVE_AGREEMENT_PAGE, variables })
  },

  createLeaveAgreement (input) {
    return mutate({ mutation: CREATE_LEAVE_AGREEMENT, variables: { input }, refetchQueries: () => ['MeQuery'] })
  },

  editLeaveAgreement (input) {
    return mutate({ mutation: EDIT_LEAVE_AGREEMENT, variables: { input } })
  },

  leaveAgreement (variables) {
    return watchQuery({ query: LEAVE_AGREEMENT, variables })
  },

  deleteLeaveAgreement (input) {
    return mutate({ mutation: DELETE_LEAVE_AGREEMENT, variables: { input } })
  },

  addAdminPrivileges (input) {
    return mutate({ mutation: ADD_ADMIN_PRIVILEGES, variables: { input } })
  },

  removeAdminPrivileges (input) {
    return mutate({ mutation: REMOVE_ADMIN_PRIVILEGES, variables: { input } })
  },

  updateOnboarding (input) {
    return mutate({ mutation: UPDATE_ONBOARDING, variables: { input } })
  },

  deactivateStaff (input) {
    return mutate({ mutation: DEACTIVATE_STAFF, variables: { input } })
  },

  deleteStaff (input) {
    return mutate({ mutation: DELETE_STAFF, variables: { input } })
  },

  leaveAndTimeAccountPage (variables) {
    return watchQuery({ query: LEAVE_AND_TIME_ACCOUNT_PAGE, variables })
  },

  setStaffExecutive (input) {
    return mutate({ mutation: SET_STAFF_EXECUTIVE, variables: { input } })
  },

  unsetStaffExecutive (input) {
    return mutate({ mutation: UNSET_STAFF_EXECUTIVE, variables: { input } })
  },
}

export default staffManagementService
