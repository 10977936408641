/**
 * Fetches data from a service.
 *
 * @param {Object} query
 * @param {string} query.name - the base name for various properties that get created by use query
 * @param {function} query.serviceFn - the service to get the observable from
 * @param {string|function} [query.variables] - the variables to pass into the service
 * @param {string} [query.fetchPolicy] - either (network-only|cache-first|cache-and-network)
 * @param {string} [query.pollInterval] - poll interval in milliseconds
 * @param {function} [query.stopPollingIf] - a function returning a truthy value
 * @param {string|function} [query.skipIf] - a property or function returning a truthy value
 * @param {function} [query.onUpdate] - is called after successfully fetching data from the service
 * @param {Map<string, (function|array)>} [query.mappingFunctions] - a function mapping the fetched result or an array of
 * two function where the first function does the mapping and the second function provides the default value
 * @param {boolean} [query.watchVariables] - whether the query should be reexecuted if the variables change
 */
const useQuery = ({
  name,
  serviceFn,
  variables,
  mappingFunctions,
  skipIf,
  onUpdate,
  fetchPolicy,
  pollInterval,
  stopPollingIf,
  watchVariables = true,
}) => {
  const metaInfoFieldName = `${name}Meta`
  const subscriptionFieldName = 'subscription'
  const queryRefFieldName = 'queryRef'
  const fieldLoadingName = 'loading'
  const computedVariables = `${name}Variables`
  const computedSkipIf = `${name}SkipIf`
  const serviceOptions = { fetchPolicy, pollInterval }

  function shouldSkip () {
    if (skipIf == null) return false
    if (typeof skipIf === 'function') {
      return this[computedSkipIf]
    } else {
      return skipIf.split('.').reduce((prev, cur) => prev ? prev[cur] : null, this)
    }
  }

  function resolveMappingFunction (dataKey) {
    return typeof mappingFunctions[dataKey] === 'object'
      ? mappingFunctions[dataKey][0]
      : mappingFunctions[dataKey]
  }

  function resolveDefaultValue (dataKey) {
    return typeof mappingFunctions[dataKey] === 'object'
      ? mappingFunctions[dataKey][1]()
      : {}
  }

  // Subscribes to the returned observable of the service
  function handleSubscribe ({ data, loading }) {
    if (data == null && loading) {
      return
    }
    this[metaInfoFieldName][fieldLoadingName] = false
    const responseData = data[Object.keys(data)[0]]

    onUpdate && onUpdate.call(this, responseData)
    stopPollingIf && stopPollingIf.call(this, responseData) && this[metaInfoFieldName][queryRefFieldName].stopPolling()

    if (mappingFunctions) {
      Object.keys(mappingFunctions).forEach(dataKey => {
        this[dataKey] = resolveMappingFunction(dataKey).call(this, responseData)
      })
    } else {
      this[name] = responseData
    }
  }

  // Convenience function for the mixin
  function subscribeToService (newData) {
    if (shouldSkip.call(this)) return
    this[metaInfoFieldName][fieldLoadingName] = true
    this[metaInfoFieldName][queryRefFieldName] = typeof serviceFn === 'string'
      ? this[serviceFn](newData, serviceOptions)
      : serviceFn.call(this, newData, serviceOptions)
    this[metaInfoFieldName][subscriptionFieldName] = this[metaInfoFieldName][queryRefFieldName].subscribe(handleSubscribe.bind(this))
  }

  function refetchQuery (newData) {
    if (shouldSkip.call(this)) return
    this[metaInfoFieldName][queryRefFieldName].refetch(newData)
    this[metaInfoFieldName][fieldLoadingName] = true
    this[metaInfoFieldName][subscriptionFieldName] = this[metaInfoFieldName][queryRefFieldName].subscribe(handleSubscribe.bind(this))
  }

  // the actual mixin
  const componentData = {
    beforeDestroy () {
      if (shouldSkip.call(this) || this[metaInfoFieldName][subscriptionFieldName] == null) return
      this[metaInfoFieldName][subscriptionFieldName].unsubscribe()
    },
    created () {
      if (shouldSkip.call(this)) return
      if (variables == null) {
        subscribeToService.call(this)
        // eslint-disable-next-line no-mixed-operators
      } else if (typeof variables === 'function') {
        subscribeToService.call(this, this[computedVariables])
      } else if ((variables && this[variables] != null && typeof this[variables] !== 'object') || (typeof this[variables] === 'object' && Object.keys(this[variables]).length > 0)) {
        // if variables data is an existing property fetch immediately
        subscribeToService.call(this, this[variables])
      }

      // Otherwise wait for the dependent property to be set and then subscribe to service (see watch properties)
    },
    data () {
      let mappedData
      if (mappingFunctions) {
        mappedData = Object.keys(mappingFunctions).reduce((obj, it) => {
          obj[it] = resolveDefaultValue(it)
          return obj
        }, {})
      } else {
        mappedData = { [name]: {} }
      }
      return {
        [metaInfoFieldName]: {
          [fieldLoadingName]: false,
          [subscriptionFieldName]: null,
          [queryRefFieldName]: null
        },
        ...mappedData
      }
    }
  }

  if (variables != null && typeof variables === 'function') {
    componentData.computed = {
      [computedVariables]: variables
    }
  }
  // watch the given property and invoke the service
  if (variables != null && watchVariables) {
    componentData.watch = {
      [typeof variables === 'string' ? variables : computedVariables] (newData) {
        if (this[metaInfoFieldName][subscriptionFieldName] == null) {
          subscribeToService.call(this, newData)
        } else {
          refetchQuery.call(this, newData)
        }
      }
    }
  }

  if (skipIf != null && typeof skipIf === 'function') {
    componentData.computed = {
      ...componentData.computed,
      [computedSkipIf]: skipIf
    }
  }

  if (skipIf != null) {
    componentData.watch = {
      ...componentData.watch,
      [typeof skipIf === 'string' ? skipIf : computedSkipIf] (newData) {
        const data = typeof variables === 'string' ? this[variables] : this[computedVariables]
        if (!newData && this[metaInfoFieldName][subscriptionFieldName] == null) {
          subscribeToService.call(this, data)
        } else {
          refetchQuery.call(this, data)
        }
      }
    }
  }

  return componentData
}

export default useQuery
