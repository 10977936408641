<template>
  <base-button
    v-bind="$attrs"
    :text="text"
    class="bg-primary text-white font-semibold tracking-widest rounded px-4 py-2 uppercase disabled:bg-primary/50"
    v-on="$listeners"
  />
</template>

<script>
  import BaseButton from '@/components/button/base-button'

  export default {
    components: { BaseButton },
    props: {
      text: {
        type: String,
        default () {
          return ''
        }
      },
    }
  }
</script>
