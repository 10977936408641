<template>
  <transition
    enter-class="opacity-0 translate-y-full"
    enter-active-class="transition-all delay-100 duration-500"
    enter-to-class="opacity-100 translate-y-0"
    leave-class="translate-y-0 opacity-100"
    leave-active-class="transition-all duration-500"
    leave-to-class="translate-y-full opacity-0"
  >
    <div
      v-if="notification"
    >
      <notification-success
        v-if="notification.type === 'success'"
        :message="$t(notification.message)"
      />
      <notification-error
        v-else
        :message="$t(notification.message)"
      />
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex'
  import { MUTATIONS, STATE } from '@/store'
  import NotificationSuccess from '@/components/notification/notification-success.vue'
  import NotificationError from '@/components/notification/notification-error.vue'

  export default {
    name: 'TheNotification',
    components: { NotificationError, NotificationSuccess },
    watch: {
      notification () {
        if(this.notification !== undefined) {
          setTimeout(() => this.$store.dispatch(MUTATIONS.RESET_NOTIFICATION), 2500)
        }
      }
    },
    computed: {
      ...mapState({
        notification: state => state[STATE.NOTIFICATION]
      })
    }
  }
</script>
