import { ME_QUERY_WITH_CURRENT_CLOCK_STATE } from '../usermanagement/userManagementService'
import { gql, mutate, watchQuery } from '@/services/apollo/apollo'
import { DateTime } from 'luxon'

const CLOCK_IN_NOW = gql`
  mutation clockInNow($input: ClockNowInput!) {
    clockInNow(input: $input) {
      id
      start
      end
    }
  }
`

const CLOCK_OUT_NOW = gql`
  mutation clockOutNow($input: ClockNowInput!) {
    clockOutNow(input: $input) {
      id
      start
      end
    }
  }
`

const TIME_INTERVAL_PAGE = gql`
  query timeIntervalPage($input: TimeIntervalPageInput!) {
    timeIntervalPage(input: $input) {
      timeInterval {
        id,
        start,
        end,
        canceled
      }
      totalCount
    }
  }
`

const TIME_INTERVAL = gql`
  query timeInterval($input: TimeIntervalQueryInput!) {
    timeInterval(input: $input) {
      id,
      start,
      end,
      staff {
        id
      },
      executableTasks {
        definition {
          category
          predicate
        }
      }
    }
  }
`

const CREATE_TIME_INTERVAL = gql`
  mutation createTimeInterval($input: CreateTimeIntervalInput!) {
    createTimeInterval(input: $input) {
      id,
      start,
      end
    }
  }
`

const EDIT_TIME_INTERVAL = gql`
  mutation editTimeInterval($input: EditTimeIntervalInput!) {
    editTimeInterval(input: $input) {
      id,
      staff {
        id,
        firstName,
        lastName
      }
      start,
      end
    }
  }
`

const CANCEL_TIME_INTERVAL = gql`
  mutation cancelTimeInterval($input: CancelTimeIntervalInput!) {
    cancelTimeInterval(cancelTimeInterval: $input)
  }
`

const SCHEDULED_BREAK_PAGE = gql`
  query scheduledBreakPage($input: ScheduledBreakPageInput!) {
    scheduledBreakPage(input: $input) {
      scheduledBreak {
        day,
        start,
        end,
        id
      }
      totalCount
    }
  }
`

function updateCurrentClockState (store, input, updateFn) {
  let data = store.readQuery({
    query: ME_QUERY_WITH_CURRENT_CLOCK_STATE,
    variables: { input }
  })

  updateFn(data.me.staff.currentClockState)

  store.writeQuery({
    query: ME_QUERY_WITH_CURRENT_CLOCK_STATE,
    variables: { input },
    data: { me: data.me }
  })
}

const SCHEDULE_BREAK = gql`
  mutation scheduleBreak($input: ScheduleBreakInput!) {
    scheduleBreak(input: $input) {
      day
      start
      end
    }
  }
`

const DELETE_SCHEDULED_BREAK = gql`
  mutation deleteScheduledBreak($input: DeleteScheduledBreakInput!) {
    deleteScheduledBreak(input: $input)
  }
`

const timeRecordingService = {
  clockInNow (input, zoneOffset = DateTime.now().toFormat('Z')) {
    return mutate({
      mutation: CLOCK_IN_NOW,
      variables: { input: { ...input, zoneOffset } },
      update: (store, { data, errors }) => {
        if (errors) return
        updateCurrentClockState(store, input,
          (currentClockState) => {
            currentClockState.clockedIn = true
            currentClockState.clockedInSince = data.clockInNow.start
          })
      }
    })
  },
  clockOutNow (input, zoneOffset = DateTime.now().toFormat('Z')) {
    return mutate({
      mutation: CLOCK_OUT_NOW,
      variables: { input: { ...input, zoneOffset } },
      update: (store, { errors }) => {
        if (errors) return
        updateCurrentClockState(store, input,
          (currentClockState) => {
            currentClockState.clockedIn = false
            currentClockState.clockedInSince = null
          })
      },
      refetchQueries: () => ['MeQueryWithCurrentWorkingweekAndTimeAccount', 'distributableDayPage']
    })
  },

  timeIntervalPage (variables) {
    return watchQuery({ query: TIME_INTERVAL_PAGE, variables })
  },

  timeInterval (variables) {
    return watchQuery({ query: TIME_INTERVAL, variables })
  },

  createTimeInterval (input) {
    return mutate({ mutation: CREATE_TIME_INTERVAL, variables: { input } })
  },

  editTimeInterval (input) {
    return mutate({ mutation: EDIT_TIME_INTERVAL, variables: { input } })
  },

  cancelTimeInterval (input) {
    return mutate({ mutation: CANCEL_TIME_INTERVAL, variables: { input } })
  },
  scheduledBreakPage (variables) {
    return watchQuery({ query: SCHEDULED_BREAK_PAGE, variables })
  },
  scheduleBreak(input) {
    return mutate({ mutation: SCHEDULE_BREAK, variables: { input } })
  },

  deleteScheduledBreak (input) {
    return mutate({ mutation: DELETE_SCHEDULED_BREAK, variables: { input } })
  },

}

export default timeRecordingService
