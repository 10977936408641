import Vue from 'vue'
import { Duration } from 'luxon'


Vue.filter('duration', duration)

function duration (isoString, pattern = 'hh:mm') {
  const duration = Duration.fromISO(isoString)
  if(duration.isValid) {

    if(duration.valueOf() < 0) {
      return `-${duration.negate().toFormat(pattern)}`
    }

    return duration.toFormat(pattern)
  }
  return ''
}

export default duration
