<template>
  <button
    :disabled="disabled || loading"
    class="hyphens-none relative disabled:cursor-not-allowed"
    v-on="$listeners"
  >
    <div class="absolute inset-0 flex items-center">
      <svg-loading
        v-if="loading"
        class="mx-auto"
      />
    </div>
    <slot>
      <div
        :class="{ 'text-transparent' : loading }"
      >
        {{ text }}
      </div>
    </slot>
  </button>
</template>

<script>
  import SvgLoading from '@/components/svg/svg-loading'

  export default {
    components: { SvgLoading },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default () {
          return ''
        }
      }
    }
  }
</script>
