<template>
  <base-notification
    :message="message"
    class="bg-rose-50 border-rose-300 text-rose-900"
  />
</template>

<script>
  import BaseNotification from '@/components/notification/base-notification.vue'

  export default {
    name: 'NotificationError',
    components: { BaseNotification },
    props: {
      message: {
        type: String,
        required: true,
      }
    }
  }
</script>
