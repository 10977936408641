import de from './de'
import en from './en'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import { confirmed, digits, email, image, integer, min, min_value, max, required } from 'vee-validate/dist/rules'
import Vue from 'vue'

extend('required', required)
extend('email', email)
extend('min', min)
extend('min_value', min_value)
extend('max', max)
extend('confirmed', confirmed)
extend('digits', digits)
extend('integer', integer)
extend('image', image)

extend('validAnnualLeave', (value) => {
  return value % 0.5 === 0
})

extend('csv', (value) => {
  const regex = /^.+\.csv$/gi
  return regex.test(value.name)
})

extend('noLeadingOrTrailingWhitespace', (value) => {
  const regex = /^(\S|(\S.*\S))$/g
  return regex.test(value)
})

localize({
  de,
  en
})
localize(navigator.language.split('-')[0])

Vue.component(ValidationProvider)
Vue.component(ValidationObserver)
