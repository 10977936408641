import { gql, mutate, watchQuery } from '@/services/apollo/apollo'

const IS_DATEV_AUTHORIZATION_PRESENT = gql`
    query isDatevAuthorizationPresent($input: IsDatevAuthorizationPresentInput!) {
        isDatevAuthorizationPresent(input: $input)
    }
`

const DATEV_AUTHORIZATION_INFO = gql`
    query datevAuthorizationInput($input: DatevAuthorizationInfoInput!) {
        datevAuthorizationInfo(input: $input) {
            datevRefreshTokenExpiryDate
            datevUsernameOfCreator
        }
    }
`

const REVOKE_DATEV_AUTHORIZATION = gql`
    mutation revokeDatevAuthorization($input: RevokeDatevAuthorizationInput!) {
        revokeDatevAuthorization(input: $input)
    }
`

const datevAuthorizationService = {
    isDatevAuthorizationPresent(variables, { fetchPolicy }) {
        return watchQuery({ query: IS_DATEV_AUTHORIZATION_PRESENT, variables, fetchPolicy: fetchPolicy || 'cache-first' })
    },
    revokeDatevAuthorization(input) {
        return mutate({ mutation: REVOKE_DATEV_AUTHORIZATION, variables: { input } })
    },
    authorizationInfo(variables, { fetchPolicy }) {
        return watchQuery({ query: DATEV_AUTHORIZATION_INFO, variables, fetchPolicy: fetchPolicy || 'cache-first' })
    }
}

export default datevAuthorizationService
