export default {
  messages: {
    required: (field) => `${field} wird benötigt.`,
    email: (field) => `${field} ist fehlerhaft.`,
    daySameOrBefore: (field, { _target_ }) => 'Fehlerhafter Zeitraum.',
    daySameOrAfter: (field, { _target_ }) => 'Fehlerhafter Zeitraum.',
    daySameOrAfterNow: (field) => `${field} darf nicht in der Vergangenheit sein.`,
    confirmed: () => 'Wert stimmt nicht überein.',
    min: (field, { length }) => `${field} muss ein ${length} stelliger Wert sein.`,
    digits: (field, { length }) => `${field} muss ein ${length} stelliger Wert sein.`,
    max: (field, { length }) => `${field} darf höchstens ein ${length} stelliger Wert sein.`,
    validAnnualLeave: () => 'Urlaub kann nur halbtägig vereinbart werden.',
    min_value: (field, { min }) => `${field} muss mindestens ${min} sein.`,
    integer: (field) => `${field} muss eine Ganzzahl sein.`,
    image: (field) => `${field} muss ein Bild sein.`,
    csv: (field) => `${field} muss eine CSV Datei sein.`,
    noLeadingOrTrailingWhitespace: (field) => `${field} darf keine führenden oder nachgestellten Leerzeichen enthalten.`,
  }
}

const capitalize = (value) => (value && value.length > 0) ? value.charAt(0).toUpperCase() + value.slice(1) : ''
