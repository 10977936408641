import { gql, mutate } from '@/services/apollo/apollo'

const GENERATE_DATEV_AUTHORIZATION_REQUEST = gql`
    mutation generateDatevAuthorizationRequest($input: GenerateDatevAuthorizationRequestInput!) {
      generateDatevAuthorizationRequest(input: $input) {
        uri
      }
    }
`

const CONFIRM_DATEV_AUTHORIZATION_REQUEST = gql`
    mutation confirmDatevAuthorizationRequest($input: ConfirmDatevAuthorizationRequestInput!) {
      confirmDatevAuthorizationRequest(input: $input) {
        organizationId
      }
    }
`

const datevAuthorizationRequestService = {
  generateDatevAuthorizationRequest(input) {
    return mutate({ mutation: GENERATE_DATEV_AUTHORIZATION_REQUEST, variables: { input } })
  },
  confirmDatevAuthorizationRequest(input) {
    return mutate({ mutation: CONFIRM_DATEV_AUTHORIZATION_REQUEST, variables: { input } })
  }
}

export default datevAuthorizationRequestService
