const useResolveHoliday = () => ({
  methods: {
    resolveHoliday (name) {
      const sanitizedName = name.replace(/[']/g, '')
      return this.translationExists(`backend.publicHoliday.${sanitizedName}`)
        ? this.translate(`backend.publicHoliday.${sanitizedName}`)
        : sanitizedName
    }
  }
})

export default useResolveHoliday
