import { gql, mutate, watchQuery } from '@/services/apollo/apollo'

const FRAGMENTS = {
  SICK_NOTE: gql`
    fragment sickNote on SickNote {
      id,
      start,
      end,
      status,
      staff {
        id,
        firstName
        lastName
      }
    }
  `
}

const SICK_NOTE_PAGE = gql`
  query sickNotePage($input: SickNotePageInput!) {
    sickNotePage(input: $input) {
      sickNotes {
        ...sickNote
      }
      totalCount
    }
  }

  ${FRAGMENTS.SICK_NOTE}
`

const SICK_NOTE = gql`
  query sickNote($input: SickNoteQueryInput!) {
    sickNote(input: $input) {
      ...sickNote
      executableTasks {
        definition {
          category
          predicate
        }
      }
    }
  }

  ${FRAGMENTS.SICK_NOTE}
`

const CREATE_SICK_NOTE = gql`
  mutation createSickNote($input: CreateSickNoteInput!) {
    createSickNote(input: $input) {
      id
      start,
      end
    }
  }
`

const SUBMIT_SICK_NOTE = gql`
  mutation submitSickNote($input: SubmitSickNoteInput!) {
    submitSickNote(input: $input) {
      id
      start,
      end
    }
  }
`

const ACCEPT_SICK_NOTE = gql`
  mutation acceptSickNote($input: AcceptSickNoteInput!) {
    acceptSickNote(input: $input){
      ...sickNote
    }
  }

  ${FRAGMENTS.SICK_NOTE}
`

const REJECT_SICK_NOTE = gql`
  mutation rejectSickNote($input: RejectSickNoteInput!) {
    rejectSickNote(input: $input){
      ...sickNote
    }
  }

  ${FRAGMENTS.SICK_NOTE}
`

const EDIT_SICK_NOTE = gql`
  mutation editSickNote($input: EditSickNoteInput! ) {
    editSickNote(input: $input) {
      ...sickNote
      executableTasks {
        definition {
          category
          predicate
        }
      }
    }
  }

  ${FRAGMENTS.SICK_NOTE}
`

const DELETE_SICK_NOTE = gql`
  mutation deleteSickNote($input: DeleteSickNoteInput!) {
    deleteSickNote(input: $input)
  }
`

const sickNoteManagementService = {
  sickNotePage (variables) {
    return watchQuery({ query: SICK_NOTE_PAGE, variables })
  },

  sickNote (variables) {
    return watchQuery({ query: SICK_NOTE, variables, fetchPolicy: 'cache-first' })
  },

  createSickNote (input) {
    return mutate({ mutation: CREATE_SICK_NOTE, variables: { input } })
  },

  submitSickNote (input) {
    return mutate({ mutation: SUBMIT_SICK_NOTE, variables: { input } })
  },

  acceptSickNote (input) {
    return mutate({ mutation: ACCEPT_SICK_NOTE, variables: { input } })
  },

  rejectSickNote (input) {
    return mutate({ mutation: REJECT_SICK_NOTE, variables: { input } })
  },

  deleteSickNote (input) {
    return mutate({ mutation: DELETE_SICK_NOTE, variables: { input } })
  },

  editSickNote (input) {
    return mutate({ mutation: EDIT_SICK_NOTE, variables: { input } })
  }
}

export default sickNoteManagementService
