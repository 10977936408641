import { gql, mutate, watchQuery } from '@/services/apollo/apollo'


const FRAGMENTS = {
  datevConfiguration: gql`
    fragment DatevConfiguration on DatevConfiguration {
      id,
      organizationId,
      active,
      consultantNumber,
      clientNumber,
      importStaffIdentificationNumberAsCompanyStaffIdentificationNumber,
      notificationEmailAddress
      exportFormat
    }
  `
}

const GET_DATEV_CONFIGURATION = gql`
  query getDatevConfiguration($input: DatevConfigurationInput!) {
    datevConfiguration(input: $input) {
      ...DatevConfiguration
    }
  }
  ${FRAGMENTS.datevConfiguration}
`

const CREATE_DATEV_CONFIGURATION = gql`
    mutation CreateDatevConfigurationMutation($input: CreateDatevConfigurationInput!) {
      createDatevConfiguration(input: $input) {
        ...DatevConfiguration
      }
    }
    ${FRAGMENTS.datevConfiguration}
`

const EDIT_DATEV_CONFIGURATION = gql`
    mutation EditDatevConfigurationMutation($input: EditDatevConfigurationInput!) {
      editDatevConfiguration(input: $input) {
        ...DatevConfiguration
      }
    }
    ${FRAGMENTS.datevConfiguration}
`

const ACTIVATE_DATEV_CONFIGURATION = gql`
    mutation ActivateDatevConfigurationMutation($input: ActivateDatevConfigurationInput!) {
      activateDatevConfiguration(input: $input) {
        ...DatevConfiguration
      }
    }
    ${FRAGMENTS.datevConfiguration}
`

const DEACTIVATE_DATEV_CONFIGURATION = gql`
    mutation DeactivateDatevConfigurationMutation($input: DeactivateDatevConfigurationInput!) {
      deactivateDatevConfiguration(input: $input) {
        ...DatevConfiguration
      }
    }
    ${FRAGMENTS.datevConfiguration}
`

const datevConfigurationService = {
  getDatevConfiguration(variables, { fetchPolicy }) {
    return watchQuery({ query: GET_DATEV_CONFIGURATION, variables, fetchPolicy: fetchPolicy || 'cache-first' })
  },
  createDatevConfiguration(input) {
    return mutate({ mutation: CREATE_DATEV_CONFIGURATION, variables: { input } })
  },
  editDatevConfiguration(input) {
    return mutate({ mutation: EDIT_DATEV_CONFIGURATION, variables: { input } })
  },
  activateDatevConfiguration(input) {
    return mutate({ mutation: ACTIVATE_DATEV_CONFIGURATION, variables: { input } })
  },
  deactivateDatevConfiguration(input) {
    return mutate({ mutation: DEACTIVATE_DATEV_CONFIGURATION, variables: { input } })
  },
}

export default datevConfigurationService
