import { gql, mutate, watchQuery } from '../../services/apollo/apollo'

const FRAGMENTS = {
  order: gql`
    fragment order on Order {
      id
      name
      customerReferenceNumber
      customerId
      organizationalUnitId
      contactPerson
      commentRequired
      hourlyRate
      budget
      status
      vat
    }
  `
}

const ORDER_PAGE = gql`
  query orderPage($input: OrderPageInput!) {
    orderPage(input: $input) {
      order {
        ...order
      }
      totalCount
    }
  }

  ${FRAGMENTS.order}
`

const ORDER = gql`
  query order($input: OrderQueryInput!) {
    order(input: $input) {
      ...order
    }
  }

  ${FRAGMENTS.order}
`

const CREATE_ORDER = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ...order
    }
  }

  ${FRAGMENTS.order}
`

const EDIT_ORDER = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      ...order
    }
  }

  ${FRAGMENTS.order}
`

const DELETE_ORDER = gql`
  mutation deleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input)
  }
`

const CLOSE_ORDER = gql`
  mutation closeOrder($input: CloseOrderInput!) {
    closeOrder(input: $input) {
      ...order
    }
  },

  ${FRAGMENTS.order}
`

const REOPEN_ORDER = gql`
  mutation reopenOrder($input: ReopenOrderInput!) {
    reopenOrder(input: $input) {
      ...order
    }
  }

  ${FRAGMENTS.order}
`

const ANNUAL_DISTRIBUTED_ORDER_TIME_PER_CUSTOMER = gql`
  query annualDistributedOrderTimePerCustomer($input: AnnualDistributedOrderTimePerCustomerInput!) {
    annualDistributedOrderTimePerCustomer(input: $input) {
      customer {
        name
      }
      customerId
      year
      duration
    }
  }
`
const MONTHLY_DISTRIBUTED_ORDER_TIME_PER_CUSTOMER = gql`
  query monthlyDistributedOrderTimePerCustomer($input: MonthlyDistributedOrderTimePerCustomerInput!) {
    monthlyDistributedOrderTimePerCustomer(input: $input) {
      customer {
        name
      }
      customerId
      year
      month
      duration
    }
  }
`

const orderManagementService = {
  orderPage(variables, { fetchPolicy } = {}) {
    return watchQuery({ query: ORDER_PAGE, variables, fetchPolicy })
  },

  order(variables) {
    return watchQuery({ query: ORDER, variables })
  },

  createOrder(input) {
    return mutate({ mutation: CREATE_ORDER, variables: { input } })
  },

  editOrder(input) {
    return mutate({ mutation: EDIT_ORDER, variables: { input } })
  },

  deleteOrder(input) {
    return mutate({ mutation: DELETE_ORDER, variables: { input } })
  },

  closeOrder(input) {
    return mutate({ mutation: CLOSE_ORDER, variables: { input } })
  },

  reopenOrder(input) {
    return mutate({ mutation: REOPEN_ORDER, variables: { input } })
  },

  annualDistributedOrderTimePerCustomer(variables) {
    return watchQuery({ query: ANNUAL_DISTRIBUTED_ORDER_TIME_PER_CUSTOMER, variables })
  },

  monthlyDistributedOrderTimePerCustomer(variables) {
    return watchQuery({ query: MONTHLY_DISTRIBUTED_ORDER_TIME_PER_CUSTOMER, variables })
  }
}

export default orderManagementService
