<template>
  <form-card
    :heading="$t('title.noInternetConnection')"
    :description="$t('subtitle.checkAndTryAgain')"
    :error-message="errorMessage"
    @submit="handleSubmit"
  >
    <template #footer>
      <button-primary
        :text="$t('button.tryAgain')"
      />
    </template>
  </form-card>
</template>

<script>
  import FormCard from '@/components/form/form-card'
  import ButtonPrimary from '@/components/button/button-primary.vue'
  import axios from '@/services/axios/axios'

  export default {
    components: { ButtonPrimary, FormCard },
    data () {
      return {
        errorMessage: '',
      }
    },
    methods: {
      handleSubmit() {
        axios.get('/live').then((response) => {
          if(response.status === 200) {
            this.$emit('alive', { state: true })
          }
        }).catch((error) => {
          this.errorMessage = this.$t('backend.networkError')
        })
      }
    }
  }
</script>
