import { gql, watchQuery } from '../../services/apollo/apollo'

const DAILY_WORKING_TIME_REPORT_PAGE = gql`
  query dailyWorkingTimeReportPage($input: DailyWorkingTimeReportPageInput!) {
    dailyWorkingTimeReportPage(input: $input) {
      dailyWorkingTimeReports {
        id
        staff {
          id
          firstName
          lastName
        }
        organizationId
        day
        workingTime
      }
      totalCount
    }
  }
`

const reportingService = {
  dailyWorkingTimeReportPage (variables) {
    return watchQuery({ query: DAILY_WORKING_TIME_REPORT_PAGE, variables })
  }
}

export default reportingService
